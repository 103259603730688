/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import FranchiseOrderTab from "./FranchiseOrderTab";
import SaleOrderTab from "./SaleOrderTab";
import SaleOrderChildTab from "./SaleOrderChildTab";
import { SearchPincodeList, ErrorMessageFromRedux } from "../../../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import StocktransferTab from "./StocktransferTab";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import CommonLoader from "../../Common/CommonLoader";

const PurchaseOrder = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const [ Tab, setTab ] = useState("franchise_order");
    const { selfInfo, device_id, commonMainLoader } = useSelector((state) => state.adminReducers);

    useEffect(() => {
        return () => {
            Dispatch(SearchPincodeList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    }, [ Tab ]);

     // get all franchise list
     useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);
    
    return(<section className="Main_section">
        <div className="MainHeader">
            {(selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm' || selfInfo?.user?.user_type === 'regional_sales_team_asm') ? (<ul className="MainHeader_Tab">
                <li className={Tab === "franchise_order" ? "active" : ""} onClick={() => setTab("franchise_order")}>Franchise Order</li>
            </ul>) : (<ul className="MainHeader_Tab">
                <li className={Tab === "franchise_order" ? "active" : ""} onClick={() => setTab("franchise_order")}>Franchise Order</li>
                <li  className={Tab === "sale_order" ? "active" : ""} onClick={() => setTab("sale_order")}>
                    {/* Sale Order */}
                    End Customer/BDC
                </li>
                <li  className={Tab === "ecom_order" ? "active" : ""} onClick={() => setTab("ecom_order")}>
                    {/* Ecom. Order */}
                    Marketplace Orders
                </li>
                <li  className={Tab === "stock_transfer" ? "active" : ""} onClick={() => setTab("stock_transfer")}>Stock Transfer</li>
            </ul>)}
        </div>

        {Tab === "franchise_order" ? (<>
            <FranchiseOrderTab />
        </>) : Tab === "sale_order" ? (<>
            <SaleOrderTab />
        </>) : Tab === "stock_transfer" ? (<>
            <StocktransferTab />
        </>) : (<>
            <SaleOrderChildTab />
        </>)}

        {(commonMainLoader?.url === "order_add" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default PurchaseOrder;