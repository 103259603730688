/* eslint-disable */
import React, { useEffect } from "react";
import { Nav, Collapse } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CATEGORY_URL, DASHBOARD_URL, PRODUCT_APPLICATIONS_URL, SUB_CATEGORY_URL, ACCOUNT_URL, PRODUCT_CREATION_URL,RETURN_AND_REPLACE_REPORT, CREATE_PRODUCT_APLICATION_URL, RETURN_AND_REPLACE_REPORT_LIST, STORE_INWARD_LOG_URL, CREATE_CATEGORY_URL, CREATE_SUB_CATEGORY_URL, CREATE_PRODUCT_URL, RM_LOGIN_URL, FRANCHISE_USER_REPORT_URL, CREATE_FRANCHISE_USER_REPORT_URL, STOCK_INWARD_URL, STOCK_LEVEL_URL, STORE_DETAILS_URL, STORE_DETAILS_REPORT_URL, FRANCHISE_REPORT_DETAILS_URL, STOCK_TRANSFER_URL, WAREHOUSE_URL, CREATE_WAREHOUSE_URL, WAREHOUSE_QUICK_URL, FRANCHISE_ORDERSREPORT_URL, E_WAY_BILL_URL, FRANCHISE_STOCK_LEVEL_URL, E_INVOICE_CANCEL_URL, E_INVOICE_DETAILS_URL, PUR_INVOICE_DETAILS_URL, PUR_ORDER_DELIVERED_URL, FRANCHISE_PENDING_DELIVERY_URL, FRANCHISE_DISPATCH_PENDING_URL, FRANCHISE_DISPATCH_URL, FRANCHISE_PENDING_ORDERS_URL, PO_APPROVALS_URL, PO_POSTING_URL, USER_MANAGEMENT_URL, REGION_MASTER_URL, ADD_REGION_MASTER_URL, CITIES_URL, STATES_URL, ADD_CITIES_URL, ADD_STATES_URL, PINE_CODES_URL, CREATE_USER_MANAGEMENT_URL, FRANCHISE_TYPE_REPORT_URL, ADD_FRANCHISE_TYPE_REPORT_URL, PRODUCT_GROUP_URL, CREATE_PRODUCT_GROUP_URL, PURCHASE_ORDER_URL, DISPATCH_LIST_URL, CREATE_DISPATCH_LIST_URL, COURIERS_URL, ORDER_CANCELLED_URL, BOX_URL, DEBIT_CREDIT_NOTES, ACCOUNT_LEDGER_REPORT, GSTDATAREPORT, GST_DATA_REPORT, OUT_STANDING_DATA_REPORT, DEBIT_CREDIT_NOTE_REPORT, DEPOSIT_REPORT, PAYMENT_APPROVAL, CREDIT_NOTE_REQUEST, CREDIT_NOTE_REPORT, PURCHASE_LIST_URL, ADD_PINCODES_URL, FRANCHISE_USER_REPORT_BRAND } from "../constant";
import { useSelector, useDispatch } from "react-redux";
import { SearchPincodeList, SearchCommonPincodeList, ErrorMessageFromRedux, GetReturnReplaceInvoiceOrderDetails, GetReturnReplaceFramesOrderDetails } from "../../../redux/actions/adminActions";

const SidebarChild = (props) => {
    const { selfInfo, MainNavUrl } = useSelector((state) => state.adminReducers);
    const { menuStates, setMenuStates } = props;
    const Dispatch = useDispatch();
    const LocationURL = useLocation().pathname;
    const LocationEditURL = LocationURL?.split("/")[1];
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // toggle menu
    const toggleMenu = (menu) => {
        const updatedMenuStates = { ...menuStates };
        Object.keys(updatedMenuStates).forEach((key) => {
            if (key === menu) {
                updatedMenuStates[key] = !updatedMenuStates[key];
            } else {
                updatedMenuStates[key] = false;
            }
        });
        setMenuStates(updatedMenuStates);
    };

    // Update Toggle
    useEffect(() => {
        if (MainNavUrl === "Inventory") {
            if (LocationURL === PRODUCT_APPLICATIONS_URL || LocationURL === CATEGORY_URL || LocationURL === SUB_CATEGORY_URL || LocationURL === PRODUCT_CREATION_URL || LocationURL === CREATE_PRODUCT_APLICATION_URL || LocationURL === CREATE_CATEGORY_URL || LocationURL === CREATE_SUB_CATEGORY_URL || LocationURL === CREATE_PRODUCT_URL || LocationURL === FRANCHISE_USER_REPORT_URL || LocationEditURL === "edit-product-application" || LocationEditURL === "edit-category" || LocationEditURL === "edit-subcategory" || LocationEditURL === "edit-product" || LocationURL === PRODUCT_GROUP_URL) {
                setMenuStates({
                    ...menuStates,
                    menu1: true
                });
            } else if (LocationURL === STOCK_INWARD_URL || LocationURL === STOCK_LEVEL_URL || LocationURL === STORE_DETAILS_URL || LocationURL === STORE_DETAILS_REPORT_URL || LocationURL === FRANCHISE_REPORT_DETAILS_URL || LocationURL === STORE_INWARD_LOG_URL) {
                setMenuStates({
                    ...menuStates,
                    menu2: true
                });
            } else if (LocationURL === STOCK_TRANSFER_URL) {
                setMenuStates({
                    ...menuStates,
                    menu3: true
                });
            } else if (LocationURL === WAREHOUSE_URL || LocationURL === CREATE_WAREHOUSE_URL || LocationEditURL === "edit-warehouse" || LocationURL === WAREHOUSE_QUICK_URL) {
                setMenuStates({
                    ...menuStates,
                    menu4: true
                });
            };
        } else if (MainNavUrl === "Admin-Tools") {
            if (LocationURL === ACCOUNT_URL || LocationURL === USER_MANAGEMENT_URL || LocationURL === CREATE_USER_MANAGEMENT_URL || LocationEditURL === "edit-user" || LocationURL === FRANCHISE_USER_REPORT_URL || LocationURL === CREATE_FRANCHISE_USER_REPORT_URL || LocationEditURL === "edit-franchise-user-report" || LocationURL === FRANCHISE_TYPE_REPORT_URL || LocationURL === ADD_FRANCHISE_TYPE_REPORT_URL || LocationEditURL === "edit-franchise-type-report" || LocationURL === RM_LOGIN_URL || LocationURL === FRANCHISE_USER_REPORT_BRAND) {
                setMenuStates({
                    ...menuStates,
                    menu1: true
                });
            } else if (LocationURL === CITIES_URL || LocationURL === STATES_URL || LocationURL === ADD_CITIES_URL || LocationURL === ADD_STATES_URL || LocationURL === PINE_CODES_URL || LocationURL === ADD_PINCODES_URL || LocationEditURL == "edit-pincode" || LocationURL === BOX_URL || LocationURL === COURIERS_URL) {
                setMenuStates({
                    ...menuStates,
                    menu2: true
                });
            };
        } else if (MainNavUrl === "Franchise") {
            if (LocationURL === FRANCHISE_ORDERSREPORT_URL || LocationURL === E_WAY_BILL_URL || LocationURL === FRANCHISE_STOCK_LEVEL_URL || LocationURL === E_INVOICE_CANCEL_URL || LocationURL === E_INVOICE_DETAILS_URL || LocationURL === PUR_INVOICE_DETAILS_URL || LocationURL === PUR_ORDER_DELIVERED_URL || LocationURL === FRANCHISE_PENDING_DELIVERY_URL || LocationURL === FRANCHISE_DISPATCH_PENDING_URL || LocationURL === FRANCHISE_DISPATCH_URL || LocationURL === FRANCHISE_PENDING_ORDERS_URL || LocationURL === PO_APPROVALS_URL || LocationURL === PO_POSTING_URL || LocationURL === PURCHASE_ORDER_URL || LocationEditURL === "so-approval" || LocationEditURL === "warehouse-approval" || LocationURL === DISPATCH_LIST_URL || LocationURL === CREATE_DISPATCH_LIST_URL || LocationEditURL === "edit-dispatch" || LocationURL === ORDER_CANCELLED_URL || LocationURL === E_INVOICE_DETAILS_URL || LocationURL === E_INVOICE_CANCEL_URL || LocationURL === E_WAY_BILL_URL || LocationURL === RETURN_AND_REPLACE_REPORT || LocationURL === RETURN_AND_REPLACE_REPORT_LIST) {
                setMenuStates({
                    ...menuStates,
                    menu1: true
                }); 
            }else if(LocationURL === DEBIT_CREDIT_NOTES || LocationURL === ACCOUNT_LEDGER_REPORT || LocationURL=== DEBIT_CREDIT_NOTE_REPORT || LocationURL === DEPOSIT_REPORT || LocationURL === PAYMENT_APPROVAL || LocationURL === CREDIT_NOTE_REQUEST || LocationURL === CREDIT_NOTE_REPORT || LocationEditURL === DEBIT_CREDIT_NOTES || LocationURL === GST_DATA_REPORT){
                setMenuStates({
                    ...menuStates,
                    menu2: true
                });
            }
        } else if (MainNavUrl === "CRM-Tools") {
            if (LocationURL === REGION_MASTER_URL || LocationURL === ADD_REGION_MASTER_URL || LocationEditURL === "edit-region-master" || LocationURL === PURCHASE_LIST_URL || LocationEditURL === "edit-purchase") {
                setMenuStates({
                    ...menuStates,
                    menu1: true
                });
            };
        }else if (MainNavUrl === "Sales") {
            if (LocationURL === OUT_STANDING_DATA_REPORT) {
                setMenuStates({
                    ...menuStates,
                    menu3: true
                });
            };
        };

        // REGION_MASTER_URL
    }, [MainNavUrl]);

    useEffect(() => {
        return () => {
            Dispatch(SearchPincodeList({}));
            Dispatch(SearchCommonPincodeList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
            Dispatch(GetReturnReplaceInvoiceOrderDetails([]));
            Dispatch(GetReturnReplaceFramesOrderDetails({}));
            
        };
    }, [LocationURL]);

    
    return (<div className="sidebar">
        <Scrollbars
            style={{ height: "calc(100vh - 50px)" }}
            renderView={props => <div {...props} className="view" />}
        >
            {MainNavUrl === "Inventory" ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {/* {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters?.filter((elm) => elm?.API !== "")?.length > 0) &&
                (<Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                    <i className="bi bi-box-seam"></i>
                    Product Masters
                    <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[0]?.Edit || PermissionsAccess?.ProductMasters[0]?.View)) && (<li>
                                <Link className={(LocationURL === PRODUCT_APPLICATIONS_URL || LocationURL === CREATE_PRODUCT_APLICATION_URL || LocationEditURL === "edit-product-application") ? "active" : ""} to={PRODUCT_APPLICATIONS_URL}>Product Applications</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[1]?.Edit || PermissionsAccess?.ProductMasters[1]?.View)) && (<li>
                                <Link className={(LocationURL === CATEGORY_URL || LocationURL === CREATE_CATEGORY_URL || LocationEditURL === "edit-category") ? "active" : ""} to={CATEGORY_URL}>Category</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[2]?.Edit || PermissionsAccess?.ProductMasters[2]?.View)) && (<li>
                                <Link className={(LocationURL === SUB_CATEGORY_URL || LocationURL === CREATE_SUB_CATEGORY_URL || LocationEditURL === "edit-subcategory") ? "active" : ""} to={SUB_CATEGORY_URL}>Sub Category</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[3]?.Edit || PermissionsAccess?.ProductMasters[3]?.View)) && (<li>
                                <Link className={(LocationURL === PRODUCT_GROUP_URL || LocationURL === CREATE_PRODUCT_GROUP_URL || LocationEditURL === "edit-product-group") ? "active" : ""} to={PRODUCT_GROUP_URL}>Product Group</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) && (<li>
                                <Link className={(LocationURL === PRODUCT_CREATION_URL || LocationURL === CREATE_PRODUCT_URL || LocationEditURL === "edit-product") ? "active" : ""} to={PRODUCT_CREATION_URL}>Product Creation</Link>
                            </li>)}
                        </ul>
                    </div>
                </Collapse> */}
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Store?.filter((elm) => elm?.API !== "")?.length > 0) && (<Nav.Link className={Object.assign(menuStates)["menu2"] ? "active" : ""} onClick={() => toggleMenu('menu2')} aria-controls="menu2" aria-expanded={menuStates.menu2}>
                    <i className="bi bi-shop-window"></i>
                    Stores
                    <span className={Object.assign(menuStates)["menu2"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu2}>
                    <div id="menu2">
                        <ul>
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[0]?.Edit || PermissionsAccess?.Store[0]?.View)) && (<li>
                                <NavLink className={LocationURL === STOCK_INWARD_URL ? "active" : ""} to={STOCK_INWARD_URL}>Stock Inward</NavLink>
                            </li>)} */}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) && (<li>
                                    <Link className={LocationURL === STOCK_LEVEL_URL ? "active" : ""} to={STOCK_LEVEL_URL}>Stock Level</Link>
                            </li>)}
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[2]?.Edit || PermissionsAccess?.Store[2]?.View)) && (<li>
                                    <Link className={LocationURL === STORE_DETAILS_URL ? "active" : ""} to={STORE_DETAILS_URL}>Stock Details</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) && (<li>
                                <Link className={LocationURL === STORE_INWARD_LOG_URL ? "active" : ""} to={STORE_INWARD_LOG_URL}>Stock Inward Log</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) && (<li>
                                <Link className={LocationURL === STORE_DETAILS_REPORT_URL ? "active" : ""} to={STORE_DETAILS_REPORT_URL}>Stock Details Report</Link>
                            </li>)} */}
                        </ul>
                    </div>
                </Collapse>
                {/* {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse?.filter((elm) => elm?.API !== "")?.length > 0) && (<Nav.Link className={Object.assign(menuStates)["menu4"] ? "active" : ""} onClick={() => toggleMenu('menu4')} aria-controls="menu4" aria-expanded={menuStates.menu4}>
                    <i className="bi bi-houses"></i>
                    Warehouse
                    <span className={Object.assign(menuStates)["menu4"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu4}>
                    <div id="menu4">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[0]?.Edit || PermissionsAccess?.Warehouse[0]?.View)) && (<li>
                                <Link className={(LocationURL === WAREHOUSE_URL || LocationURL === CREATE_WAREHOUSE_URL || LocationEditURL === "edit-warehouse") ? "active" : ""} to={WAREHOUSE_URL}>Warehouse</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[1]?.Edit || PermissionsAccess?.Warehouse[1]?.View)) && (<li>
                                <Link className={LocationURL === WAREHOUSE_QUICK_URL ? "active" : ""} to={WAREHOUSE_QUICK_URL}>Warehouse Quick Login</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) && (<li>
                                <Link className={(LocationURL === DISPATCH_LIST_URL || LocationURL === CREATE_DISPATCH_LIST_URL || LocationEditURL === "edit-dispatch") ? "active" : ""} to={DISPATCH_LIST_URL}>Dispatch List</Link>
                            </li>)}
                        </ul>
                    </div>
                </Collapse> */}
            </Nav>) : MainNavUrl === "Admin-Tools" ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools?.filter((elm) => elm?.API !== "")?.length > 0) && (<Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                    <i className="bi bi-person-fill-gear"></i>
                    Admin Tools
                    <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            <li>
                                {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[0]?.Edit || PermissionsAccess?.AdminTools[0]?.View)) && (<li>
                                    <Link className={(LocationURL === USER_MANAGEMENT_URL || LocationURL === CREATE_USER_MANAGEMENT_URL || LocationEditURL === "edit-user") ? "active" : ""} to={USER_MANAGEMENT_URL}>Users</Link>
                                </li>)} */}
                                <li>
                                    <Link className={LocationURL === ACCOUNT_URL ? "active" : ""} to={ACCOUNT_URL}>Account</Link>
                                </li>

                                
                                {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[1]?.Edit || PermissionsAccess?.AdminTools[1]?.View)) && (<li>
                                    <Link className={(LocationURL === FRANCHISE_USER_REPORT_URL || LocationURL === CREATE_FRANCHISE_USER_REPORT_URL || LocationEditURL === "edit-franchise-user-report") ? "active" : ""} to={FRANCHISE_USER_REPORT_URL}>Create Franchise</Link>
                                </li>)}
                                {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[2]?.Edit || PermissionsAccess?.AdminTools[2]?.View)) && (<li>
                                    <Link className={(LocationURL === FRANCHISE_TYPE_REPORT_URL || LocationURL === ADD_FRANCHISE_TYPE_REPORT_URL || LocationEditURL === "edit-franchise-type-report") ? "active" : ""} to={FRANCHISE_TYPE_REPORT_URL}>Franchise Type</Link>
                                </li>)}
                                {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[3]?.Edit || PermissionsAccess?.AdminTools[3]?.View)) && (<li>
                                    <Link className={LocationURL === RM_LOGIN_URL ? "active" : ""} to={RM_LOGIN_URL}>Regional Manager Login</Link>
                                </li>)} */}
                            </li>
                        </ul>
                    </div>
                </Collapse>
                {/* {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters?.filter((elm) => elm?.API !== "")?.length > 0) && (
                    <Nav.Link className={Object.assign(menuStates)["menu2"] ? "active" : ""} onClick={() => toggleMenu('menu2')} aria-controls="menu2" aria-expanded={menuStates.menu2}>
                        <i className="bi bi-mortarboard"></i>
                        Masters
                        <span className={Object.assign(menuStates)["menu2"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                    </Nav.Link>
                )}
                <Collapse className="collapnseDrop" in={menuStates.menu2}>
                    <div id="menu2">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[0]?.Edit || PermissionsAccess?.Masters[0]?.View)) && (<li>
                                <Link className={(LocationURL === STATES_URL || LocationURL === ADD_STATES_URL) ? "active" : ""} to={STATES_URL}>States</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[1]?.Edit || PermissionsAccess?.Masters[1]?.View)) && (<li>
                                <Link className={(LocationURL === CITIES_URL || LocationURL === ADD_CITIES_URL) ? "active" : ""} to={CITIES_URL}>Cities</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[2]?.Edit || PermissionsAccess?.Masters[2]?.View)) && (<li>
                                <Link className={(LocationURL === PINE_CODES_URL || LocationURL === ADD_PINCODES_URL || LocationEditURL === "edit-pincode") ? "active" : ""} to={PINE_CODES_URL}>Pincodes</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[3]?.Edit || PermissionsAccess?.Masters[3]?.View)) && (<li>
                                <Link className={LocationURL === BOX_URL ? "active" : ""} to={BOX_URL}>Box</Link>
                            </li>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[4]?.Edit || PermissionsAccess?.Masters[4]?.View)) && (<li>
                                <NavLink to={COURIERS_URL}>Couriers</NavLink>
                            </li>)}
                        </ul>
                    </div>
                </Collapse> */}
            </Nav>) : MainNavUrl === "Franchise" ? (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement?.filter((elm) => elm?.API !== "")?.length > 0) && (<Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                    <i className="bi bi-shop"></i>
                    Order Management
                    <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                </Nav.Link>)}
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[0]?.Edit || PermissionsAccess?.OrderManagement[0]?.View)) && (
                                <li><Link className={LocationURL === FRANCHISE_ORDERSREPORT_URL ? "active" : ""} to={FRANCHISE_ORDERSREPORT_URL}>All Orders Report</Link></li>
                            )}
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) && (
                                <li><Link className={LocationURL === PURCHASE_ORDER_URL ? "active" : ""} to={PURCHASE_ORDER_URL}>SO Creation</Link></li>
                            )} */}
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[2]?.Edit || PermissionsAccess?.OrderManagement[2]?.View)) && (
                                <li><Link className={(LocationURL === PO_POSTING_URL || LocationEditURL === "so-approval") ? "active" : ""} to={PO_POSTING_URL}>SO Approvals</Link></li>
                            )} */}
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[3]?.Edit || PermissionsAccess?.OrderManagement[3]?.View)) && (
                                <li><NavLink className={(LocationURL === PO_APPROVALS_URL || LocationEditURL === "warehouse-approval") ? "active" : ""} to={PO_APPROVALS_URL}>Warehouse Approval</NavLink></li>
                            )} */}
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[4]?.Edit || PermissionsAccess?.OrderManagement[4]?.View)) && (
                                <li><NavLink className={LocationURL === FRANCHISE_PENDING_ORDERS_URL ? "active" : ""} to={FRANCHISE_PENDING_ORDERS_URL}>Pending For Accounts</NavLink></li>
                            )} */}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[5]?.Edit || PermissionsAccess?.OrderManagement[5]?.View)) && (
                                <li><NavLink className={LocationURL === FRANCHISE_DISPATCH_URL ? "active" : ""} to={FRANCHISE_DISPATCH_URL}>Pending For Stores</NavLink></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[6]?.Edit || PermissionsAccess?.OrderManagement[6]?.View)) && (
                                <li><NavLink className={LocationURL === FRANCHISE_DISPATCH_PENDING_URL ? "active" : ""} to={FRANCHISE_DISPATCH_PENDING_URL}>Pending For Dispatch</NavLink></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[7]?.Edit || PermissionsAccess?.OrderManagement[7]?.View)) && (
                                <li><NavLink className={LocationURL === FRANCHISE_PENDING_DELIVERY_URL ? "active" : ""} to={FRANCHISE_PENDING_DELIVERY_URL}>Pending For Delivery</NavLink></li>
                            )}
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[8]?.Edit || PermissionsAccess?.OrderManagement[8]?.View)) && (
                                <li><Link className={LocationURL === PUR_ORDER_DELIVERED_URL ? "active" : ""} to={PUR_ORDER_DELIVERED_URL}>Order Delivered</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[9]?.Edit || PermissionsAccess?.OrderManagement[9]?.View)) && (
                                <li><Link className={LocationURL === PUR_INVOICE_DETAILS_URL ? "active" : ""} to={PUR_INVOICE_DETAILS_URL}>Rejected</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[10]?.Edit || PermissionsAccess?.OrderManagement[10]?.View)) && (
                                <li><Link className={LocationURL === ORDER_CANCELLED_URL ? "active" : ""} to={ORDER_CANCELLED_URL}>
                                    Cancellation
                                </Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[11]?.Edit || PermissionsAccess?.OrderManagement[11]?.View)) && (
                                <li><Link className={LocationURL === E_INVOICE_DETAILS_URL ? "active" : ""} to={E_INVOICE_DETAILS_URL}>E-Invoice Details</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[12]?.Edit || PermissionsAccess?.OrderManagement[12]?.View)) && (
                                <li><Link className={LocationURL === E_INVOICE_CANCEL_URL ? "active" : ""} to={E_INVOICE_CANCEL_URL}>E-Invoice Cancel</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[13]?.Edit || PermissionsAccess?.OrderManagement[13]?.View)) && (
                                <li><Link className={LocationURL === E_WAY_BILL_URL ? "active" : ""} to={E_WAY_BILL_URL}>E-Way Bill</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[14]?.Edit || PermissionsAccess?.OrderManagement[14]?.View)) && (
                                <li><Link className={(LocationURL === RETURN_AND_REPLACE_REPORT_LIST || LocationURL === RETURN_AND_REPLACE_REPORT) ? "active" : ""} to={RETURN_AND_REPLACE_REPORT_LIST}>Return And Replace</Link></li>
                            )} */}
                        </ul>
                    </div>
                </Collapse>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.PaymentManager?.filter((elm) => elm?.API !== "")?.length > 0) && (
                    <Nav.Link className={Object.assign(menuStates)["menu2"] ? "active" : ""} onClick={() => toggleMenu('menu2')} aria-controls="menu2" aria-expanded={menuStates.menu2}>
                        <i className="bi bi-credit-card"></i>
                        Payment Manager
                        <span className={Object.assign(menuStates)["menu2"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                    </Nav.Link>
                )}
                <Collapse className="collapnseDrop" in={menuStates.menu2}>
                    <div id="menu2">
                        <ul>
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[0]?.Edit || PermissionsAccess?.PaymentManager[0]?.View)) && (
                                <li><Link className={LocationURL === ACCOUNT_LEDGER_REPORT ? "active" : ""} to={ACCOUNT_LEDGER_REPORT}>Account Ledger Report</Link></li>
                            )}
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[1]?.Edit || PermissionsAccess?.PaymentManager[1]?.View)) && (
                                <li><Link className={(LocationURL === DEBIT_CREDIT_NOTES || LocationEditURL === "debit-credit-notes") ? "active" : ""} to={DEBIT_CREDIT_NOTES}>Debit/Credit Notes</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[2]?.Edit || PermissionsAccess?.PaymentManager[2]?.View)) && (
                                <li><Link className={LocationURL === DEBIT_CREDIT_NOTE_REPORT? "active" : ""} to={DEBIT_CREDIT_NOTE_REPORT}>Debit/Credit Note Report</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[3]?.Edit || PermissionsAccess?.PaymentManager[3]?.View)) && (
                                <li><Link className={LocationURL === PAYMENT_APPROVAL? "active" : ""} to={PAYMENT_APPROVAL}>Payment Approval</Link></li>
                            )}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[4]?.Edit || PermissionsAccess?.PaymentManager[4]?.View)) && (
                                <li><Link className={LocationURL === GST_DATA_REPORT ? "active" : ""} to={GST_DATA_REPORT}>GST Data Report</Link></li>
                            )} */}
                        </ul>
                    </div>
                </Collapse> 
            </Nav>) : (<Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools?.filter((elm) => elm?.API !== "")?.length > 0) && (
                    <Nav.Link className={Object.assign(menuStates)["menu1"] ? "active" : ""} onClick={() => toggleMenu('menu1')} aria-controls="menu1" aria-expanded={menuStates.menu1}>
                        <i className="bi bi-pc-display"></i>
                        CRM
                        <span className={Object.assign(menuStates)["menu1"] ? "bi bi-caret-down-fill" : "bi bi-caret-right-fill"}></span>
                    </Nav.Link>
                )}
                <Collapse className="collapnseDrop" in={menuStates.menu1}>
                    <div id="menu1">
                        <ul>
                            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[0]?.Edit || PermissionsAccess?.CRMTools[0]?.View)) && (<li>
                                <Link className={(LocationURL === REGION_MASTER_URL || LocationURL === ADD_REGION_MASTER_URL || LocationEditURL === "edit-region-master") ? "active" : ""} to={REGION_MASTER_URL}>Region Master</Link>
                            </li>)} */}
                            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[1]?.Edit || PermissionsAccess?.CRMTools[1]?.View)) && (<li>
                                <Link className={(LocationURL === PURCHASE_LIST_URL || LocationEditURL === "edit-purchase") ? "active" : ""} to={PURCHASE_LIST_URL}>Purchase List</Link>
                            </li>)}
                        </ul>
                    </div>
                </Collapse>
            </Nav>)}
        </Scrollbars>
    </div>)
};

export default SidebarChild;