
const PostRequestCall = async (url, payload, access_token, state) => {
  const headers = {
    'Authorization': 'Bearer ' + access_token,
  }
  if(state){
    headers['Accept'] = "application/json";
    headers['Content-Type'] = "application/json";
  }
    const responce = window.axios.post(url, payload, { headers:headers }).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default PostRequestCall;