/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import "react-datepicker/dist/react-datepicker.css";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import Select from 'react-select'
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { SearchPincodeList, StartCommonLoader, ErrorMessageFromRedux, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import CheckValid from "../../Common/CheckValid";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";
import CommonewModel from "../../Common/CommonewModel";
import AmountNumberFormat from "../../Common/AmountNumberFormat";

const SaleOrderTab = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, ClearFormSet, subCategorylistall, categorylistall, productlistall, ErrorMessageFromReddux, searchPincodeList, commonLoader, selfInfo } = useSelector((state) => state.adminReducers);
    const [ selectProduct, setselectProduct ] = useState("");
    const [ selectSubCategory, setselectSubCategory ] = useState("");
    const [ selectCategory, setselectCategory ] = useState("");
    const [ selectItemName, setselectItemName ] = useState("");
    const [ AddQuantity, setAddQuantity ] = useState(1);
    const optionsProducts = productlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.product_name}));
    const [ optionsCategory, setoptionsCategory ] = useState(() => categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.category_name })));
    const [ optionsSubCategory, setoptionsSubCategory ] = useState([]);
    const [ optionsItemName, setoptionsItemName ] = useState([]);
    let GetCurrentProduct = productlistall?.filter((elm) => elm?.id === selectProduct?.value)[0];

    const [ ApplicationState, setApplicationState ] = useState({
        Name: "",
        Mobile: "",
        Email: "",
        PINCode: "",
        GSTIN: "",
        Address: "",
        address2: "",
    }); 
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: "",
        Data: {}
    });
    const [ PreviewOrder, setPreviewOrder ] = useState(false);
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const pinRegex = /^\d{6}$/; // eslint-disable-line
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // error state
    const [ errorPINCode, seterrorPINCode ] = useState("");
    const [ errorName, seterrorName ] = useState("");
    const [ errorMobile, seterrorMobile ] = useState("");
    const [ errorEmail, seterrorEmail ] = useState("");
    const [ errorAddress, seterrorAddress ] = useState("");
    const [ errorAddress2, seterrorAddress2 ] = useState("");
    const [ errorGSTIN, seterrorGSTIN ] = useState("");

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get category all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "subcategory_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all product list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all franchise list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    
    const [ FilterDisable, setFilterDisable ] = useState(false);
    // Product select
    const ProductSelect = (value) => {
        if(value !== null) {
            setselectProduct(value);
            const ProductCurrent = productlistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === value?.value)[0];
            const GetCurrentSubCategory = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === ProductCurrent?.sub_category_id)[0];
            const CategoryCurrent = categorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === GetCurrentSubCategory?.category_id);
            const GetCurrentNewSubCategory = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === ProductCurrent?.sub_category_id);
            const ProductNewCurrent = productlistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === value?.value);
            setoptionsCategory(CategoryCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })))
            setoptionsSubCategory(GetCurrentNewSubCategory?.map((item, index)=>({key:item.id, value:item?.id , label:item?.sub_category_name })));
            setoptionsItemName(ProductNewCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name })));
            setselectCategory(CategoryCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name }))[0]);
            setselectSubCategory(GetCurrentNewSubCategory?.map((item, index)=>({key:item.id, value:item?.id , label:item?.sub_category_name }))[0]);
            setselectItemName(ProductNewCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name }))[0]);
            setFilterDisable(true);
        } else {
            setselectProduct("");
            setFilterDisable(false);
            setselectItemName(null);
            setselectSubCategory(null);
            setselectCategory(null);
            setoptionsSubCategory([]);
            setoptionsItemName([]);
            setoptionsCategory(categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })))
        }
    };
    
    // category select
    const CategorySelect = (value) => {
        setselectSubCategory(null);
        setselectItemName(null);
        if(value !== null) {
            setselectCategory(value);
            const CategoryCurrent = categorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === value?.value)[0];
            const SubCategoryCurrent = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.category_id === CategoryCurrent?.id);
            const ProductCurrent = productlistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.sub_category_id === SubCategoryCurrent?.id);
            setoptionsSubCategory(SubCategoryCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.sub_category_name })));
            setoptionsItemName(ProductCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name})));
        } else {
            setselectCategory("");
            setoptionsSubCategory([]);
            setoptionsItemName([]);
            setoptionsItemName([]);
        }
    };

    // sub category select
    const SubCategorySelect = (value) => {
        setselectItemName(null);
        if(value !== null) {
            setselectSubCategory(value);
            const SubCategoryCurrent = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === value?.value)[0];
            const ProductCurrent = productlistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.sub_category_id === SubCategoryCurrent?.id);
            setoptionsItemName(ProductCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name})));
        } else {
            setselectSubCategory("");
        }
    };

    const ItemSelect = (value) => {
        if(value !== null) {
            setselectItemName(value);
        } else {
            setselectItemName("");
        }
    };

    // search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchPincode();
        };
    };

    // search pincode
    const SearchPincode = () => {
        if(ApplicationState?.PINCode?.length === 6) {
            Dispatch(StartCommonLoader(true));
            let param = { 
                "transmit": "broadcast", 
                "url": "pin_code_search",
                "request" : { 
                    "pin_code" : ApplicationState?.PINCode, 
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            Dispatch(StartCommonLoader(false));
        }
    };

    const PinCOdeSearchFunction = (e) => {
        setApplicationState({...ApplicationState, PINCode: e.target.value});
        if (e.target.value.length === 6) {
            Dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "broadcast",
                "url": "pin_code_search",
                "request": {
                    "pin_code": e.target.value,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);

        } if (e.target.value === "") {
            Dispatch(SearchPincodeList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        } else {
            Dispatch(StartCommonLoader(false))
        }
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
          display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    const [ ProductListOrder, setProductListOrder ] = useState([]);
    var ProductMergAarray = [...ProductListOrder];

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    // add purchase order
    const PurchaseOrderAdd = () => {
        if(selectProduct !== "" && selectSubCategory !== "" && selectCategory !== "" && AddQuantity > 0) {
            let Obj = {
                id: generateRandomId(),
                product: selectProduct,
                sub_category: selectSubCategory,
                category: selectCategory,
                quantity: parseInt(AddQuantity),
            };
            ProductMergAarray?.push(Obj);
        
            let mergedArray = mergeAndUpdate(ProductMergAarray);
            // unique array convert
            setProductListOrder(mergedArray);
            PurchaseOrderClear();
        } else {

        };
    };

    // merge array and add quantity
    function mergeAndUpdate(arr) {
        let mergedArray = [];
        arr.forEach(item => {
            // Check if the item already exists in the merged array
            const existingItemIndex = mergedArray.findIndex(
                mergedItem => mergedItem?.product?.value === item?.product?.value && mergedItem.is_demo === item.is_demo
            );
    
            if (existingItemIndex !== -1) {
                // If the item exists, update its quantity
                mergedArray[existingItemIndex].quantity += item.quantity;
            } else {
                // If the item doesn't exist, add it to the merged array
                mergedArray.push({...item});
            }
        });
    
        return mergedArray;
    };


    // Function to handle adding quantity
    const addMainQuantity = (id) => {
        const AddQauntArray = ProductMergAarray?.map((elm) => {
            if(elm?.id === id) {
                return {
                    ...elm,
                    quantity: parseInt(elm?.quantity) + 1,
                }
            } else {
                return elm;
            }
        });
        setProductListOrder(AddQauntArray);
    };

    // Function to handle removing quantity
    const removeQuantity = (id, value) => {
        const RemovedQunty = ProductMergAarray?.map((elm) => {
            if(value === 1) {
                if(elm?.id !== id) {
                    return elm;
                }
            } else {
                if(elm?.id === id) {
                    return {
                        ...elm,
                        quantity: parseInt(elm?.quantity) - 1,
                    }
                } else {
                    return elm;
                }
            }
        })?.filter((elm) => elm !== undefined);
        setProductListOrder(RemovedQunty);
    };

    // remove order
    const DeleteOrder = (id) => {
        const RemoveOrder = [];
        ProductMergAarray?.map((elm) => {
            if(elm?.id !== id) {
                return RemoveOrder.push(elm);
            }
        });
        setProductListOrder(RemoveOrder);
    };

    // confirm order
    const PhoneCode = 91;
    const CountryStates = getRegionCodeForCountryCode(PhoneCode);
    const CheckMobileNumber = parsePhoneNumber(ApplicationState?.Mobile?.toString(), { regionCode: CountryStates });

    const ConfirmOrder = () => {
        const ProductListOrderStateArray = [];
        ProductListOrder?.filter((elm) => {
            return  ProductListOrderStateArray?.push({
                product_id: elm?.product?.value,
                product_qty: elm?.quantity,
                is_demo: false,
            });
        });
        
        if(ApplicationState?.Name.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && ApplicationState?.PINCode.trim() !== "" && ApplicationState?.Address.trim() !== "" &&
            ApplicationState?.address2?.length <= 100 && ApplicationState?.Address.length <= 100
        )  {
            if(EmailRegex.test(ApplicationState?.Email.trim()) === true && CheckMobileNumber?.valid === true && pinRegex?.test(ApplicationState?.PINCode.trim()) === true) {
                Dispatch(StartMainCommonLoader({
                    url: "order_add",
                    loader: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "order_add",
                    "request" : { 
                        "order_type" : "CO", 
                        "customer_name" : ApplicationState?.Name.trim(), 
                        "mobile": ApplicationState?.Mobile.trim(), 
                        "email": ApplicationState?.Email.trim(), 
                        "pin_code_id": searchPincodeList?.pin?.id, 
                        "gstin_no": ApplicationState?.GSTIN.trim(), 
                        "address": ApplicationState?.Address.trim(), 
                        "address2": ApplicationState?.address2?.trim(), 
                        "items": ProductListOrderStateArray
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
                CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail });
                CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
            }
        } else {
            CheckValid(ApplicationState?.Name.trim(), {type: 'Customername', error: seterrorName});
            CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
            CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
            CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail});
            CheckValid(ApplicationState?.Address.trim(), {type: 'Address', error: seterrorAddress});
            CheckValid(ApplicationState?.address2?.trim(), {type: 'address2', error: seterrorAddress2});
        };
    };

    const onChange = (e) => {
        let value = e.target.value;
    
        // Remove leading zeros
        value = value.replace(/^0+/g, "");
    
        // Remove non-numeric characters
        value = value.replace(/\D/g, "");
    
        // Ensure the value is more than 1 (not negative number)
        if (value === "" || parseInt(value) < 0) {
            value = ""; // Set default value to 1
        }
    
        // Update the state with the modified value
        setAddQuantity(parseInt(value));
    }

    const OrderPurchaseFunct = () => {
        if(ApplicationState?.GSTIN.trim() !== "") {
            if(ApplicationState?.Name.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && ApplicationState?.PINCode.trim() !== "" && ApplicationState?.Address.trim() !== "" &&
                ApplicationState?.address2?.length <= 100 && ApplicationState?.Address.length <= 100
            )  {
                if(EmailRegex.test(ApplicationState?.Email.trim()) === true && CheckMobileNumber?.valid === true && pinRegex?.test(ApplicationState?.PINCode.trim()) === true && (GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15)) {
                    setShowModalNew({
                        ...showModalNew,
                        open: open,
                        title: "Confirmation",
                        subtitle: "Order-Purchase",
                        modalType: "Order-Purchase",
                        description: "Are you sure to proceed for Order?",
                        modalType: "not form",
                        button: "Yes",
                        Data: {}
                    });
                } else {
                    CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
                    CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail });
                    CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                    if(GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) {
                        seterrorGSTIN("");
                    } else {
                        seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                    };
                }
            } else {
                CheckValid(ApplicationState?.Name.trim(), {type: 'Customername', error: seterrorName});
                CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
                CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail});
                CheckValid(ApplicationState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                CheckValid(ApplicationState?.address2?.trim(), {type: 'address2', error: seterrorAddress2});
                if(ApplicationState?.GSTIN.trim() !== "") {
                    if(searchPincodeList?.state?.gst_code === undefined) {
                        seterrorGSTIN(`Before you enter the GSTIN number, please enter the PIN code.`);
                    } else {
                        if(GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) {
                            seterrorGSTIN("");
                        } else {
                            seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                        };
                    };
                } else {
                    // seterrorGSTIN("GSTIN cannot be empty");
                };
            };
        } else {
            if(ApplicationState?.Name.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && ApplicationState?.PINCode.trim() !== "" && ApplicationState?.Address.trim() !== "" &&
                ApplicationState?.address2?.length <= 100 && ApplicationState?.Address.length <= 100
            )  {
                if(EmailRegex.test(ApplicationState?.Email.trim()) === true && CheckMobileNumber?.valid === true && pinRegex?.test(ApplicationState?.PINCode.trim()) === true) {
                    setShowModalNew({
                        ...showModalNew,
                        open: open,
                        title: "Confirmation",
                        subtitle: "Order-Purchase",
                        modalType: "Order-Purchase",
                        description: "Are you sure to proceed for Order?",
                        modalType: "not form",
                        button: "Yes",
                        Data: {}
                    });
                } else {
                    CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
                    CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail });
                    CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                }
            } else {
                CheckValid(ApplicationState?.Name.trim(), {type: 'Customername', error: seterrorName});
                CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
                CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail});
                CheckValid(ApplicationState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                CheckValid(ApplicationState?.address2?.trim(), {type: 'address2', error: seterrorAddress2});
            };
        }
        
    };

    // clear purchase order
    const PurchaseOrderClear = () => {
        setselectProduct("");
        setselectSubCategory("");
        setselectCategory("");
        setAddQuantity(1);
        setFilterDisable(false);
        setoptionsSubCategory([]);
        setoptionsItemName([]);
        setselectItemName("");
        setoptionsCategory(() => categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })));
    };

    // all clears data
    const PurchaseOrderMainClear = () => {
        setselectProduct("");
        setselectSubCategory("");
        setselectCategory("");
        PurchaseOrderClear();
        setProductListOrder([]);
        setFilterDisable(false);
        setoptionsSubCategory([]);
        setoptionsItemName([]);
        setselectItemName("");
        setoptionsCategory(() => categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })));
    };

    function getGstAmt(price, gstRate) {
        return (parseFloat(price)  - ( parseFloat(price) / (1 + (parseFloat(gstRate) / 100))));
    };

    // gst get
    const GetGSTAmount = () => {
        let total = 0;
        let total_gst = 0;
        let price = 0;
        let sub_total = 0;
        ProductListOrder?.filter((elm) => {
            const ProductGet = productlistall?.filter((elms) => elms?.id === elm?.product?.value)[0]
            price = ProductGet?.ecom_price;
            let gst_rate = ProductGet.igst_rate
            
            sub_total = parseFloat(parseFloat(price) * parseInt(elm?.quantity) * 100);
            total = total + sub_total;
            // total_gst = parseInt(total_gst + (parseInt((parseInt(gst_rate) / 100) * sub_total)));
            total_gst = total_gst + (getGstAmt(price, gst_rate) * parseInt(elm?.quantity))

        });
        
        return {
            total: total/100,
            total_gst: total_gst,
        };
    };

    const GetCurrentOrderRate = (current, product) => {
        let price = 0;
        let sub_total = 0;
        price = parseFloat(product?.ecom_price === null ? 0 : product?.ecom_price);
       
        sub_total = parseFloat(parseFloat(price) * parseFloat(current?.quantity) * 100);
        
        return {
            price: price,
            sub_total: sub_total/100,
        };
    };

    const GSTvalidations = (value) => {
        if(value !== "") {
            const GSTDigit = searchPincodeList?.state?.gst_code?.length === 1 ? 2 : searchPincodeList?.state?.gst_code?.length
            const GSTDigitValid = searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code;

            const Substring = value.trim().substring(0, GSTDigit);

            if(Substring.toString() === GSTDigitValid.toString()) {
                return true;
            } else {
                return false;
            }
        };
    };

    const GSTINvalidKeyUp = (value) => {
        if(value !== "") {
            if(searchPincodeList?.state?.gst_code === undefined) {
                seterrorGSTIN(`Before you enter the GST number, please enter the PIN code.`);
            } else {
                if(GSTvalidations(value) && value?.length === 15) {
                    seterrorGSTIN("");
                } else {
                    seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                };
            };
        } else {
            // seterrorGSTIN("GSTIN cannot be empty");
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            PurchaseOrderMainClear();
            setApplicationState({
                ...ApplicationState,
                Name: "",
                Mobile: "",
                Email: "",
                PINCode: "",
                GSTIN: "",
                Address: "",
                address2: "",
            });
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
            setShowModalNew({
                ...showModalNew,
                open: false,
                Data: {}
            });
            setPreviewOrder(false);
            Dispatch(SearchPincodeList({}));
        };
    }, [ ClearFormSet ]);

    const DisableAddButton = selectProduct !== "" && selectSubCategory !== "" && selectCategory !== "" && AddQuantity > 0;

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });
    
    return(<section className="Main_section">
        {!PreviewOrder && (<div className="filtr_Form Purchase_Order_Mendetory" style={{ borderBottom: "1px solid #eee" }}>
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group className="mb-3">
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select 
                                        onChange={(e) => ProductSelect(e)} 
                                        value={selectProduct}
                                        isClearable 
                                        options={optionsProducts}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="Select Products"
                                        styles={customStyles}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                    <Select 
                                        onChange={(e) => CategorySelect(e)} 
                                        value={selectCategory}
                                        // defaultValue={DefaultCategory} 
                                        isClearable 
                                        options={optionsCategory}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="Select Category"
                                        styles={customStyles}
                                        isDisabled={FilterDisable}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                    <Select 
                                        onChange={(e) => SubCategorySelect(e)} 
                                        value={selectSubCategory}
                                        // defaultValue={DefaultSubCategory} 
                                        // isClearable 
                                        options={optionsSubCategory}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="Select Sub Category"
                                        styles={customStyles}
                                        isDisabled={FilterDisable}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                    <Select 
                                        onChange={(e) => ItemSelect(e)} 
                                        value={selectItemName}
                                        // defaultValue={DefaultSubCategory} 
                                        // isClearable 
                                        options={optionsItemName}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="Select Item Name"
                                        styles={customStyles}
                                        isDisabled={FilterDisable}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <Form.Control 
                                    type="number"
                                    value={AddQuantity} 
                                    className="inputBoxs"
                                    onChange={onChange}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Quantity"
                                    maxLength={3}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <button className="btn exportbtn" disabled={!DisableAddButton} id="PurchaseOrderbtn" onClick={() => PurchaseOrderAdd()}>
                                Add
                            </button>
                            <button className="btn exportbtn" style={{ marginLeft: "10px" }} id="PurchaseOrderClearbtn" onClick={() => PurchaseOrderClear()}>
                                <i className="bi bi-x-circle-fill"></i>&nbsp;Clear
                            </button>
                        </div>
                    </div>
                </div>
            </div>)}
            <div className="tableView">
                <Scrollbars 
                    style={{ 
                        height: PreviewOrder ? "calc(100vh - 146px)" : GetCurrentProduct === undefined ? "calc(100vh - 270px)" : "calc(100vh - 270px)"
                    }} 
                    renderView={props => <div {...props} className="view"/>}
                    renderTrackHorizontal={renderTrack}
                    className="ScrollbarsSidebar"
                >
                    {PreviewOrder && (<div className="FranchiseWallet" style={{ justifyContent: "end" }}>
                        <button className="btn btn-link backbtn" onClick={() => setPreviewOrder(false)} style={{ display: "flex" }}>
                            <i className="bi bi-arrow-left-short"></i> Back
                        </button>
                    </div>)}

                    {ProductListOrder?.length > 0 && (<>
                        <div className="tableView">
                            <Table responsive bordered  >
                                <thead>
                                    <tr>
                                        <th>SNO</th>
                                        <th>Product Code</th>
                                        <th>Product Name</th>
                                        <th>Rate</th>
                                        <th>Quantity</th>
                                        <th>Amount</th>
                                        {!PreviewOrder && (<th>Action</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {ProductListOrder?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{elm?.product?.product_code}</td>
                                            <td>{elm?.product?.product_name}</td>
                                            <td>₹ {AmountNumberFormat(GetCurrentOrderRate(elm, elm?.product)?.price)}</td>
                                            <td>
                                                {PreviewOrder ? elm?.quantity : (<div className="QauntityManage">
                                                    <button className="btn remove" onClick={() => removeQuantity(elm?.id, elm?.quantity)}>
                                                        <i className="bi bi-dash-circle-fill"></i>
                                                    </button>
                                                    <h2>{elm?.quantity}</h2>
                                                    <button className="btn add" onClick={() => addMainQuantity(elm?.id)}>
                                                        <i className="bi bi-plus-circle-fill"></i>
                                                    </button>
                                                </div>)}
                                            </td>
                                            <td>₹ {AmountNumberFormat(GetCurrentOrderRate(elm, elm?.product)?.sub_total)}</td>
                                            {!PreviewOrder && (<td>
                                                <Button className="btn edittable" onClick={() => DeleteOrder(elm?.id)} style={{ marginLeft: "7px" }}>
                                                    <i className="bi bi-trash" style={{ color: "red" }}></i> 
                                                </Button>
                                            </td>)}
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        {!PreviewOrder && (<div className="btnGroupswraps" id="btnGroupswraps">
                            <Button className="Addbtn" onClick={() => setPreviewOrder(!PreviewOrder)}>Preview</Button>
                        </div>)}
                        {PreviewOrder && (<>
                            <ul className="GSTAmounttotal">
                                <li className="totaltd">
                                    <label>GST: &nbsp;</label>
                                    ₹ {AmountNumberFormat(GetGSTAmount()?.total_gst)}
                                </li>
                                <li className="totaltd">
                                    <label>Net Amount: &nbsp;</label>
                                    ₹ {AmountNumberFormat(GetGSTAmount()?.total)}
                                </li>
                            </ul>
                            <div className="formloginset">
                                <div className="HeaderDivider" >
                                    <h6 className="subtitleForm PurchaseOrderCheckbox">
                                        Delivery Address
                                    </h6>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Customer name<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationState?.Name} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, Name: e.target.value})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Customername', error: seterrorName})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Customer name" 
                                                />
                                                {errorName !== "" &&<span className="error_medotory">{errorName}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Mobile Number<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="number" 
                                                    className="inputBoxs"
                                                    value={ApplicationState?.Mobile} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, Mobile: e.target.value.replace(/^0/, "")})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'phone', error: seterrorMobile})}
                                                    onKeyDown={(e) => {
                                                        EmptySpaceFieldValid(e)
                                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                    }}
                                                    placeholder="Enter Mobile Number" 
                                                />
                                                {errorMobile !== "" &&<span className="error_medotory">{errorMobile}</span>}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Email Address<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationState?.Email} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, Email: e.target.value})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: seterrorEmail})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Email Address" 
                                                />
                                                {errorEmail !== "" &&<span className="error_medotory">{errorEmail}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>
                                                    PIN Code
                                                    <span className="mendatory_star">*</span>
                                                    <OverlayTrigger
                                                        placement="left"
                                                        delay={{ show: 250, hide: 100 }}
                                                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                                        Search by either hitting the enter button or clicking on the search icon.
                                                    </Tooltip>}
                                                    >
                                                        <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div className="serachBoxWIth">
                                                    <Form.Control 
                                                        type="text" 
                                                        value={ApplicationState?.PINCode} 
                                                        onChange={(e) => PinCOdeSearchFunction(e)}
                                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'PINCode', error: seterrorPINCode})}
                                                        onKeyDown={(e) => {
                                                            EmptySpaceFieldValid(e);
                                                            onEnterPincodeSearch(e)
                                                        }}
                                                        disabled={commonLoader}
                                                        maxLength={6}
                                                        placeholder="Enter PIN Code" 
                                                    />
                                                    <Button className="searchbtn" onClick={() => SearchPincode()}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                                            <path d="M21 21l-6 -6"></path>
                                                        </svg>
                                                    </Button>
                                                    
                                                    {ErrorMessageFromReddux?.API === "pin_code_search" && (<>
                                                        <div className="pincode_error">
                                                            {ErrorMessageFromReddux?.Error !== "" ? (
                                                                <i className="bi bi-x-circle-fill" onClick={() => setApplicationState({...ApplicationState, PINCode: ""})} style={{ color: "red" }}></i>
                                                            ) : (<i className="bi bi-check-circle-fill" style={{ color: "#34b739" }}></i>)}
                                                        </div>
                                                    </>)}
                                                    {commonLoader && (<Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>)}
                                                </div>
                                                {errorPINCode !== "" &&<span className="error_medotory">{errorPINCode}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>City</Form.Label>
                                                <div className="defaultvalueField">
                                                    {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.city?.district_name : ""}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>State</Form.Label>
                                                <div className="defaultvalueField">
                                                    {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.state?.state_name : ""}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>GSTIN(Optional)</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationState?.GSTIN} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, GSTIN: e.target.value})}
                                                    onKeyUp={(e) => GSTINvalidKeyUp(e?.target?.value)}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter GSTIN"
                                                />
                                                {(ApplicationState?.GSTIN !== "" && errorGSTIN !== "") &&<span className="error_medotory">{errorGSTIN}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Address Line 1<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    as="textarea" rows={3}
                                                    value={ApplicationState?.Address} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, Address: e.target.value})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: seterrorAddress})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Address" 
                                                />
                                                {errorAddress !== "" &&<span className="error_medotory">{errorAddress}</span>}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Address Line 2(Optional) </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    as="textarea" rows={3}
                                                    value={ApplicationState?.address2} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, address2: e.target.value})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'address2', error: seterrorAddress2})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Address" 
                                                />
                                                {errorAddress2 !== "" &&<span className="error_medotory">{errorAddress2}</span>}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ textAlign: "right" }}>
                                            {selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit) ? (<Button className="formbtn" onClick={() => OrderPurchaseFunct()} style={{ width: "auto" }} variant="primary" >
                                                Confirm Order
                                            </Button>) : <span style={{ color: "red" }}>You don't have permission to create an order.</span>}
                                            <Button className="formbtnback" id="backbtbackgt" style={{ width: "auto", marginLeft: "10px" }} onClick={() => setPreviewOrder(false)}>Back</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </>)}
                </Scrollbars>
            </div>

            {showModalNew?.open && (<CommonewModel ConfirmOrder={ConfirmOrder} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}
    </section>)
}

export default SaleOrderTab;