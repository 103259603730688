/* eslint-disable */
import React from "react";
import { Nav, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import FranchisseIcon from "../../../assets/img/Franchise.png";
import FranchisseActiveIcon from "../../../assets/img/Franchise-active.png";
import CRMIcon from "../../../assets/img/crm.png";
import CRMActiveIcon from "../../../assets/img/crm-active.png";
import { useDispatch, useSelector } from "react-redux";
import { SelectMainNavigation } from "../../../redux/actions/adminActions";

const SidebarMain = (props) => {
    const { selfInfo } = useSelector((state) => state.adminReducers);
    const { menuStates, setMenuStates } = props;
    const Dispatch = useDispatch();
    const { MainNavUrl } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // clear menu selection and menu select
    const ClearMenuSelection = (url) => {
        Dispatch(SelectMainNavigation(url));
        setMenuStates({
            ...menuStates,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
        });
    };
    
    return(<div className="sidebarMain">
        <Nav className="flex-column">
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Store?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Warehouse?.filter((elm) => elm?.API !== "")?.length > 0)) &&
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>Inventory</Tooltip>}
                >
                    <a className={MainNavUrl === "Inventory" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Inventory")}>
                        <i className="bi bi-briefcase-fill"></i>
                    </a>
                </OverlayTrigger>
            }
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Masters?.filter((elm) => elm?.API !== "")?.length > 0)) &&
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                    Admin Tools
                </Tooltip>}
                >
                    <a className={MainNavUrl === "Admin-Tools" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Admin-Tools")}>
                        <i className="bi bi-person-fill-gear"></i>
                    </a>
                </OverlayTrigger>
            }
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.PaymentManager?.filter((elm) => elm?.API !== "")?.length > 0)) &&
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Order Management
              </Tooltip>}
            >
                <a className={MainNavUrl === "Franchise" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Franchise")}>
                    <Image src={FranchisseIcon} className="inactive" style={{ width: "18px" }} alt="Franchise"/>
                    <Image src={FranchisseActiveIcon} className="active" style={{ width: "18px" }} alt="Franchise" />
                </a>
            </OverlayTrigger>}
            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools?.filter((elm) => elm?.API !== "")?.length > 0) &&
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                CRM Tools
              </Tooltip>}
            >
                <a className={MainNavUrl === "CRM-Tools" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("CRM-Tools")}>
                    <Image src={CRMIcon} className="inactive" style={{ width: "21px" }} alt="Franchise"/>
                    <Image src={CRMActiveIcon} className="active" style={{ width: "21px" }} alt="Franchise" />
                </a>
            </OverlayTrigger>}
        </Nav>
    </div>)
};

export default SidebarMain;