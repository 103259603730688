/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrderDataTableFilter from "../../Common/OrderDataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { FRANCHISE_PENDING_DELIVERY_URL, MARKET_PLACE_INVOICE_API_URL } from "../../Shared/constant";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MoreIcon from '../../../assets/img/more_options.png';
import { TimeSense, getColor } from "../../Common/TimeSense";
import { ClearFormSetFutios, getTableSorting, updateOrderManagementFilter } from "../../../redux/actions/adminActions";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";
import ResponsiveFilter from "../../Common/ResponsiveFilter";

const FranchisePendingDelivery = (props) => {
    const navigate = useNavigate();
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const [userType, setUserType] = useState("all");
    const { websocket, ResponsiveWidth } = useContext(WebSocketContext);
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const today = new Date();
    const { device_id, PendingDeliveryList, OrderManagmentFilter, Regionlistall, ClearFormSet, sortingfor, selfInfo } = useSelector((state) => state.adminReducers);
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const Location = useLocation()?.pathname?.replace("/", "");
    let ModuleWiseFilterState = Location === "all-orders-report" ? "AllOrdersReport" : Location === "SO-approvals" ? "SOApprovals" : Location === "warehouse-approvals" ? "WarehouseApprovals" : Location === "pending-for-accounts" ? "PendingForAccounts" : Location === "pending-for-stores" ? "PendingForStores" : Location === "pending-for-dispatch" ? "PendingForDispatch" : Location === "pending-for-delivery" ? "PendingForDelivery" : "OrderDelivered";
    const [ ResponsiveFilterModal, setResponsiveFilterModal ] = useState({
        open: false,
        title: "",
    })

    //Order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            Data: value
        });
    };

    useEffect(() => {
        Dispatch(getTableSorting(""))
    }, [])

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            PendingForDelivery: {
                CurrentPage: page,
            }
        }));
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        Dispatch(updateOrderManagementFilter({
            PendingForDelivery: {
                CurrentPage: 1,
            }
        }));
    };

    const selectRegionFunction = (e) => {
        Dispatch(updateOrderManagementFilter({
            PendingForDelivery: {
                SelectRegion: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            Dispatch(updateOrderManagementFilter({
                PendingForDelivery: {
                    FromDate: "",
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                PendingForDelivery: {
                    FromDate: date,
                }
            }));
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        Dispatch(updateOrderManagementFilter({
            PendingForDelivery: {
                CurrentPage: 1,
            }
        }));
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(OrderManagmentFilter?.PendingForDelivery?.FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            Dispatch(updateOrderManagementFilter({
                PendingForDelivery: {
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                PendingForDelivery: {
                    ToDate: date,
                }
            }));
        }
    };

    // get order_list all
    useEffect(() => {
        if (websocket) {
            const { UserLimit, UserSearch, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage } = OrderManagmentFilter?.PendingForDelivery;
            let param = {
                "transmit": "broadcast",
                "url": "order_list",
                "request": {
                    "status": "DISPATCHED",
                    "limit": UserLimit,
                    "page_no": CurrentPage,
                    "search": UserSearch.trim(),
                    "from_date": null,
                    "to_date": null,
                    "from_inv": null,
                    "to_inv": null,
                    "order_type": OrderSource,
                    "region_id": SelectRegion,
                    "order_by": sortingfor,
                },
                "DeviceId": device_id
            };
            if (FromDate !== "" && ToDate !== "") {
                param.request.from_inv = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                param.request.to_inv = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
            }
            wsSend_request(websocket, param);
        }
    }, [websocket, userType, OrderManagmentFilter?.PendingForDelivery, sortingfor]);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    // get franchise name
    const GetFranchiseName = (billing_id) => {
        const current = PendingDeliveryList?.franchise?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    // open order details modal
    const OrderDetailsModal = (order_code) => {
        navigate(`${FRANCHISE_PENDING_DELIVERY_URL}/${order_code}`);
    };

    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };
    
    const callInvoiceModal = (e, code) => {
        e.preventDefault();
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": code,
            },
            "DeviceId": device_id,
            "TabId": tabID,
            modal: true
        };
        wsSend_request(websocket, param);
    }

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    const GetRegionName = (id) => {
        if(id === null) {
            return "-";
        } else {
            const currentRegionData = PendingDeliveryList?.region_list?.filter((elm) => elm?.id === id)[0];
            return currentRegionData?.region_name
        }
    };

    // Market Place Invocie
    const MarketPlaceInvocie = async (invoice_code, uni_code) => {
        window.open(MARKET_PLACE_INVOICE_API_URL + "?invoice_code=" + invoice_code + "&uni_code=" + uni_code, '_blank');
    };

    // order Source update
    const OnChangeOrderSource = (value) => {
        Dispatch(updateOrderManagementFilter({
            [ModuleWiseFilterState]: {
                OrderSource: value,
                CurrentPage: 1,
            }
        }));
    };

    // select limit
    const SelectLimitFct = (e) => {
        Dispatch(updateOrderManagementFilter({
            [ModuleWiseFilterState]: {
                UserLimit: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // get page limit from redux
    const GetuserLimit = () => {
        return OrderManagmentFilter?.[ModuleWiseFilterState]?.UserLimit;
    };

    // filters commomn
    const SaprateFilterCommn = () => {
        return(<div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
        <div className="formloginset">
            <div className="row">
                <div className="col-md-3">
                    <Form.Group>
                        <div className="datepickField min">
                            <DatePicker
                                selected={OrderManagmentFilter?.PendingForDelivery?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDelivery?.FromDate)}
                                startDate={OrderManagmentFilter?.PendingForDelivery?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDelivery?.FromDate)}
                                endDate={OrderManagmentFilter?.PendingForDelivery?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDelivery?.ToDate)}
                                onChange={(date) => DateStartGet(date)}
                                dateFormat="dd/MM/yyyy"
                                maxDate={today}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter From Date"
                                isClearable={OrderManagmentFilter?.PendingForDelivery?.FromDate !== "" ? true : false}
                                name="statDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3" >
                    <Form.Group>
                        <div className="datepickField">
                            <DatePicker
                                selected={OrderManagmentFilter?.PendingForDelivery?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDelivery?.ToDate)}
                                startDate={OrderManagmentFilter?.PendingForDelivery?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDelivery?.FromDate)}
                                endDate={OrderManagmentFilter?.PendingForDelivery?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForDelivery?.ToDate)}
                                minDate={startDate}
                                maxDate={endDate}
                                onChange={(date) => DateEndGet(date)}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter To Date"
                                isClearable={OrderManagmentFilter?.PendingForDelivery?.ToDate !== "" ? true : false}
                                name="ToDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3" style={{ width: "15%" }}>
                    <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter?.PendingForDelivery?.SelectRegion} onChange={(e) => selectRegionFunction(e)}>
                        <option value="">Select Region</option>
                        {Regionlistall?.map((elm, index) => {
                            return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                        })}
                    </select>
                </div>
                {ResponsiveWidth < 991 && (<React.Fragment>
                    {selfInfo?.user?.user_type === 'super_admin' && (<div className="col-md-3">
                        <select 
                            className="form-control  pe-4" 
                            value={OrderManagmentFilter[ModuleWiseFilterState]?.OrderSource} 
                            onChange={(e) => OnChangeOrderSource(e.target.value)}
                        >
                            <option value="">All ( Select order type )</option>
                            <option value="FO">Dealer</option>
                            <option value="CO">Sale</option>
                            <option value="WO"> Internal Warehouse to Warehouse</option>
                            <option value="AO">Amazon, Website, Flipkart</option>
                        </select>
                    </div>)}
                </React.Fragment>)}
                {ResponsiveWidth < 991 ? (<div id="mobile-filters-footer-grid">
                    <div className="col-md-3">
                        <select className="form-control userLimitselect" value={GetuserLimit()} onChange={(e) => SelectLimitFct(e, Location)}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="60">60</option>
                            <option value="70">70</option>
                            <option value="80">80</option>
                            <option value="90">90</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div className="col-md-3" style={{ width: "auto" }}>
                        <button className="btn exportbtn" disabled={OrderManagmentFilter?.PendingForDelivery?.ToDate === "" || OrderManagmentFilter?.PendingForDelivery?.FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": null, "ToDate": null, "FromInv": OrderManagmentFilter?.PendingForDelivery?.FromDate, "ToInv": OrderManagmentFilter?.PendingForDelivery?.ToDate, "status": "DISPATCHED", "region_id": OrderManagmentFilter?.PendingForDelivery?.SelectRegion, "search": OrderManagmentFilter?.PendingForDelivery?.UserSearch.trim(), "order_type": OrderManagmentFilter?.PendingForDelivery?.OrderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>) : (<div className="col-md-3" style={{ width: "auto" }}>
                    <button className="btn exportbtn" disabled={OrderManagmentFilter?.PendingForDelivery?.ToDate === "" || OrderManagmentFilter?.PendingForDelivery?.FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": null, "ToDate": null, "FromInv": OrderManagmentFilter?.PendingForDelivery?.FromDate, "ToInv": OrderManagmentFilter?.PendingForDelivery?.ToDate, "status": "DISPATCHED", "region_id": OrderManagmentFilter?.PendingForDelivery?.SelectRegion, "search": OrderManagmentFilter?.PendingForDelivery?.UserSearch.trim(), "order_type": OrderManagmentFilter?.PendingForDelivery?.OrderSource })}>
                        <i className="bi bi-filetype-xlsx"></i>
                        Export
                    </button>
                </div>)}
            </div>
        </div>
    </div>)
    };

    // table list
    const TableList = () => {
        return(<Table responsive bordered hover >
            <thead>
                <tr>
                    <th></th>
                    <th>SNo</th>
                    {ResponsiveWidth > 700 ? (<React.Fragment>
                        <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id</th>
                        <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                        <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                        <th className={sortingfor === "order_source" ? "active" : sortingfor === "-order_source" ? "inactive" : ""} onClick={() => CommonSortingFct("order_source")}>Order Source</th>
                        <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                        <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                        <th className={sortingfor === "invoice_code" ? "active" : sortingfor === "-invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_code")}>Invoice ID</th>
                        <th className={sortingfor === "invoice_at" ? "active" : sortingfor === "-invoice_at" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_at")}>Invoice Date</th>
                        <th className={sortingfor === "courier_name" ? "active" : sortingfor === "-courier_name" ? "inactive" : ""} onClick={() => CommonSortingFct("courier_name")}>Transporter</th>
                        <th className={sortingfor === "order_priority" ? "active" : sortingfor === "-order_priority" ? "inactive" : ""} onClick={() => CommonSortingFct("order_priority")}>Order Priority</th>
                        <th className={sortingfor === "docket_number" ? "active" : sortingfor === "-docket_number" ? "inactive" : ""} onClick={() => CommonSortingFct("docket_number")}>Docket Number</th>
                        <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Pending Since </th>
                    </React.Fragment>) : (<React.Fragment>
                        <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id</th>
                        <th className={sortingfor === "invoice_code" ? "active" : sortingfor === "-invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_code")}>Invoice ID</th>
                    </React.Fragment>)}
                </tr>
            </thead>
            <tbody>
                {PendingDeliveryList?.data?.map((elm, index) => {
                    const get_color = getColor(elm);
                    return (
                        <React.Fragment key={index}>
                            <tr >
                                <td style={{ width: '10px' }}>
                                    <button
                                        className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                        onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={activeTableDetails === elm?.id ? true : false}
                                    >
                                        <Image src={MoreIcon} alt="more icon" />
                                    </button>
                                </td>
                                {ResponsiveWidth < 700 ? (<React.Fragment>
                                    <td>
                                        {GetCurrentTableSRNo(index, PendingDeliveryList?.pagination?.current_page, PendingDeliveryList?.pagination?.record_limit)}
                                    </td>
                                    <td>
                                        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[7]?.Edit) ? (
                                            <b onClick={() => OrderDetailsModal(elm?.id)} className="tableBolanchor">{elm?.order_code}</b>
                                        ) : (
                                            <b className="tableBolanchor">{elm?.order_code}</b>
                                        )}
                                    </td>
                                    <td>{elm?.invoice_code === null ? "-" : elm?.invoice_code}</td>
                                </React.Fragment>) : (<React.Fragment>
                                    <td>
                                        <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                            {GetCurrentTableSRNo(index, PendingDeliveryList?.pagination?.current_page, PendingDeliveryList?.pagination?.record_limit)}
                                        </Link>
                                    </td>
                                    <td>
                                        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[7]?.Edit) ? (
                                            <b onClick={() => OrderDetailsModal(elm?.id)} className="tableBolanchor">{elm?.order_code}</b>
                                        ) : (
                                            <b className="tableBolanchor">{elm?.order_code}</b>
                                        )}
                                    </td>
                                    <td>
                                        {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                    </td>
                                    <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                    <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                                    <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                    <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                    <td>{elm?.invoice_code === null ? "-" : elm?.invoice_code}</td>
                                    <td>{CommonDateTime(elm?.invoice_at || elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                    <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                    <td>{elm?.order_priority ? elm?.order_priority : "-"}</td>
                                    <td>{
                                        elm?.docket_number === null ?
                                            "-" :
                                            elm?.shipment_url === null ? elm?.docket_number : (<a href={elm?.shipment_url} target="__blank">{elm?.docket_number ? elm?.docket_number : "-"}</a>)
                                    } </td>
                                    <td>{TimeSense(elm?.updated_at)}</td>
                                </React.Fragment>)}
                            </tr>
                            <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                    <td colSpan="12" >
                                        <Table bordered hover className="table">
                                            <tbody>
                                                {ResponsiveWidth < 700 && (<React.Fragment>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Customer Code</th>
                                                        <td>
                                                            {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Order Date</th>
                                                        <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Order Source</th>
                                                        <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>SHIP To Name</th>
                                                        <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>BILL To Name</th>
                                                        <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Invoice Date</th>
                                                        <td>{CommonDateTime(elm?.invoice_at || elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Transporter</th>
                                                        <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Order Priority</th>
                                                        <td>{elm?.order_priority ? elm?.order_priority : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Docket Number</th>
                                                        <td>{
                                                            elm?.docket_number === null ?
                                                                "-" :
                                                                elm?.shipment_url === null ? elm?.docket_number : (<a href={elm?.shipment_url} target="__blank">{elm?.docket_number ? elm?.docket_number : "-"}</a>)
                                                        } </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Pending Since</th>
                                                        <td>{TimeSense(elm?.updated_at)}</td>
                                                    </tr>
                                                </React.Fragment>)}
                                                <tr>
                                                    <th style={{ width: "20%" }}>Quantity</th>
                                                    <td>{elm?.qty}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Order Value</th>
                                                    <td>{AmountNumberFormat(elm?.total)}</td>

                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>SKU Type</th>
                                                    <td>{elm?.category}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Status</th>
                                                    <td>
                                                        {elm?.order_status === "DISPATCHED" && (<Badge bg="danger">In-transit</Badge>)}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ width: "20%" }}>Invoice Date</th>
                                                    <td>{CommonDateTime(elm?.invoice_at || elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ width: "20%" }}>Criticality</th>
                                                    <td>
                                                        <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Attachments</th>
                                                    <td>
                                                        {elm?.attachments !== null && (<ul className="fileuploadListytable">
                                                            {elm?.attachments?.map((elm, index) => {
                                                                return (<li key={index}>
                                                                    <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                                </li>)
                                                            })}
                                                        </ul>)}
                                                        {(elm?.attachments !== null && PendingDeliveryList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0) && (<br/>)}
                                                        {PendingDeliveryList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0 && PendingDeliveryList?.uni_com_invoice_list?.map((elm__, index) => {
                                                            if(elm__?.order_id === elm?.id && elm?.order_type === "AO") {
                                                                return(<React.Fragment key={index}>
                                                                    {elm__?.invoiceCode !== null && elm?.uni_code !== null && (
                                                                        <a key={index} className="anchorDownoad" onClick={() => MarketPlaceInvocie(elm__?.invoiceCode, elm?.uni_code)}><i className="bi bi-file-earmark-text"></i> Market place invoice</a>
                                                                    )}
                                                                </React.Fragment>)
                                                            }
                                                        })}
                                                        {(PendingDeliveryList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0 && elm?.uni_shipment_url !== null) && (<br/>)}
                                                        {elm?.uni_shipment_url !== null && (
                                                            <a href={elm?.uni_shipment_url} download={elm?.uni_shipment_url} target="__blank"><i className="bi bi-file-earmark-text"></i> Shipping label</a>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Region</th>
                                                    <td>{GetRegionName(elm?.region_id)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </Collapse>
                        </React.Fragment>
                    )
                })}
                {PendingDeliveryList?.data?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="12">Pending for delivery list not found</td></tr>}
            </tbody>
        </Table>)
    };
    
    return (<section className="Main_section">
        {ResponsiveWidth > 991 ? (<SaprateFilterCommn />) : (<ResponsiveFilter ResponsiveFilterModal={ResponsiveFilterModal} setResponsiveFilterModal={setResponsiveFilterModal}>
            <SaprateFilterCommn />
        </ResponsiveFilter>)}
        
        <OrderDataTableFilter
            filterType={""}
            searchType={"Order Id, Name, Invoice ID, Customer Code, Docket Number"}
            userType={userType}
            setUserType={setUserType}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ResponsiveFilterModal={ResponsiveFilterModal}
            setResponsiveFilterModal={setResponsiveFilterModal}
            ResponsiveWidth={ResponsiveWidth}
        />
        <div className="tableView orderlistTable gstreporttableadded">
            <Scrollbars
                style={{ height: PendingDeliveryList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 215px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                {TableList()}
                {ResponsiveWidth < 900 && (<>
                    {/* pagination */}
                    {PendingDeliveryList?.pagination?.total_records > 10 && (<CommonPagination
                        currentPage={OrderManagmentFilter?.PendingForDelivery?.CurrentPage}
                        paginationLength={PendingDeliveryList?.pagination}
                        currentFunction={currentFunction}
                    />)}
                </>)}
            </Scrollbars>

            {ResponsiveWidth > 900 && (<>
                {/* pagination */}
                {PendingDeliveryList?.pagination?.total_records > 10 && (<CommonPagination
                    currentPage={OrderManagmentFilter?.PendingForDelivery?.CurrentPage}
                    paginationLength={PendingDeliveryList?.pagination}
                    currentFunction={currentFunction}
                />)}
            </>)}
        </div>

        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        <OrderStatusModel setOrderNewModel={setOrderNewModel} OrderNewModel={OrderNewModel} />
    </section>)
}

export default FranchisePendingDelivery;