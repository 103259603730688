/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Form, Badge, Collapse, Image } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrderDataTableFilter from "../../Common/OrderDataTableFilter";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import MoreIcon from '../../../assets/img/more_options.png';
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import CommonStatus from "../../Common/CommonStatus";
import { getTableSorting, updateOrderManagementFilter } from "../../../redux/actions/adminActions";
import { Link, useLocation } from "react-router-dom";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";
import { MARKET_PLACE_INVOICE_API_URL } from "../../Shared/constant";
import ResponsiveFilter from "../../Common/ResponsiveFilter";

const FranchiseOrdersReport = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const [userType, setUserType] = useState("all");
    const { websocket, ResponsiveWidth } = useContext(WebSocketContext);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const today = new Date();
    const { device_id, OrderReportList, AllDealerFranchiseTypeListAll, selfInfo, OrderManagmentFilter, sortingfor, Regionlistall } = useSelector((state) => state.adminReducers);
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const [orderSource, setOrderSource] = useState("");
    var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    //Order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const Location = useLocation()?.pathname?.replace("/", "");
    let ModuleWiseFilterState = Location === "all-orders-report" ? "AllOrdersReport" : Location === "SO-approvals" ? "SOApprovals" : Location === "warehouse-approvals" ? "WarehouseApprovals" : Location === "pending-for-accounts" ? "PendingForAccounts" : Location === "pending-for-stores" ? "PendingForStores" : Location === "pending-for-dispatch" ? "PendingForDispatch" : Location === "pending-for-delivery" ? "PendingForDelivery" : "OrderDelivered";
    const [ ResponsiveFilterModal, setResponsiveFilterModal ] = useState({
        open: false,
        title: "",
    })

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            Data: value
        });
    };

    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            AllOrdersReport: {
                CurrentPage: page,
            }
        }));
    };

    useEffect(() => {
        CommonSortingFct("")
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        Dispatch(updateOrderManagementFilter({
            AllOrdersReport: {
                CurrentPage: 1,
            }
        }));
    };

    const selectOrderTypeFunction = (e) => {
        Dispatch(updateOrderManagementFilter({
            AllOrdersReport: {
                SelectOrderType: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    const selectRegionFunction = (e) => {
        Dispatch(updateOrderManagementFilter({
            AllOrdersReport: {
                SelectRegion: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            Dispatch(updateOrderManagementFilter({
                AllOrdersReport: {
                    FromDate: "",
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                AllOrdersReport: {
                    FromDate: date,
                }
            }));
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        Dispatch(updateOrderManagementFilter({
            AllOrdersReport: {
                CurrentPage: 1,
            }
        }));
        if (date === null) {
            setEndDate("")
            let selectedDate = new Date(date);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            Dispatch(updateOrderManagementFilter({
                AllOrdersReport: {
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                AllOrdersReport: {
                    ToDate: date,
                }
            }));
        }
    };

    // get all franchise list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "franchise_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get order_list all
    useEffect(() => {
        const { SelectOrderType, UserLimit, UserSearch, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage } = OrderManagmentFilter?.AllOrdersReport;
        let param = {
            "transmit": "broadcast",
            "url": "order_list",
            "request": {
                "status": SelectOrderType,
                "limit": UserLimit,
                "page_no": CurrentPage,
                "search": UserSearch.trim(),
                "from_date": null,
                "to_date": null,
                "from_inv": null,
                "to_inv": null,
                "order_type": OrderSource,
                "order_data": "list",
                "region_id": SelectRegion,
                "order_by": sortingfor
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    }, [ websocket, sortingfor, OrderManagmentFilter?.AllOrdersReport ]);

    const GetFranchiseName = (billing_id) => {
        const current = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    // open order details modal
    const OrderDetailsModal = (order_code) => {
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": order_code,
            },
            "DeviceId": device_id,
        };
        wsSend_request(websocket, param);
        setOrderViewerModal({ ...OrderViewerModal, open: !OrderViewerModal?.open });
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    const callInvoiceModal = (e, code) => {
        e.preventDefault();
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": code,
            },
            "DeviceId": device_id,
            "TabId": tabID,
            modal: true
        };
        wsSend_request(websocket, param);
    }

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    const GetRegionName = (id) => {
        if(id === null) {
            return "-";
        } else {
            const currentRegionData = OrderReportList?.regionlist?.filter((elm) => elm?.id === id)[0];
            return currentRegionData?.region_name
        }
    };

    // Market Place Invocie
    const MarketPlaceInvocie = async (invoice_code, uni_code) => {
        window.open(MARKET_PLACE_INVOICE_API_URL + "?invoice_code=" + invoice_code + "&uni_code=" + uni_code, '_blank');
    };

    // order Source update
    const OnChangeOrderSource = (value) => {
        Dispatch(updateOrderManagementFilter({
            [ModuleWiseFilterState]: {
                OrderSource: value,
                CurrentPage: 1,
            }
        }));
    };

    // select limit
    const SelectLimitFct = (e) => {
        Dispatch(updateOrderManagementFilter({
            [ModuleWiseFilterState]: {
                UserLimit: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // get page limit from redux
    const GetuserLimit = () => {
        return OrderManagmentFilter?.[ModuleWiseFilterState]?.UserLimit;
    };

    // filters commomn
    const SaprateFilterCommn = () => {
        return(<div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee", marginBottom: "10px" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={OrderManagmentFilter?.AllOrdersReport?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.AllOrdersReport?.FromDate)}
                                    startDate={OrderManagmentFilter?.AllOrdersReport?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.AllOrdersReport?.FromDate)}
                                    endDate={OrderManagmentFilter?.AllOrdersReport?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.AllOrdersReport?.ToDate)}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={OrderManagmentFilter?.AllOrdersReport?.FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={OrderManagmentFilter?.AllOrdersReport?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.AllOrdersReport?.ToDate)}
                                    startDate={OrderManagmentFilter?.AllOrdersReport?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.AllOrdersReport?.FromDate)}
                                    endDate={OrderManagmentFilter?.AllOrdersReport?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.AllOrdersReport?.ToDate)}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={OrderManagmentFilter?.AllOrdersReport?.ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "20%" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter?.AllOrdersReport?.SelectOrderType} onChange={(e) => selectOrderTypeFunction(e)}>
                            <option value="OPEN,SO_HALF_APPROVAL,SO_APPROVED,WH_PENDING,WH_APPROVED,AC_APPROVED,ST_APPROVED,HALF_DISPATCH,DISPATCHED,DELIVERED,CANCELLED,REJECTED,RETURN,REPLACE,RETURN_REQUEST,CANCELLED_REQUEST">All {ResponsiveWidth < 991 && "( Select Order Status )"}</option>
                            <option value="OPEN,SO_HALF_APPROVAL">So Approval</option>
                            <option value="SO_APPROVED">Warehouse Approval</option>
                            <option value="WH_PENDING">Warehouse Pending</option>
                            <option value="WH_APPROVED">Pending For Accounts</option>
                            <option value="AC_APPROVED">Pending For Store</option>
                            <option value="ST_APPROVED,HALF_DISPATCH">Pending For Dispatch</option>
                            <option value="DISPATCHED">Pending For Delivery</option>
                            <option value="DELIVERED">Order Delivered</option>
                            <option value="REJECTED">Rejected</option>
                            <option value="CANCELLED,CANCELLED_REQUEST">Cancellation</option>
                            <option value="RETURN,REPLACE,RETURN_REQUEST">Return And Replace</option>
                        </select>
                    </div>
                    <div className="col-md-3" style={{ width: "15%" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter?.AllOrdersReport?.SelectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>
                    {ResponsiveWidth < 991 && (<React.Fragment>
                        {selfInfo?.user?.user_type === 'super_admin' && (<div className="col-md-3">
                            <select 
                                className="form-control  pe-4" 
                                value={OrderManagmentFilter[ModuleWiseFilterState]?.OrderSource} 
                                onChange={(e) => OnChangeOrderSource(e.target.value)}
                            >
                                <option value="">All ( Select order type )</option>
                                <option value="FO">Dealer</option>
                                <option value="CO">Sale</option>
                                <option value="WO"> Internal Warehouse to Warehouse</option>
                                <option value="AO">Amazon, Website, Flipkart</option>
                            </select>
                        </div>)}
                    </React.Fragment>)}
                    {ResponsiveWidth < 991 ? (<div id="mobile-filters-footer-grid">
                        <div className="col-md-3">
                            <select className="form-control userLimitselect" value={GetuserLimit()} onChange={(e) => SelectLimitFct(e)}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="60">60</option>
                                <option value="70">70</option>
                                <option value="80">80</option>
                                <option value="90">90</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div className="col-md-3" style={{ width: "auto" }}>
                            <button className="btn exportbtn" disabled={OrderManagmentFilter?.AllOrdersReport?.ToDate === "" || OrderManagmentFilter?.AllOrdersReport?.FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": OrderManagmentFilter?.AllOrdersReport?.FromDate, "ToDate": OrderManagmentFilter?.AllOrdersReport?.ToDate, "FromInv": null, "ToInv": null, "status": OrderManagmentFilter?.AllOrdersReport?.SelectOrderType, "region_id": OrderManagmentFilter?.AllOrdersReport?.SelectRegion ,"search": OrderManagmentFilter?.AllOrdersReport?.UserSearch.trim(), "order_type": orderSource })}>
                                <i className="bi bi-filetype-xlsx"></i>
                                Export
                            </button>
                        </div>
                    </div>) : ( <div className="col-md-3" style={{ width: "auto" }}>
                        <button className="btn exportbtn" disabled={OrderManagmentFilter?.AllOrdersReport?.ToDate === "" || OrderManagmentFilter?.AllOrdersReport?.FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": OrderManagmentFilter?.AllOrdersReport?.FromDate, "ToDate": OrderManagmentFilter?.AllOrdersReport?.ToDate, "FromInv": null, "ToInv": null, "status": OrderManagmentFilter?.AllOrdersReport?.SelectOrderType, "region_id": OrderManagmentFilter?.AllOrdersReport?.SelectRegion ,"search": OrderManagmentFilter?.AllOrdersReport?.UserSearch.trim(), "order_type": orderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>)}
                </div>
            </div>
        </div>)
    };

    // table list
    const TableList = () => {
        return(<Table responsive bordered hover >
            <thead>
                <tr>
                    <th></th> 
                    <th>SNo</th>
                    {ResponsiveWidth > 700 ? (<React.Fragment>
                        <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                        <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                        <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id.</th>
                        <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                        <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                        <th className={sortingfor === "invoice_code" ? "active" : sortingfor === "-invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_code")}>Invoice No.</th>
                        <th className={sortingfor === "invoice_at" ? "active" : sortingfor === "-invoice_at" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_at")}>Invoice Date</th>
                        <th className={sortingfor === "courier_name" ? "active" : sortingfor === "-courier_name" ? "inactive" : ""} onClick={() => CommonSortingFct("courier_name")}>Transporter</th>
                        <th className={sortingfor === "order_priority" ? "active" : sortingfor === "-order_priority" ? "inactive" : ""} onClick={() => CommonSortingFct("order_priority")}>Order Priority</th>
                        <th className={sortingfor === "qty" ? "active" : sortingfor === "-qty" ? "inactive" : ""} onClick={() => CommonSortingFct("qty")}>Qty</th>
                        <th className={sortingfor === "docket_number" ? "active" : sortingfor === "-docket_number" ? "inactive" : ""} onClick={() => CommonSortingFct("docket_number")}>Docket Number</th>
                        <th className={sortingfor === "total" ? "active" : sortingfor === "-total" ? "inactive" : ""} onClick={() => CommonSortingFct("total")}>Amount</th>
                        <th className={sortingfor === "order_status" ? "active" : sortingfor === "-order_status" ? "inactive" : ""} onClick={() => CommonSortingFct("order_status")}>Status</th>
                    </React.Fragment>) : (<React.Fragment>
                        <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id.</th>
                        <th className={sortingfor === "invoice_code" ? "active" : sortingfor === "-invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_code")}>Invoice No.</th>
                    </React.Fragment>)}
                </tr>
            </thead>
            <tbody>
                {OrderReportList?.list?.map((elm, index) => {
                    return (<React.Fragment key={index}>
                        <tr>
                            <td style={{ width: '10px' }}>
                                <button
                                    className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                    onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={activeTableDetails === elm?.id ? true : false}
                                >
                                    <Image src={MoreIcon} alt="more icon" />
                                </button>
                            </td>
                            {ResponsiveWidth < 700 ? (<React.Fragment>
                                <td>
                                    {GetCurrentTableSRNo(index, OrderReportList?.pagination?.current_page, OrderReportList?.pagination?.record_limit)}
                                </td>
                                <td>
                                    {/* <b className="tableBolanchor" onClick={() => OrderDetailsModal(elm?.id)}>{elm?.order_code}</b> */}
                                    <b className="tableBolanchor" onClick={() => OrderDetailsModal(elm?.id)}>{elm?.order_code}</b>
                                </td>
                                <td>
                                    {!elm?.invoice_code ? "-" : (<b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, elm?.id)}>{elm?.invoice_code}</b>)}
                                </td>
                            </React.Fragment>) : (<React.Fragment>
                                    <td>
                                        <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                            {GetCurrentTableSRNo(index, OrderReportList?.pagination?.current_page, OrderReportList?.pagination?.record_limit)}
                                        </Link>
                                    </td>
                                    <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                    <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                    <td>
                                        <b className="tableBolanchor" onClick={() => OrderDetailsModal(elm?.id)}>{elm?.order_code}</b>
                                    </td>
                                    <td>
                                        {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                    </td>
                                    <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                    <td>
                                        {!elm?.invoice_code ? "-" : (<b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, elm?.id)}>{elm?.invoice_code}</b>)}
                                    </td>
                                    <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                    <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                    <td>{elm?.order_priority ? elm?.order_priority : "-"}</td>
                                    <td>{elm?.qty}</td>
                                    <td>{elm?.docket_number ? elm?.docket_number : "-"}</td>
                                    <td>₹{AmountNumberFormat(elm?.total)}</td>
                                    <td>
                                        {CommonStatus(elm?.order_status)}
                                    </td>
                            </React.Fragment>)}
                        </tr>
                        <Collapse in={activeTableDetails === elm?.id ? true : false}>
                            <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                <td colSpan="13" >
                                    <Table bordered hover className="table">
                                        <tbody>
                                            {ResponsiveWidth < 700 ? (<React.Fragment>
                                                <tr>
                                                    <th style={{ width: "20%" }}>SHIP To Name</th>
                                                    <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>BILL To Name</th>
                                                    <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Customer Code</th>
                                                    <td>{elm?.customer_identity === null ? "-" : elm?.customer_identity}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Order Date</th>
                                                    <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Invoice Date</th>
                                                    <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Transporter</th>
                                                    <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Order Priority</th>
                                                    <td>{elm?.order_priority ? elm?.order_priority : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Qty</th>
                                                    <td>{elm?.qty}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Docket Number</th>
                                                    <td>{elm?.docket_number ? elm?.docket_number : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Amount</th>
                                                    <td>₹{AmountNumberFormat(elm?.total)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Status</th>
                                                    <td>{CommonStatus(elm?.order_status)}</td>
                                                </tr>
                                            </React.Fragment>) : (<React.Fragment>
                                                {elm?.invoice_code ? (
                                                <tr>
                                                    <th style={{ width: "20%" }}>Invoice Date</th>
                                                    <td>{CommonDateTime(elm?.invoice_at || elm?.created_at, "LL", "show")}</td>
                                                </tr>
                                            ) : <React.Fragment />}
                                                <tr>
                                                    <th style={{ width: "20%" }}>Remarks</th>
                                                    <td>{elm?.remark === null ? "-" : elm?.remark}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Attachments</th>
                                                    <td>
                                                        {elm?.attachments !== null && (<ul className="fileuploadListytable">
                                                            {elm?.attachments?.map((elm, index) => {
                                                                return (<li key={index}>
                                                                    <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                                </li>)
                                                            })}
                                                        </ul>)}
                                                        {(elm?.attachments !== null && OrderReportList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0) && (<br/>)}
                                                        {OrderReportList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0 && OrderReportList?.uni_com_invoice_list?.map((elm__, index) => {
                                                            if(elm__?.order_id === elm?.id && elm?.order_type === "AO") {
                                                                return(<React.Fragment key={index}>
                                                                    {elm__?.invoiceCode !== null && elm?.uni_code !== null && (
                                                                        <a key={index} className="anchorDownoad" onClick={() => MarketPlaceInvocie(elm__?.invoiceCode, elm?.uni_code)}><i className="bi bi-file-earmark-text"></i> Market place invoice</a>
                                                                    )}
                                                                </React.Fragment>)
                                                            }
                                                        })}
                                                        {(OrderReportList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === elm?.id && elm?.order_type === "AO"))?.length > 0 && elm?.uni_shipment_url !== null) && (<br/>)}
                                                        {elm?.uni_shipment_url !== null && (
                                                            <a href={elm?.uni_shipment_url} download={elm?.uni_shipment_url} target="__blank"><i className="bi bi-file-earmark-text"></i> Shipping label</a>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Region</th>
                                                    <td>{GetRegionName(elm?.region_id)}</td>
                                                </tr>
                                            </React.Fragment>)}
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        </Collapse>
                    </React.Fragment>)
                })}
                {OrderReportList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="13">All order report list not found</td></tr>}
            </tbody>
        </Table>)
    };

    return (<section className="Main_section">
        {ResponsiveWidth > 991 ? (<SaprateFilterCommn />) : (<ResponsiveFilter ResponsiveFilterModal={ResponsiveFilterModal} setResponsiveFilterModal={setResponsiveFilterModal}>
            <SaprateFilterCommn />
        </ResponsiveFilter>)}

        <OrderDataTableFilter
            orderSource={orderSource}
            callSelectSource={setOrderSource}
            filterType={""}
            searchType={"Order Id, Customer Code"}
            userType={userType}
            setUserType={setUserType}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
            ResponsiveFilterModal={ResponsiveFilterModal}
            setResponsiveFilterModal={setResponsiveFilterModal}
            ResponsiveWidth={ResponsiveWidth}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars
                style={{ height: OrderReportList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 209px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar ScrollbarsSidebar_ordersManagements"
            >
                {TableList()}
                {ResponsiveWidth < 900 && (<>
                    {/* pagination */}
                    {OrderReportList?.pagination?.total_records > 10 && (<CommonPagination
                        currentPage={OrderManagmentFilter?.AllOrdersReport?.CurrentPage}
                        paginationLength={OrderReportList?.pagination}
                        currentFunction={currentFunction}
                    />)}
                </>)}
            </Scrollbars>
            {ResponsiveWidth > 900 && (<>
                {/* pagination */}
                {OrderReportList?.pagination?.total_records > 10 && (<CommonPagination
                    currentPage={OrderManagmentFilter?.AllOrdersReport?.CurrentPage}
                    paginationLength={OrderReportList?.pagination}
                    currentFunction={currentFunction}
                />)}
            </>)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        <OrderStatusModel setOrderNewModel={setOrderNewModel} OrderNewModel={OrderNewModel} />
    </section>)
}

export default FranchiseOrdersReport;