/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, Collapse, Image } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import MoreIcon from '../../../assets/img/more_options.png';
import CommonDateTime from "../../Common/CommonDateTime";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { Link, useParams, useNavigate  } from "react-router-dom";
import { PO_POSTING_URL } from "../../Shared/constant";
import { GetOrderReportDetails, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelOrder from "../../Common/CancelOrder";
import OrderPause from "./OrderPause";

const ApprovalOrders = (props) => {
    const { RouteName } = props;
    const { roomId } = useParams();
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, ClearFormSet, OrderReportDetails, commonMainLoader } = useSelector((state) => state.adminReducers);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const [ OrderPriority, setOrderPriority ] = useState("");
    const [ errorOrderPriority, setErrorOrderPriority ] = useState("");
    
    useEffect(() => {
        if(roomId) {
            let param = { 
                "transmit": "broadcast", 
                "url": "order_detail",
                "request" : { 
                    "order_id" : roomId,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId ]);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
          display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // select order priority
    const selectOrderPriority = (value) => {
        if(value !== "") {
            setOrderPriority(value);
            setErrorOrderPriority("");
        } else {
            setOrderPriority("");
            setErrorOrderPriority("Please select order priority");
        }
    };

    // get total balance and Credit Limit	
    const GetCurrentOrderRate = (current) => {
        const getCurrentFranchise = current?.order?.billing;
        const getCurrentFranchiseType = current?.franchise_type_detail;

        let creditlimit = 0;
        let wallet = 0
        if(getCurrentFranchiseType?.franchise_type_credit !== null && getCurrentFranchiseType?.franchise_type_credit !== "") {
            wallet = parseInt(getCurrentFranchiseType?.franchise_type_credit);
            creditlimit = parseInt(getCurrentFranchiseType?.franchise_type_credit);
        };
        if(getCurrentFranchise?.credit_addon !== null && getCurrentFranchise?.credit_addon !== "") {
            wallet = parseInt(getCurrentFranchise?.credit_addon) + wallet;
            creditlimit = parseInt(getCurrentFranchise?.credit_addon) + creditlimit;
        }; 
        if(getCurrentFranchise?.advance_amount !== null && getCurrentFranchise?.advance_amount !== "") {
            wallet = wallet + parseInt(getCurrentFranchise?.advance_amount);
        };

        return {
            Wallet_balance: (getCurrentFranchise?.advance_amount === null || getCurrentFranchise?.advance_amount === "") ? 0 :parseInt(getCurrentFranchise?.advance_amount),
            credit_limit: creditlimit,
            available_balance: wallet,
        };
    };

    const UpdateOrderReportDetails =  OrderReportDetails?.order_items_list?.map((elm) => {
        return {
            order: elm,
            id: elm?.id,
            QntMain: ""
        };
    });
    
    const [ MainArray, setMainArray ] = useState([]);
    useEffect(() => {
        if(UpdateOrderReportDetails !== undefined) {
            setMainArray(UpdateOrderReportDetails);
        };
    }, [ OrderReportDetails ]);

    const GetOrderQuantity = (value, MainValue, data) => {
        const QauntityValid = value <= (parseInt(data?.qty) - parseInt(data?.partial_qty));
        const QauntityValidSecond = MainValue <= (parseInt(data?.qty) - parseInt(data?.partial_qty));

        if(QauntityValid) {
            const UpdatedArray = MainArray?.map((elm) => {
                if(elm?.id === data?.id) {
                    return{
                        ...elm,
                        QntMain: value
                    }
                } else 
                return elm;
            });
            setMainArray(UpdatedArray);
        } else {
            toast.error(`Balance Qty Should not be More than ${parseInt(data?.qty) - parseInt(data?.partial_qty)}`);
        };
        if(QauntityValidSecond) {
        } else {
            toast.error(`Balance Qty Should not be More than ${parseInt(data?.qty) - parseInt(data?.partial_qty)}`);
        };
    };
    
    // approval order function
    const submitObjectArray = MainArray?.map(elm => ({qty: elm?.QntMain, order_items_id: elm?.order?.id}));

    const OrderApproval = () => {
        if(OrderReportDetails?.order?.order_type === "AO") {
            if(submitObjectArray !== undefined && OrderPriority !== "") {
                if(submitObjectArray && submitObjectArray?.filter((elm) => elm?.qty !== "")?.length > 0) {
                    Dispatch(StartMainCommonLoader({
                        url: "so_approval",
                        loader: true
                    }));
    
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "so_approval",
                        "request" : { 
                            "order_id" : roomId,
                            "items" : submitObjectArray,
                            "order_priority": OrderPriority,
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    toast.error(`Please enter balance Qty.`);
                }
            } else {
                setErrorOrderPriority("Please select order priority");
            };
        } else {
            if(submitObjectArray && submitObjectArray?.filter((elm) => elm?.qty !== "")?.length > 0) {
                Dispatch(StartMainCommonLoader({
                    url: "so_approval",
                    loader: true
                }));

                let param = { 
                    "transmit": "broadcast", 
                    "url": "so_approval",
                    "request" : { 
                        "order_id" : roomId,
                        "items" : submitObjectArray,
                        "order_priority": OrderPriority,
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                toast.error(`Please enter balance Qty.`);
            }
        };
    };
    
    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Navigate(PO_POSTING_URL);
        };
    }, [ ClearFormSet ]);

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("qntyClass")) {
            document.activeElement.blur();
        }
    });

    return(<section className="Main_section">
        <div className="MainHeader">
            <Link to={PO_POSTING_URL} onClick={() => Dispatch(GetOrderReportDetails({}))} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<>
            <div className="filtr_Form"  style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
                <div className="formloginset formloginsetStaticData">
                    <div className="row">
                        {OrderReportDetails?.order?.order_type !== "CO" && (<div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Franchise Name</Form.Label>
                                <div className="defaultvalueField">
                                {OrderReportDetails?.order?.customer_name}
                                </div>
                            </Form.Group>
                        </div>)}
                        <div className="col-md-3">
                            <Form.Group className="mb-3 ">
                                <Form.Label>Request No</Form.Label>
                                <div className="defaultvalueField">
                                    {OrderReportDetails?.order?.order_code}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Request Date</Form.Label>
                                <div className="defaultvalueField">
                                    {CommonDateTime(OrderReportDetails?.order?.created_at, "LL", "show")}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3 ">
                                <Form.Label>Total Qty</Form.Label>
                                <div className="defaultvalueField">
                                {OrderReportDetails?.order?.qty}
                                </div>
                            </Form.Group>
                        </div>
                        {(OrderReportDetails?.order?.order_type === "AO" && (OrderReportDetails?.order?.order_source === "AMAZON_IN_API" || OrderReportDetails?.order?.order_source === "AMAZON")) && (<div className="col-md-3">
                            <Form.Group className="mb-3 ">
                                <Form.Label>Select Order Priority <span className="mendatory_star">*</span></Form.Label>
                                <select 
                                    className="form-control" 
                                    value={OrderPriority} 
                                    onChange={(e) => selectOrderPriority(e?.target?.value)}
                                >
                                    <option value="">Select Order Priority</option>
                                    <option value="Amazon Prime">Prime </option>
                                    <option value="Amazon">Non Prime</option>
                                </select>
                                {errorOrderPriority && (<span className="error_medotory">{errorOrderPriority}</span>)}
                            </Form.Group>
                        </div>)}
                    </div>
                    {OrderReportDetails?.order?.order_type === "FO" && (<div className="row">
                        <div className="col-md-4" >
                            <Form.Group>
                                <Form.Label>Wallet Balance:</Form.Label>
                                <div className="defaultvalueField">
                                {AmountNumberFormat(GetCurrentOrderRate(OrderReportDetails)?.Wallet_balance)}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4" >
                            <Form.Group>
                                <Form.Label>Credit Limit:</Form.Label>
                                <div className="defaultvalueField">
                                {AmountNumberFormat(GetCurrentOrderRate(OrderReportDetails)?.credit_limit)}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4" >
                            <Form.Group>
                                <Form.Label>Available Balance:</Form.Label>
                                <div className="defaultvalueField">
                                {AmountNumberFormat(GetCurrentOrderRate(OrderReportDetails)?.available_balance)}
                                </div>
                            </Form.Group>
                        </div>
                    </div>)}
                </div>
            </div>
            <div className="tableView">
                <Scrollbars 
                    style={{ height: (OrderReportDetails?.order?.order_type === "AO" || OrderReportDetails?.order?.order_type === "FO") ? "calc(100vh - 342px)" : "calc(100vh - 249px)"}} 
                    renderView={props => <div {...props} className="view"/>}
                    renderTrackHorizontal={renderTrack}
                    className="ScrollbarsSidebar"
                >
                    <Table responsive bordered hover >
                        <thead>
                            <tr>
                                <th></th>
                                <th>SNo</th>
                                <th>Product Code</th>
                                <th>Product Name</th>
                                <th>Amount</th>
                                <th>Total Qty</th>
                                <th>Approved Qty</th>
                                <th>Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {OrderReportDetails?.order_items_list?.map((elm, index) => {
                                return(<React.Fragment key={index}>
                                    <tr>
                                        <td style={{ width: '10px' }}>
                                            <button 
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{elm?.product_code}</td>
                                        <td>{elm?.product_name}</td>
                                        <td>{elm?.sub_total}</td>
                                        <td>{elm?.qty}</td>
                                        <td>{elm?.partial_qty}</td>
                                        <td>
                                            {MainArray?.map((elmz) => {
                                                if(elmz?.id === elm?.id) {
                                                    return( <input 
                                                        type="number" 
                                                        className="form-control qntyClass"
                                                        value={elmz?.QntMain}
                                                        onChange={(e) => GetOrderQuantity(e?.target?.value <= (parseInt(elm?.qty) - parseInt(elm?.partial_qty)) ? e?.target?.value.replace(/^0/, "") : 0, e?.target?.value.replace(/^0/, ""), elm)}
                                                        onKeyDown={(e) => {
                                                            EmptySpaceFieldValid(e)
                                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                        }}    
                                                        maxLength={elmz?.QntMain <= (parseInt(elm?.qty) - parseInt(elm?.partial_qty))}
                                                    />)
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                            <td colSpan="8" >
                                                <Table bordered hover className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Unit Amount</th>
                                                            <td>{parseInt(elm?.price).toFixed(2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Tax Amount</th>
                                                            <td>{elm?.sub_gst}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>)
                            })}
                        </tbody>
                    </Table>
                    <div className="col-md-12 pt-3" style={{ textAlign: "right" }}>
                        <Button className="formbtn mt-0 me-2" disabled={(commonMainLoader?.url === "so_approval" && commonMainLoader?.loader)} onClick={() => OrderApproval()} style={{ width: "auto" }} variant="primary" >
                            Submit
                        </Button>
                        <CancelOrder data={OrderReportDetails}/>
                        <OrderPause OrderReportDetails={OrderReportDetails}/>
                    </div>
                </Scrollbars>
            </div>
        </>) : (<>
            Please Wait...
        </>)}
    </section>)
}

export default ApprovalOrders;