/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Modal, Form, Table } from 'react-bootstrap';
import { apiMapping } from "../../APIpermission";

const CommonPermissionModel = (props) => {
    const { showModalNew, setShowModalNew, modules, setModules, ApplicationState } = props;

    const [ CurrentPermissions, setCurrentPermissions ] = useState({});

    const handleClose = () => {
        setShowModalNew({ ...setShowModalNew, open: false });
    };

    useEffect(() => {
        setCurrentPermissions(modules);
    }, [ modules ])

    // dashbaord checkbox
    const DashBoardMainCheckboxChange = (e, url) => {
        const Check = e?.target?.checked;
        setCurrentPermissions({
            ...CurrentPermissions, 
            Dashboard: {
                ...CurrentPermissions.Dashboard, 
                [url]: Check
            },
        });
    };
    
    // checkbox with permissions
    const MainCheckboxChange = (e, label, type) => {
        setCurrentPermissions({
            ...CurrentPermissions, 
            ProductMasters: CurrentPermissions?.ProductMasters?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            Store: CurrentPermissions?.Store?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            Warehouse: CurrentPermissions?.Warehouse?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            Masters: CurrentPermissions?.Masters?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            AdminTools: CurrentPermissions?.AdminTools?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            Masters: CurrentPermissions?.Masters?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            CRMTools: CurrentPermissions?.CRMTools?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            OrderManagement: CurrentPermissions?.OrderManagement?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
            PaymentManager: CurrentPermissions?.PaymentManager?.map((elm) => {
                if(elm?.label === label) {
                    if(type === "Edit") {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                View: e?.target?.checked === true && true,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                Edit: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["Edit"] : apiMapping[label]?.["View"]
                            }
                        };
                    } else {
                        if(e?.target?.checked) {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: e?.target?.checked ? apiMapping[label]?.["View"] : apiMapping[label]?.["View"]
                            }
                        } else {
                            return{
                                ...elm,
                                View: e?.target?.checked,
                                API: ""
                            }
                        };
                    };
                } else 
                return elm;
            }),
        });
    };

    const AddPermissions = () => {
        setModules(CurrentPermissions);
        handleClose();
    };

    // Admin Tools Disable
    const AdminToolsDisable = (label) => {
        if(ApplicationState?.UserType === "warehouse_manager") {
            if(label === "Rm Quick Login") {
                return true;
            } else if(label === "Users") {
                return true;
            } else if(label === "Account Ledger Report") {
                return true;
            } else if(label === "Debit Credit Notes") {
                return true;
            } else if(label === "Debit Credit Notes Report") {
                return true;
            } else if(label === "Payment Approval") {
                return true;
            } else if(label === "Gst Data Report") {
                return true;
            } else if(label === "Warehouse Quick Login") {
                return true;
            } else {
                return false;
            };
        } else if(ApplicationState?.UserType === "regional_sales_team") {
            if(label === "Warehouse Quick Login") {
                return true;
            } else if(label === "Rm Quick Login") {
                return true;
            } else {
                return false;
            };
        } else {
            return false;
        };
    };

    // Get Admin id update
    const GetAdminToolsUpdateId = (label) => {
        if(ApplicationState?.UserType === "warehouse_manager") {
            if(label === "Rm Quick Login") {
                return "productMastersRMSMWH";
            } else if(label === "Users") {
                return "productMastersRMSMWH";
            } else if(label === "Account Ledger Report") {
                return "productMastersRMSMWH";
            } else if(label === "Debit Credit Notes") {
                return "productMastersRMSMWH";
            } else if(label === "Debit Credit Notes Report") {
                return "productMastersRMSMWH";
            } else if(label === "Payment Approval") {
                return "productMastersRMSMWH";
            } else if(label === "Gst Data Report") {
                return "productMastersRMSMWH";
            } else if(label === "Warehouse Quick Login") {
                return "productMastersRMSMWH";
            } else {
                return "productMasters";
            };
        } else if(ApplicationState?.UserType === "regional_sales_team") {
            if(label === "Warehouse Quick Login") {
                return "productMastersRMSMWH";
            } else if(label === "Rm Quick Login") {
                return "productMastersRMSMWH";
            } else {
                return "productMasters";
            };
        } else {
            return "productMasters";
        };
    };

    return (<Modal
        show={showModalNew?.open}
        onHide={handleClose}
        className="commonModal permissionmodel"
    >
        <Modal.Header closeButton>
            <Modal.Title style={{ width: showModalNew?.title === "Are you sure?" ? "100%" : "" }}>
                {showModalNew?.title} <div className="usertyperPermission">{ApplicationState?.UserType?.replaceAll("_", " ")}</div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "10px" }}>
            <div className="row">
                <div className="col-md-3">
                    <div className="row">
                        <h5 >Dashboard</h5>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>Dashboard</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Sales Revenue Report</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.SalesRevenueReport}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "SalesRevenueReport")}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Dealer Performance</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.DealerPerformance}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "DealerPerformance")}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Order Fulfillment</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                id="productMasters"
                                                checked={CurrentPermissions?.Dashboard?.OrderFulfillment}
                                                onChange={(e) => DashBoardMainCheckboxChange(e, "OrderFulfillment")}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="row">
                        <h5 >Inventory</h5>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>Product Masters</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.ProductMasters?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>Stores</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.Store?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className={(ApplicationState?.UserType === "warehouse_manager" || ApplicationState?.UserType === "regional_sales_team") ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>Warehouse</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.Warehouse?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm?.View}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <h5 >Admin Tools</h5>
                    </div>
                    <div className="mt-3">
                        <div className={(ApplicationState?.UserType === "warehouse_manager" || ApplicationState?.UserType === "regional_sales_team") ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>Admin Tools</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.AdminTools?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>Masters</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.Masters?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <h5 >Order Management</h5>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>Order Management</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.OrderManagement?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className={(ApplicationState?.UserType === "warehouse_manager" || ApplicationState?.UserType === "regional_sales_team") ? "tableView permissiontableView permissiontablAdminTolls" : "tableView permissiontableView"}>
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>Payment Manager</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.PaymentManager?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm.Edit}
                                                    disabled={AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={GetAdminToolsUpdateId(elm?.label)}
                                                    checked={elm?.View}
                                                    disabled={elm.Edit || AdminToolsDisable(elm?.label)}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <h5 >CRM Tools</h5>
                    </div>
                    <div className="mt-3">
                        <div className="tableView permissiontableView">
                            <Table responsive bordered hover >
                                <thead>
                                    <tr>
                                        <th>CRM Tools</th>
                                        <th>Edit</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentPermissions?.CRMTools?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{elm?.label}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "Edit"); }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="productMasters"
                                                    checked={elm?.View}
                                                    disabled={elm.Edit}
                                                    onChange={(e) => { MainCheckboxChange(e, elm?.label, "View"); }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <button className="btn permissionbtn" onClick={() => AddPermissions()}><i className="bi bi-person-check"></i> Add Permission</button>
        </Modal.Body>
    </Modal>)
}

export default CommonPermissionModel;