/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Button, Table, Image, Form } from 'react-bootstrap';
import Logo from "../../../assets/img/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios, GetOrderReportDetails, StartCommonLoader } from "../../../redux/actions/adminActions";
import CommonDateTime from "../../Common/CommonDateTime";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useNavigate, useParams } from "react-router-dom";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import { FRANCHISE_DISPATCH_PENDING_URL, FRANCHISE_DISPATCH_URL } from "../../Shared/constant";
import SelectReact from "../../Common/SelectReact";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import PurchaseOrderReport from "../../Common/PurchaseOrderReport";
import { toast } from "react-toastify";
import CancelOrder from "../../Common/CancelOrder";
import CommonewModel from "../../Common/CommonewModel";
import CommonLoader from "../../Common/CommonLoader";

const FranchiseDispatchShippingEdit = (props) => {
    const { OrderViewerModal, setOrderViewerModal } = props;
    const { OrderReportDetails, device_id, OptionCourierList, ClearFormSet, AllboxList, commonLoader } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const roomId = useParams();
    const [formData, setFormData] = useState({
        dispatch_date:"",
        distance:0,
        est_delivered_date:"",
        docket_number:"",
        courier_id:"",
        is_dispatch:"",
        order_id:roomId?.id,
    })
    const Navigate = useNavigate();
    const [disable, setDisable] = useState(true)
    const { websocket, ResponsiveWidth } = useContext(WebSocketContext);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        subtitle: "",
        modalType: "",
        button: ""
    });

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "courier_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        if(roomId?.id) {
            let param = { 
                "transmit": "broadcast", 
                "url": "order_detail",
                "request" : { 
                    "order_id" : roomId?.id,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId?.id, websocket ]);

    useEffect(()=>{
        if(websocket) {
            let param = { 
                "transmit": "broadcast", 
                "url": "courier_list",
                "request" : { 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : "",
                    "status" : true,
                    "order_by" :"-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    },[websocket])

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            if(ClearFormSet?.url !== "box_add") {
                Dispatch(ClearFormSetFutios({
                    url:"",
                    action:false
                }));
                Navigate(FRANCHISE_DISPATCH_PENDING_URL);
            };
        };
    }, [ ClearFormSet ]);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
          display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    const callSubmitForm = (e, state) =>{
        e.preventDefault();
        if(OrderReportDetails?.order?.is_pin_same === true && formData?.distance<=0){
            return toast.info("Distance is required greater than 0");
        }
        
        if(formData?.courier_id ){
            Dispatch(StartCommonLoader(true));
            let param = { 
                "transmit": "broadcast", 
                "url": "order_dispatch",
                "request" : {
                    "order_id" : roomId?.id,
                    is_dispatch: state?true:false,
                    courier_id:formData?.courier_id,
                    distance:  formData?.distance,
                    est_delivered_date: moment(formData?.est_delivered_date).format("YYYY-MM-DD")
                },
                "DeviceId" : device_id
            };
            if(state){
                param.request.distance = formData?.distance
            }
            if(state && formData?.docket_number){
                param.request.docket_number = formData?.docket_number
            }
            if(state && formData?.est_delivered_date){
                param.request.est_delivered_date = moment(formData?.est_delivered_date).format("YYYY-MM-DD");
            }
            if(state && formData?.dispatch_date){
                param.request.dispatch_date = moment(formData?.dispatch_date).format("YYYY-MM-DD");
            }
            wsSend_request(websocket, param);
        }
    };

    const onChange = (e) => {
        if (e.target.name === "distance" && e.target.value !== "") {
            e.target.value = e.target.value.replace(/^0/g, "");
        }
       // Allow only numbers
        if (e.target.name === "docket_number") {
            if(parseInt(e.target.value)<=0){
                e.target.value = "";
            }
            e.target.value = e.target.value; // Remove non-numeric characters
        }
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }));
    }
    
    const onSelect = (e, name) =>{
        setFormData((data)=>({
            ...data,
            [name]:e
        }))
    }
    const today = new Date();
    function subDate(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });
   
    console.log("ResponsiveWidth=========>", ResponsiveWidth);
    return(
        <React.Fragment>
            <section className="Main_section pending_for_store_section">
                <div className="MainHeader">
                    <Link className="btn btn-link backbtn" to={FRANCHISE_DISPATCH_PENDING_URL} onClick={() => Dispatch(GetOrderReportDetails({}))}>
                        <i className="bi bi-arrow-left-short"></i> Back
                    </Link>
                </div>
                <Scrollbars
                    style={{ height: "calc(100vh - 149px )"}} 
                    renderView={props => <div {...props} className="view"/>}
                    renderTrackHorizontal={renderTrack}
                    className="ScrollbarsSidebar"
                >
                    <div className=" pb-5 OrderDetailsModal" >
                        <div >
                            <h5>{OrderViewerModal?.Title}</h5>
                        </div>
                        <div className="orderDetailsViewr " id="contentToPrint">
                            {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                <PurchaseOrderReport OrderReportDetails={OrderReportDetails}/>
                                <div className="table-responsive">
                                    <Table className="printTable t-body-nowrap table-common mt mb-5" bordered hover>
                                        <thead>
                                            <tr>
                                                <th >SNO.</th>
                                                <th >Customer</th>
                                                <th >Phone</th>
                                                <th >City</th>
                                                <th >State</th>
                                                <th >PinCode</th>
                                                <th >Product</th>
                                            </tr>
                                            
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td >SNO.</td>
                                                <td >{OrderReportDetails?.order?.billing?.customer_name}</td>
                                                <td >{OrderReportDetails?.order?.billing?.mobile}</td>
                                                <td >{OrderReportDetails?.order?.billing_pin_data?.district?.district_name}</td>
                                                <td >{OrderReportDetails?.order?.billing_pin_data?.state?.state_name}</td>
                                                <td >{OrderReportDetails?.order?.billing_pin_data?.pin_code?.pin_code}</td>
                                                <td >
                                                {OrderReportDetails?.order_items_list?.map((elm, index) => (
                                                    <span className="pe-1" key={index}>{elm?.product_name}</span>
                                                ))}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={10}>
                                                    <Table  className="printTable t-body-nowrap table-common m-0" bordered hover>
                                                        {ResponsiveWidth > 700 && (<tbody>
                                                            <tr>
                                                                <th style={{width:"20%"}}>Courier</th>
                                                                <td className="carrier min">
                                                                    <SelectReact
                                                                        options={OptionCourierList?.data}
                                                                        name={"courier_id"}
                                                                        value={formData?.courier_id}
                                                                        onChange={onChange}
                                                                        classNameFormGroup={"col-sm-6 col-lg-4"}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{width:"20%"}} >Docket Number</th>
                                                                <td>
                                                                    <div className="col-sm-6 col-lg-4">
                                                                        <Form.Control 
                                                                            name={"docket_number"}
                                                                            onChange={onChange}
                                                                            value={formData?.docket_number}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{width:"20%"}} >Dispatch Date</th>
                                                                <td >
                                                                    <div className="datepickField min col-sm-6 col-lg-4">
                                                                        <ReactDatePicker
                                                                            selected={formData?.dispatch_date} 
                                                                            startDate={formData?.dispatch_date}
                                                                            onChange={(e)=>onSelect(e, "dispatch_date")} 
                                                                            // minDate={subDate(new Date(), 34)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth 
                                                                            showMonthDropdown
                                                                            name="dispatch_date"
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            placeholderText="Enter From Date"
                                                                            isClearable={formData?.dispatch_date !== "" ? true : false}
                                                                            // maxDate={subDate(new Date(OrderReportDetails?.order?.invoice_at), -1)}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{width:"20%"}}>Estimated Delivered Date</th>
                                                                <td>
                                                                    <div className="datepickField min col-sm-6 col-lg-4">
                                                                        <ReactDatePicker
                                                                            selected={formData?.est_delivered_date} 
                                                                            startDate={formData?.est_delivered_date}
                                                                            // minDate={new Date()}
                                                                            onChange={(e)=>onSelect(e, "est_delivered_date")} 
                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth 
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            name="est_delivered_date"
                                                                            dropdownMode="select"
                                                                            placeholderText="Enter From Date"
                                                                            isClearable={formData?.est_delivered_date !== "" ? true : false}
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{width:"20%"}} >Distance</th>
                                                                <td>
                                                                    <div className="col-sm-6 col-lg-4">
                                                                        <Form.Control 
                                                                            name={"distance"}
                                                                            onChange={onChange}
                                                                            className="inputBoxs"
                                                                            value={formData?.distance}
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>)}
                                                    </Table>
                                                </td>
                                            </tr>
                                            {/* {AccessoriesList?.map((elm, index) => (
                                                <React.Fragment key={index}>
                                                        <FrameSelectionItem callBack={callBack} item={elm} index={index} />
                                                </React.Fragment>
                                            ))} */}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="formloginset">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Courier</Form.Label>
                                        <SelectReact
                                            options={OptionCourierList?.data}
                                            name={"courier_id"}
                                            value={formData?.courier_id}
                                            onChange={onChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Docket Number</Form.Label>
                                        <Form.Control 
                                            name={"docket_number"}
                                            onChange={onChange}
                                            value={formData?.docket_number}
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Dispatch Date</Form.Label>
                                        <div className="datepickField min ">
                                            <ReactDatePicker
                                                selected={formData?.dispatch_date} 
                                                startDate={formData?.dispatch_date}
                                                onChange={(e)=>onSelect(e, "dispatch_date")} 
                                                // minDate={subDate(new Date(), 34)}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth 
                                                showMonthDropdown
                                                name="dispatch_date"
                                                showYearDropdown
                                                dropdownMode="select"
                                                placeholderText="Enter From Date"
                                                isClearable={formData?.dispatch_date !== "" ? true : false}
                                                // maxDate={subDate(new Date(OrderReportDetails?.order?.invoice_at), -1)}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Estimated Delivered Date</Form.Label>
                                        <div className="datepickField min ">
                                            <ReactDatePicker
                                                selected={formData?.est_delivered_date} 
                                                startDate={formData?.est_delivered_date}
                                                // minDate={new Date()}
                                                onChange={(e)=>onSelect(e, "est_delivered_date")} 
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth 
                                                showMonthDropdown
                                                showYearDropdown
                                                name="est_delivered_date"
                                                dropdownMode="select"
                                                placeholderText="Enter From Date"
                                                isClearable={formData?.est_delivered_date !== "" ? true : false}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Distance</Form.Label>
                                        <Form.Control 
                                            name={"distance"}
                                            onChange={onChange}
                                            className="inputBoxs"
                                            value={formData?.distance}
                                            type="number"
                                        />
                                    </Form.Group>
                                </div>
                            <div className="text-center mb-4 responsive_btn_grousp">
                                <Button disabled={formData?.courier_id === "" || (formData?.est_delivered_date === "" || formData?.est_delivered_date === null) || OrderReportDetails?.order?.order_status === "HALF_DISPATCH"? true:false} className="btn exportbtn" variant={"exportbtn"} onClick={callSubmitForm}>
                                    Eship Dispatch
                                </Button>
                                <Button disabled={
                                    (formData?.est_delivered_date &&
                                    formData?.dispatch_date &&
                                    formData?.distance!="" &&
                                    formData?.courier_id && formData?.docket_number)? false:true
                                } className="btn exportbtn" variant={"exportbtn"} onClick={(e)=>callSubmitForm(e, true)}>
                                    Full Dispatch
                                </Button>
                                <CancelOrder className={"btn-sm"}  text={"Cancel Order"} order_status={"CANCELLED"} data={OrderReportDetails} />
                            </div>
                            </div>) : (<>
                                Please Wait...
                            </>)}
                        </div>
                    </div>
                </Scrollbars>
            </section>

            {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}

            {commonLoader && (<CommonLoader />)}
        </React.Fragment>
    )
}

export default FranchiseDispatchShippingEdit;