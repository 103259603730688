// /* eslint-disable */
// import React, { useState } from "react";
// import { Table, Button, Form, Badge } from 'react-bootstrap';
// import { Scrollbars } from 'react-custom-scrollbars-2';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import DataTableFilter from "../../Common/DataTableFilter";
// import OrderDetailsViewer from "../../Common/OrderDetailsViewer";

// const EInvoiceCancel = (props) => {
//     const { RouteName } = props;
//     const [ userSearch, setUserSearch ] = useState("");
//     const [ userType, setUserType ] = useState("all");
//     const [ userLimit, setUserLimit ] = useState(10);
//     const [ currentPage, setCurrentPage ] = useState(1);
//     const [ OrderViewerModal, setOrderViewerModal ] = useState({
//         open: false,
//         Title: "Order Details",
//         Data: {}
//     });

//     // active supplier
//     // const AllowCategoryGet = (data_, type, booleancheck) => {
//     //     setShowModal({
//     //         ...showModal,
//     //         open: !showModal?.open,
//     //         title: "Are you sure?",
//     //         subtitle: "Active Category",
//     //         description: `Do you really want to ${booleancheck ? "remove " : ""} ${type.split("_")[1]} to <h5>${data_?.name}</h5>`,
//     //         modalType: "not form",
//     //         button: "Yes",
//     //         Data: data_
//     //     });
//     // }; 

//     // page change
//     const currentFunction = (page) => {
//         setCurrentPage(page);
//     };
    

//     // select user type
//     const SelectUserTypeFnct = (e) => {
//         setUserType(e.target.value);
//         setCurrentPage(1);
//     };

//     const renderTrack = ({ style, ...props }) => {
//         const trackStyle = {
//           display: "none"
//         };
//         return <div style={{ ...style, ...trackStyle }} {...props} />;
//     };

//     return(<section className="Main_section">
//         <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
//             <div className="formloginset">
//                 <div className="row">
//                     <div className="col-md-3">
//                         <Form.Group className="mb-3" >
//                             <Form.Label>From</Form.Label>
//                             <div className="datepickField">
//                                 <DatePicker 
//                                     // selected={DateSelect} 
//                                     // onChange={(date) => setDateSelect(date)} 
//                                     dateFormat="dd/MM/yyyy"
//                                     peekNextMonth 
//                                     showMonthDropdown
//                                     showYearDropdown
//                                     dropdownMode="select"
//                                     placeholderText="Enter Date"
//                                     // maxDate={newDate}
//                                 />
//                             </div>
//                         </Form.Group>
//                     </div>
//                     <div className="col-md-3" >
//                         <Form.Group className="mb-3" >
//                             <Form.Label>To</Form.Label>
//                             <div className="datepickField">
//                                 <DatePicker 
//                                     // selected={DateSelect} 
//                                     // onChange={(date) => setDateSelect(date)} 
//                                     dateFormat="dd/MM/yyyy"
//                                     peekNextMonth 
//                                     showMonthDropdown
//                                     showYearDropdown
//                                     dropdownMode="select"
//                                     placeholderText="Enter Date"
//                                     // maxDate={newDate}
//                                 />
//                             </div>
//                         </Form.Group>
//                     </div>
//                     <div className="col-md-3">
//                         <Form.Group  >
//                             <Form.Label>&nbsp;</Form.Label>
//                             <Button className="Addbtn" id="GetDetailsbntn" style={{ marginTop: "0px" }}>Get Details</Button>
//                         </Form.Group>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <DataTableFilter 
//             filterType={""}
//             userSearch={userSearch}
//             setUserSearch={setUserSearch}
//             userType={userType} 
//             setUserType={setUserType}
//             userLimit={userLimit} 
//             setUserLimit={setUserLimit}
//             currentPage={currentPage}
//             setCurrentPage={setCurrentPage}
//             SelectUserTypeFnct={SelectUserTypeFnct}
//             buttonvisible={false}
//             searchdisable={true}
//             ActiveInactiveFilter={true}
//         />
        
//         <div className="tableView">
//             <Scrollbars 
//                 style={{ height: "calc(100vh - 260px)" }} 
//                 renderView={props => <div {...props} className="view"/>}
//                 renderTrackHorizontal={renderTrack}
//                 className="ScrollbarsSidebar"
//             >
//                 <Table responsive bordered hover >
//                     <thead>
//                         <tr>
//                             <th>SNo</th>
//                             <th>Order No.</th>
//                             <th>Order Date</th>
//                             <th>Invoice No.</th>
//                             <th>Invoice Date</th>
//                             <th>Status</th>
//                             <th>PV</th>
//                             <th>Code</th>
//                             <th>Name</th>
//                             <th>Amount</th>
//                             <th>Remarks</th>
//                             <th>Action</th>
//                             <th>Show Error</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>1</td>
//                             <td>
//                                 <Button className="btn btn-link" onClick={() => setOrderViewerModal({...OrderViewerModal, open: !OrderViewerModal?.open})}>116/2324/3/1</Button>
//                             </td>
//                             <td>18 Jan 2024</td>
//                             <td>
//                                 <Button className="btn btn-link">27-EM-2324-00083</Button>
//                             </td>
//                             <td>18 Jan 2024</td>
//                             <td>
//                                 <Badge bg="success">Approved</Badge>
//                             </td>
//                             <td>0.00</td>
//                             <td>116</td>
//                             <td>The Cycle World Bhopal</td>
//                             <td>60,000.00</td>
//                             <td>
//                                 <textarea className="form-control table-description"></textarea>
//                             </td>
//                             <td>
//                                 <Button className="cancelBtn">Cancel</Button>
//                             </td>
//                             <td></td>
//                         </tr>
//                         <tr>
//                             <td>2</td>
//                             <td>
//                                 <Button className="btn btn-link" onClick={() => setOrderViewerModal({...OrderViewerModal, open: !OrderViewerModal?.open})}>116/2324/3/1</Button>
//                             </td>
//                             <td>18 Jan 2024</td>
//                             <td>
//                                 <Button className="btn btn-link">27-EM-2324-00083</Button>
//                             </td>
//                             <td>18 Jan 2024</td>
//                             <td>
//                                 <Badge bg="success">Approved</Badge>
//                             </td>
//                             <td>0.00</td>
//                             <td>116</td>
//                             <td>The Cycle World Bhopal</td>
//                             <td>60,000.00</td>
//                             <td>
//                                 <textarea className="form-control table-description"></textarea>
//                             </td>
//                             <td>
//                                 <Button className="cancelBtn">Cancel</Button>
//                             </td>
//                             <td></td>
//                         </tr>
//                     </tbody>
//                 </Table>
//             </Scrollbars>
//         </div>
//         <OrderDetailsViewer 
//             OrderViewerModal={OrderViewerModal} 
//             setOrderViewerModal={setOrderViewerModal} 
//         />
//     </section>)
// }

// export default EInvoiceCancel;

// /* eslint-disable */
// import React, { useState } from "react";
// import { Table, Button, Form, Modal } from 'react-bootstrap';
// import { Scrollbars } from 'react-custom-scrollbars-2';
// import "react-datepicker/dist/react-datepicker.css";
// import DataTableFilter from "../../Common/DataTableFilter";

// const EInvoiceDetails = (props) => {
//     const { RouteName } = props;
//     const [ userSearch, setUserSearch ] = useState("");
//     const [ userType, setUserType ] = useState("all");
//     const [ userLimit, setUserLimit ] = useState(10);
//     const [ currentPage, setCurrentPage ] = useState(1);
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     // page change
//     const currentFunction = (page) => {
//         setCurrentPage(page);
//     };
    

//     // select user type
//     const SelectUserTypeFnct = (e) => {
//         setUserType(e.target.value);
//         setCurrentPage(1);
//     };

//     const renderTrack = ({ style, ...props }) => {
//         const trackStyle = {
//           display: "none"
//         };
//         return <div style={{ ...style, ...trackStyle }} {...props} />;
//     };

//     return(<section className="Main_section">
//         <div className="filtr_Form card-header-New" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
//             <div className="card-header-right" style={{ float: "left", width: "100%" }}>
//                 <div className="row">
//                     <div className="col-md-3">
//                         <Form.Group className="mb-3" >
//                             <select className="form-control activestatusselet" id="activestatusselet">
//                                 <option>Select Generation Type</option>
//                                 <option>Pending</option>
//                                 <option>Generate</option>
//                             </select>
//                         </Form.Group>
//                     </div>
//                     <div className="col-md-3">
//                         <Form.Group  >
//                             <Button className="btn newsumbitbnt" style={{ marginTop: "0px" }}>Submit</Button>
//                         </Form.Group>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <DataTableFilter 
//             filterType={""}
//             userSearch={userSearch}
//             setUserSearch={setUserSearch}
//             userType={userType} 
//             setUserType={setUserType}
//             userLimit={userLimit} 
//             setUserLimit={setUserLimit}
//             currentPage={currentPage}
//             setCurrentPage={setCurrentPage}
//             SelectUserTypeFnct={SelectUserTypeFnct}
//             buttonvisible={false}
//             searchdisable={true}
//             ActiveInactiveFilter={true}
//         />
        
//         <div className="tableView">
//             <Scrollbars 
//                 style={{ height: "calc(100vh - 260px)" }} 
//                 renderView={props => <div {...props} className="view"/>}
//                 renderTrackHorizontal={renderTrack}
//                 className="ScrollbarsSidebar"
//             >
//                 <Table responsive bordered hover >
//                     <thead>
//                         <tr>
//                             <th>SNo</th>
//                             <th>Order No.</th>
//                             <th>Order Date</th>
//                             <th>Invoice No.</th>
//                             <th>Invoice Date</th>
//                             <th>Ack. No.</th>
//                             <th>Ack. Date</th>
//                             <th>Error</th>
//                             <th>Show Error</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>1</td>
//                             <td>001/2223/1/1</td>
//                             <td>25/03/2023</td>
//                             <td>27-EM-2223-00016</td>
//                             <td>25/03/2023</td>
//                             <td>-</td>
//                             <td>-</td>
//                             <td>
//                                 <Button className="btn edittable btn btn-primary" onClick={handleShow}>
//                                     <i className="bi bi-eye"></i>
//                                 </Button>
//                             </td>
//                             <td>
//                                 <Button className="btn edittable btn btn-primary">
//                                     <i className="bi bi-eye"></i>
//                                 </Button>
//                             </td>
//                         </tr>
//                         <tr>
//                             <td>2</td>
//                             <td>001/2223/1/1</td>
//                             <td>25/03/2023</td>
//                             <td>27-EM-2223-00016</td>
//                             <td>25/03/2023</td>
//                             <td>-</td>
//                             <td>-</td>
//                             <td>
//                                 <Button className="btn edittable btn btn-primary" onClick={handleShow}>
//                                     <i className="bi bi-eye"></i>
//                                 </Button>
//                             </td>
//                             <td>
//                                 <Button className="btn edittable btn btn-primary">
//                                     <i className="bi bi-eye"></i>
//                                 </Button>
//                             </td>
//                         </tr>
//                     </tbody>
//                 </Table>
//             </Scrollbars>
//         </div>

//         {/* Error modal */}
//         <Modal show={show} className="errorDetailsModal" onHide={handleClose}>
//             <Modal.Header closeButton>
//             <Modal.Title>Error Details</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>IRN is not present for provided GSTIN or IRN is not active(cancelled)</Modal.Body>
//         </Modal>
//     </section>)
// }

// export default EInvoiceDetails;

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector,useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import MoreIcon from '../../../assets/img/more_options.png';
import { useNavigate } from "react-router-dom";
import { TimeSense, getColor, getStringOfErrorValues } from "../../Common/TimeSense";
import ItemInvoiceCancel from "../../Common/ItemInvoiceCancel";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const EInvoiceDetails = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ FromDate, setFromDate ] = useState("");
    const [ ToDate, setToDate ] = useState("");
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ status, setStatus ] = useState("");
    const [ statusSearch, setStatusSearch ] = useState("");
    const [show, setShow] = useState(null)
    const handleShow = (e) => setShow(e);
    const today = new Date();
    const { device_id, EInvoiceFailure, ClearFormSet, selfInfo } = useSelector((state) => state.adminReducers);
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            callFilterData()
            Dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
        };
    }, [ ClearFormSet ]);

    useEffect(() => {
        if(websocket){
           callFilterData();
        }
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    const callFilterData = () =>{
        let param = {
            "transmit": "broadcast",
            "url": "invoice_list",
            "request" : {
                status: "CAN",
                limit: userLimit,
                page_no: currentPage,
                search: userSearch.trim(),
                ewb_status: "",
                order_by: "-updated_at",
                invoice_status: "SUCCESS",
                is_cancel:"",
                is_ewb_cancel:""
            },
            "DeviceId" : device_id
        };
        if(FromDate !== "" && ToDate !== ""){
            param.request.from_date =  CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date =  CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
        setTimeout(()=>setStatusSearch(status), [1000])
    }

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
          display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };
    const responseText = (response1) =>{
        const response = JSON.parse(response1);
        let text = "";
        if(typeof(response?.errors) == "object"){
            text = text +`</p><b>errors:</b> ${getStringOfErrorValues(response?.errors)}</p>`;
        }
        if(typeof(response?.message) == "object"){
            text = text +`</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
        }
        if(typeof(response?.response) == "object"){
            text = text +`</p><b>response:</b> ${getStringOfErrorValues(response?.response)}</p>`;
        }
        if(typeof(response?.message) == "string"){
            text = text +`</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
        }
        return text;
    }

    return(<section className="Main_section">
        <DataTableFilter 
            searchType={"Invoice ID."}
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: "calc(100vh - 190px)" }} 
                renderView={props => <div {...props} className="view"/>}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th hidden></th>
                            <th>SNo</th>
                            <th>Order Id</th>
                            <th>Order Date</th>
                            <th>Invoice ID</th>
                            <th>Invoice date</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[12]?.Edit) && (<th>Remarks</th>)}
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[12]?.Edit) && (<th>Action</th>)}
                            <th>Show Error</th>
                            {statusSearch === "DACT" && (
                                <React.Fragment>
                                    <th>Error</th>
                                    <th>Show Error</th>
                                </React.Fragment>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {EInvoiceFailure?.data?.map((elm, index) => {
                            return(
                            <React.Fragment key={index}>
                                <ItemInvoiceCancel ClearFormSet={ClearFormSet} statusSearch={statusSearch} handleShow={handleShow} activeTableDetails={activeTableDetails} setActiveTableDetails={setActiveTableDetails} elm={elm} index={index} device_id={device_id} websocket={websocket} EInvoiceFailure={EInvoiceFailure} />
                            </React.Fragment>
                        )})}
                        {(!EInvoiceFailure?.data || EInvoiceFailure?.data?.length <= 0) && <tr style={{ textAlign: "center" }} ><td colSpan="10">E-Invoice Cancel list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {EInvoiceFailure?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={EInvoiceFailure?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal} 
            setOrderViewerModal={setOrderViewerModal} 
        />
        {show!==null && (
            <Modal show={show!==null} className="errorDetailsModal" onHide={()=>setShow(null)}>
                <Modal.Header closeButton>
                <Modal.Title>Error Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: "auto"}}>
                    <p dangerouslySetInnerHTML={{__html:responseText(show?.invoice_response)}}>
                    </p>
                </Modal.Body>
            </Modal>
        )}
    </section>)
}

export default EInvoiceDetails;