import CommonDateTime from "./CommonDateTime";
import { API_URL } from "../Shared/constant";

const CommonExport = (url, data) => {
    if (url === "order_list") {
        window.open(API_URL+ `order/export_order/?from_date=${data?.FromDate === null ? "" : CommonDateTime(data?.FromDate, "YYYY-MM-DD", "hide")}&to_date=${data?.ToDate === null ? "" : CommonDateTime(data?.ToDate, "YYYY-MM-DD", "hide")}&from_inv=${data?.FromInv === null ? "" : CommonDateTime(data?.FromInv, "YYYY-MM-DD", "hide")}&to_inv=${data?.ToInv === null ? "" : CommonDateTime(data?.ToInv, "YYYY-MM-DD", "hide")}&status=${data?.status}&order_type=${data?.order_type}&region_id=${data?.region_id}&search=${data?.search}`, '_blank');
    } else if(url === "dncn_ledger") {
        window.open(API_URL+ `dncn/export_account_ledger/?limit=${data?.limit}&from_date=${CommonDateTime(data?.FromDate, "YYYY-MM-DD", "hide")}&to_date=${CommonDateTime(data?.ToDate, "YYYY-MM-DD", "hide")}`, '_blank');
    } else if(url === "export_debit_credit_note_report") {
        window.open(API_URL+ `dncn/export_debit_credit_note_report/?limit=${data?.limit}&franchise_id=${data?.franchise_id === undefined ? "" : data?.franchise_id}&from_date=${CommonDateTime(data?.FromDate, "YYYY-MM-DD", "hide")}&to_date=${CommonDateTime(data?.ToDate, "YYYY-MM-DD", "hide")}`, '_blank');
    } else if(url === "export_payment_approval") {
        window.open(API_URL+ `dncn/export_payment_approval/?limit=${data?.limit}&franchise_id=${data?.franchise_id === undefined ? "" : data?.franchise_id}&from_date=${CommonDateTime(data?.FromDate, "YYYY-MM-DD", "hide")}&to_date=${CommonDateTime(data?.ToDate, "YYYY-MM-DD", "hide")}&txn_type=Credit Request`, '_blank');
    } else if(url === "export_stock_frame") {
        window.open(API_URL+ `store/export_stock_frame/?limit=${data?.limit}&product_id=${data?.product_id === undefined ? "" : data?.product_id}&warehouse_id=${data?.warehouse_id === undefined ? "" : data?.warehouse_id}&from_date=${CommonDateTime(data?.FromDate, "YYYY-MM-DD", "hide")}&to_date=${CommonDateTime(data?.ToDate, "YYYY-MM-DD", "hide")}&search=${data?.search}&status=${data?.status}&order_type=${data?.order_type}`, '_blank');
    } else if(url === "export_stock_level") {
        window.open(API_URL+ `store/export_stock_level/?limit=${data?.limit}&product_id=${data?.product_id === undefined ? "" : data?.product_id}&warehouse_id=${data?.warehouse_id === undefined ? "" : data?.warehouse_id}&from_date=${CommonDateTime(data?.FromDate, "YYYY-MM-DD", "hide")}&to_date=${CommonDateTime(data?.ToDate, "YYYY-MM-DD", "hide")}`, '_blank');
    } else if(url === "export_stock_inward") {
        window.open(API_URL+ `store/export_stock_inward/?limit=${data?.limit}&product_id=${data?.product_id === undefined ? "" : data?.product_id}&warehouse_id=${data?.warehouse_id === undefined ? "" : data?.warehouse_id}`, '_blank');
    } else if(url === "stock_inward_log") {
        window.open(API_URL+ `store/export_stock_logs/?limit=${data?.limit}&product_id=${data?.product_id === undefined ? "" : data?.product_id}&warehouse_id=${data?.warehouse_id === undefined ? "" : data?.warehouse_id}&search=${data?.search}&status=${data?.status}&page_no=${data?.page_no}`, '_blank');
    };
}

export default CommonExport;