/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Dashboard = () => {
    const [ Tab, setTab ] = useState("sales_revenue_report");
    const { selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Dashboard?.DealerPerformance && PermissionsAccess?.Dashboard?.OrderFulfillment && PermissionsAccess?.Dashboard?.SalesRevenueReport)) {
            setTab("sales_revenue_report");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DealerPerformance) {
            setTab("dealer_performance");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.OrderFulfillment) {
            setTab("order_fulfillment");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.SalesRevenueReport) {
            setTab("sales_revenue_report");
        }
    }, [ selfInfo, PermissionsAccess ]);

    return(<div className="Main_section">
        {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Dashboard?.DealerPerformance || PermissionsAccess?.Dashboard?.OrderFulfillment || PermissionsAccess?.Dashboard?.SalesRevenueReport)) ? (<React.Fragment>
            <div className="MainHeader">
                <ul className="MainHeader_Tab">
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.SalesRevenueReport) && (<li  className={Tab === "sales_revenue_report" ? "active" : ""} onClick={() => setTab("sales_revenue_report")}>Sales Revenue Report</li>)}
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DealerPerformance) && (<li  className={Tab === "dealer_performance" ? "active" : ""} onClick={() => setTab("dealer_performance")}>Dealer Performance</li>)}
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.OrderFulfillment) && (<li  className={Tab === "order_fulfillment" ? "active" : ""} onClick={() => setTab("order_fulfillment")}>Order Fulfillment</li>)}
                </ul>
            </div>
            {Tab === "sales_revenue_report" ? (<div className="iframe_wrapper">
                <iframe width="100%" height="100%"  src="https://lookerstudio.google.com/embed/reporting/36377ba7-c97b-4855-a496-6e0fe1f9ffea/page/p3FzD" frameborder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>) 
            : Tab === "dealer_performance" ? (<div className="iframe_wrapper">
                <small className="no_record_fround">No data found</small>
            </div>) 
            : (<div className="iframe_wrapper">
                <iframe width="100%" id="framesidss" height="100%" src="https://lookerstudio.google.com/embed/reporting/00fb1a2e-3e14-4623-8d43-931d3b78983c/page/uX7yD" frameborder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>)}
        </React.Fragment>) : (<div className="permissionCenter">
            <span className="errorMessageIcon"><i className="bi bi-ban"></i>&nbsp; You do not have permission to view the dashboard.</span>
        </div>)}
    </div>)
}

export default Dashboard;