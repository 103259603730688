import React from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars-2';

const Accounts = () => {
    const { selfInfo } = useSelector((state) => state.adminReducers);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };
    
    return(<div className="acccount_detailas">
        <Scrollbars
            style={{ height: "calc(100vh - 100px)" }}
            renderView={props => <div {...props} className="view" />}
            renderTrackHorizontal={renderTrack}
            className="ScrollbarsSidebarHide"
        >
            <h4><div><i className="bi bi-person-fill"></i> Personal Information</div></h4>
            <div className="form_data_wrapper">
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <Form.Label>Full Name</Form.Label>
                            <div className="form_data">{selfInfo?.user?.full_name}</div>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <Form.Label>Email</Form.Label>
                            <div className="form_data">{selfInfo?.user?.email}</div>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <Form.Label>Mobile Number</Form.Label>
                            <div className="form_data">{(selfInfo?.user?.mobile !== null && selfInfo?.user?.mobile !== "") ? selfInfo?.user?.mobile : "-"}</div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group  >
                            <Form.Label>User Type</Form.Label>
                            <div className="form_data" style={{ textTransform: "capitalize" }}>{selfInfo?.user?.user_type?.replaceAll("_", " ")}</div>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <h4><div><i className="bi bi-geo-alt-fill"></i> Address</div></h4>
            <div className="form_data_wrapper">
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <Form.Label>Address Line 1</Form.Label>
                            <div className="form_data">{(selfInfo?.user?.address !== null && selfInfo?.user?.address !== "") ? selfInfo?.user?.address : "-"}</div>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-3" >
                            <Form.Label>Address Line 2</Form.Label>
                            <div className="form_data">{(selfInfo?.user?.address2 !== null && selfInfo?.user?.address2 !== "") ? selfInfo?.user?.address2 : "-"}</div>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </Scrollbars>
    </div>)
}

export default Accounts;