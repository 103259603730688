/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { USER_MANAGEMENT_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";
import Select from 'react-select'
import CommonLoader from "../../Common/CommonLoader";
import { StartMainCommonLoader } from "../../../redux/actions/adminActions";
import CommonPermissionModel from "../../Common/CommonPermissionModel";
import { apiMapping } from "../../../APIpermission";

const AddUser = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [UploadFileLoader, setUploadFileLoader] = useState(false);
    const [ApplicationState, setApplicationState] = useState({
        FullName: "",
        Email: "",
        Mobile: "",
        Address: "",
        address2: "",
        UserType: "",
        RelatedID: "",
        RegionType: "",
        Regional_manager: "",
        Status: true
    });
    const [ modules, setModules ] = useState({
        InventoryActive: false,
        ProductMastersActive: false,
        Dashboard: {
            SalesRevenueReport: false,
            DealerPerformance: false,
            OrderFulfillment: false,
        },
        ProductMasters: [
            {
                label: "Product Applications",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Category",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Sub Category",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Product Group",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Product Creation",
                Edit: false,
                View: false,
                API: ""
            }
        ],
        StoreActive: false,
        Store: [
            {
                label: "Stock Inward",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Stock Level",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Stock Details",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Stock Inward Log",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Stock Details Report",
                Edit: false,
                View: false,
                API: ""
            }
        ],
        WarehouseActive: false,
        Warehouse: [
            {
                label: "Warehouse",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Warehouse Quick Login",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Dispatch List",
                Edit: false,
                View: false,
                API: ""
            },
        ],
        AdminToolsActive: false,
        AdminTools: [
            {
                label: "Users",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Create Franchise",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Franchise Type Report",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Rm Quick Login",
                Edit: false,
                View: false,
                API: ""
            }
        ],
        Masters: [
            {
                label: "States",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Cities",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Pincodes",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Box",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Couriers",
                Edit: false,
                View: false,
                API: ""
            },
        ],
        CRMToolsActive: false,
        CRMTools: [
            {
                label: "Region Master",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Purchase List",
                Edit: false,
                View: false,
                API: ""
            }
        ],
        OrderManagementActive: false,
        OrderManagement: [
            {
                label: "All Orders Report",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "SO Creation",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "SO Approvals",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Warehouse Approvals",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Pending For Accounts",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Pending For Stores",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Pending For Dispatch",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Pending For Delivery",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Order Delivered",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Order Rejected",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Order Cancelled",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "E-Invoice Details",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "E-Invoice Cancel",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "E-Way Bill Report",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Return And Replace List",
                Edit: false,
                View: false,
                API: ""
            }
        ],
        PaymentManagerActive: false,
        PaymentManager: [
            {
                label: "Account Ledger Report",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Debit Credit Notes",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Debit Credit Notes Report",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Payment Approval",
                Edit: false,
                View: false,
                API: ""
            },
            {
                label: "Gst Data Report",
                Edit: false,
                View: false,
                API: ""
            },
        ],
    });

    // Function to extract API values from the object
    function extractAPIs(data) {
        const apiArray = [];
        // Iterate over each module
        if(data !== null) {
            Object.values(data).forEach(module => {
                // Check if module is an array
                if (Array.isArray(module)) {
                    // Iterate over each object in the module
                    module.forEach(obj => {
                        // Check if the object has an API property
                        if (obj.API) {
                            // Split the API property by comma to handle multiple APIs
                            const apis = obj.API.split(',').map(api => api.trim());
                            // Add each API to the apiArray
                            apis.forEach(api => {
                                if (!apiArray.includes(api)) {
                                    apiArray.push(api);
                                }
                            });
                        }
                    });
                }
            });
            return apiArray;
        };
    };

    // Extract APIs
    const uniqueAPIs = extractAPIs(modules);

    const [errorAddress, seterrorAddress] = useState("");
    const [errorAddress2, seterrorAddress2] = useState("");
    const { roomId } = useParams();

    const { ClearFormSet, userList, Regionlistall,userlistAll, wareHouselistall, device_id, commonMainLoader, selfInfo } = useSelector((state) => state.adminReducers);
    const CurrentData_ = userList?.list?.filter((elm) => elm?.id === roomId)[0];    

    const [optionsRegion, SetOptionsRegion] = useState(() => Regionlistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.region_name })));
    let defaultRegionOption = optionsRegion?.filter((elm) => elm?.key === CurrentData_?.related_id)[0];

    const [optionsWareHouse, SetOptionsWareHouse] = useState(() => wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} (${item?.warehouse_code})` })));
    let defaultWareHouseOption = optionsWareHouse?.filter((elm) => elm?.key === CurrentData_?.related_id)[0];
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    // error state
    const [errorFirstName, seterrorFirstName] = useState("");
    const [errorMobile, seterrorMobile] = useState("");
    const [errorEmail, seterrorEmail] = useState("");
    const [errorUserType, seterrorUserType] = useState("");
    const [errorRegionalType, seterrorerrorRegionalType] = useState("");
    const [errorRelatedID, seterrorRelatedID] = useState("");
    const [errorRegionalManager, setErrorRegionalManager] = useState("")
    const [optionsRegionalManager, SetoptionsRegionalManager] = useState([]);
    const [permissionsError, setpermissionsError] = useState("");

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    //permission model
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    const showPermission = (userType) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Select Permission",
            subtitle: "user permission",
            modalType: "form",
            // button: "Yes",
            data: userType
        });
    }

    useEffect(() => {
        if(ApplicationState?.UserType !== "") {
            if(ApplicationState?.UserType === "admin") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: true,
                    ProductMastersActive: true,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: true,
                            View: true,
                            API: apiMapping["Product Applications"]?.["Edit"]
                        },
                        {
                            label: "Category",
                            Edit: true,
                            View: true,
                            API: apiMapping["Category"]?.["Edit"]
                        },
                        {
                            label: "Sub Category",
                            Edit: true,
                            View: true,
                            API: apiMapping["Sub Category"]?.["Edit"]
                        },
                        {
                            label: "Product Group",
                            Edit: true,
                            View: true,
                            API: apiMapping["Product Group"]?.["Edit"]
                        },
                        {
                            label: "Product Creation",
                            Edit: true,
                            View: true,
                            API: apiMapping["Product Creation"]?.["Edit"]
                        }
                    ],
                    StoreActive: true,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Inward"]?.["Edit"]
                        },
                        {
                            label: "Stock Level",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Level"]?.["Edit"]
                        },
                        {
                            label: "Stock Details",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Details"]?.["Edit"]
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Inward Log"]?.["Edit"]
                        },
                        {
                            label: "Stock Details Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Details Report"]?.["Edit"]
                        }
                    ],
                    WarehouseActive: true,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse"]?.["Edit"]
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse Quick Login"]?.["Edit"]
                        },
                        {
                            label: "Dispatch List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Dispatch List"]?.["Edit"]
                        },
                    ],
                    AdminToolsActive: true,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: true,
                            View: true,
                            API: apiMapping["Users"]?.["Edit"]
                        },
                        {
                            label: "Create Franchise",
                            Edit: true,
                            View: true,
                            API: apiMapping["Create Franchise"]?.["Edit"]
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Franchise Type Report"]?.["Edit"]
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: true,
                            View: true,
                            API: apiMapping["Rm Quick Login"]?.["Edit"]
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: true,
                            View: true,
                            API: apiMapping["States"]?.["Edit"]
                        },
                        {
                            label: "Cities",
                            Edit: true,
                            View: true,
                            API: apiMapping["Cities"]?.["Edit"]
                        },
                        {
                            label: "Pincodes",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pincodes"]?.["Edit"]
                        },
                        {
                            label: "Box",
                            Edit: true,
                            View: true,
                            API: apiMapping["Box"]?.["Edit"]
                        },
                        {
                            label: "Couriers",
                            Edit: true,
                            View: true,
                            API: apiMapping["Couriers"]?.["Edit"]
                        },
                    ],
                    CRMToolsActive: true,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: true,
                            View: true,
                            API: apiMapping["Region Master"]?.["Edit"]
                        },
                        {
                            label: "Purchase List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Purchase List"]?.["Edit"]
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["All Orders Report"]?.["Edit"]
                        },
                        {
                            label: "SO Creation",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Creation"]?.["Edit"]
                        },
                        {
                            label: "SO Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Approvals"]?.["Edit"]
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse Approvals"]?.["Edit"]
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Accounts"]?.["Edit"]
                        },
                        {
                            label: "Pending For Stores",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Stores"]?.["Edit"]
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Dispatch"]?.["Edit"]
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Delivery"]?.["Edit"]
                        },
                        {
                            label: "Order Delivered",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Delivered"]?.["Edit"]
                        },
                        {
                            label: "Order Rejected",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Rejected"]?.["Edit"]
                        },
                        {
                            label: "Order Cancelled",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Cancelled"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Details"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Cancel"]?.["Edit"]
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Way Bill Report"]?.["Edit"]
                        },
                        {
                            label: "Return And Replace List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Return And Replace List"]?.["Edit"]
                        }
                    ],
                    PaymentManagerActive: true,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Account Ledger Report"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes Report"]?.["Edit"]
                        },
                        {
                            label: "Payment Approval",
                            Edit: true,
                            View: true,
                            API: apiMapping["Payment Approval"]?.["Edit"]
                        },
                        {
                            label: "Gst Data Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Gst Data Report"]?.["Edit"]
                        },
                    ],
                });
            } else if(ApplicationState?.UserType === "warehouse_manager") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: true,
                    ProductMastersActive: false,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Category",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Sub Category",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Product Group",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Product Creation",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    StoreActive: true,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Stock Level",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Level"]?.["Edit"]
                        },
                        {
                            label: "Stock Details",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Stock Details Report",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    WarehouseActive: false,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Dispatch List",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                    ],
                    AdminToolsActive: false,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Create Franchise",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Cities",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Pincodes",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Box",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Couriers",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                    ],
                    CRMToolsActive: false,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Purchase List",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "SO Creation",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "SO Approvals",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse Approvals"]?.["Edit"]
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Pending For Stores",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Stores"]?.["Edit"]
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Dispatch"]?.["Edit"]
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Delivery"]?.["Edit"]
                        },
                        {
                            label: "Order Delivered",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Delivered"]?.["Edit"]
                        },
                        {
                            label: "Order Rejected",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Rejected"]?.["Edit"]
                        },
                        {
                            label: "Order Cancelled",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Cancelled"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Return And Replace List",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    PaymentManagerActive: false,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Payment Approval",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Gst Data Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                    ],
                });
            } else if(ApplicationState?.UserType === "regional_sales_team") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: false,
                    ProductMastersActive: false,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Category",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Sub Category",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Product Group",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Product Creation",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    StoreActive: false,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Stock Level",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Stock Details",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Stock Details Report",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    WarehouseActive: false,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Dispatch List",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                    ],
                    AdminToolsActive: false,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Create Franchise",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Cities",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Pincodes",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Box",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Couriers",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                    ],
                    CRMToolsActive: false,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Purchase List",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["All Orders Report"]?.["Edit"]
                        },
                        {
                            label: "SO Creation",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Creation"]?.["Edit"]
                        },
                        {
                            label: "SO Approvals",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Pending For Stores",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Order Delivered",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Order Rejected",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Order Cancelled",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Return And Replace List",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    PaymentManagerActive: true,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Account Ledger Report"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Payment Approval",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Gst Data Report",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                    ],
                });
            } else if(ApplicationState?.UserType === "sales_operations") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: true,
                    ProductMastersActive: true,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: true,
                            View: true,
                            API: apiMapping["Product Applications"]?.["Edit"]
                        },
                        {
                            label: "Category",
                            Edit: true,
                            View: true,
                            API: apiMapping["Category"]?.["Edit"]
                        },
                        {
                            label: "Sub Category",
                            Edit: true,
                            View: true,
                            API: apiMapping["Sub Category"]?.["Edit"]
                        },
                        {
                            label: "Product Group",
                            Edit: true,
                            View: true,
                            API: apiMapping["Product Group"]?.["Edit"]
                        },
                        {
                            label: "Product Creation",
                            Edit: true,
                            View: true,
                            API: apiMapping["Product Creation"]?.["Edit"]
                        }
                    ],
                    StoreActive: true,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Inward"]?.["Edit"]
                        },
                        {
                            label: "Stock Level",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Level"]?.["Edit"]
                        },
                        {
                            label: "Stock Details",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Details"]?.["Edit"]
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Inward Log"]?.["Edit"]
                        },
                        {
                            label: "Stock Details Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Stock Details Report"]?.["Edit"]
                        }
                    ],
                    WarehouseActive: true,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse"]?.["Edit"]
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse Quick Login"]?.["Edit"]
                        },
                        {
                            label: "Dispatch List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Dispatch List"]?.["Edit"]
                        },
                    ],
                    AdminToolsActive: true,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: true,
                            View: true,
                            API: apiMapping["Users"]?.["Edit"]
                        },
                        {
                            label: "Create Franchise",
                            Edit: true,
                            View: true,
                            API: apiMapping["Create Franchise"]?.["Edit"]
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Franchise Type Report"]?.["Edit"]
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: true,
                            View: true,
                            API: apiMapping["Rm Quick Login"]?.["Edit"]
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: true,
                            View: true,
                            API: apiMapping["States"]?.["Edit"]
                        },
                        {
                            label: "Cities",
                            Edit: true,
                            View: true,
                            API: apiMapping["Cities"]?.["Edit"]
                        },
                        {
                            label: "Pincodes",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pincodes"]?.["Edit"]
                        },
                        {
                            label: "Box",
                            Edit: true,
                            View: true,
                            API: apiMapping["Box"]?.["Edit"]
                        },
                        {
                            label: "Couriers",
                            Edit: true,
                            View: true,
                            API: apiMapping["Couriers"]?.["Edit"]
                        },
                    ],
                    CRMToolsActive: true,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: true,
                            View: true,
                            API: apiMapping["Region Master"]?.["Edit"]
                        },
                        {
                            label: "Purchase List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Purchase List"]?.["Edit"]
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["All Orders Report"]?.["Edit"]
                        },
                        {
                            label: "SO Creation",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Creation"]?.["Edit"]
                        },
                        {
                            label: "SO Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Approvals"]?.["Edit"]
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse Approvals"]?.["Edit"]
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Accounts"]?.["Edit"]
                        },
                        {
                            label: "Pending For Stores",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Stores"]?.["Edit"]
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Dispatch"]?.["Edit"]
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Delivery"]?.["Edit"]
                        },
                        {
                            label: "Order Delivered",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Delivered"]?.["Edit"]
                        },
                        {
                            label: "Order Rejected",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Rejected"]?.["Edit"]
                        },
                        {
                            label: "Order Cancelled",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Cancelled"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Details"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Cancel"]?.["Edit"]
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Way Bill Report"]?.["Edit"]
                        },
                        {
                            label: "Return And Replace List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Return And Replace List"]?.["Edit"]
                        }
                    ],
                    PaymentManagerActive: true,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Account Ledger Report"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes Report"]?.["Edit"]
                        },
                        {
                            label: "Payment Approval",
                            Edit: true,
                            View: true,
                            API: apiMapping["Payment Approval"]?.["Edit"]
                        },
                        {
                            label: "Gst Data Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Gst Data Report"]?.["Edit"]
                        },
                    ],
                });
            } else if(ApplicationState?.UserType === "inventory_operations") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: true,
                    ProductMastersActive: true,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Applications"]?.["View"]
                        },
                        {
                            label: "Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Category"]?.["View"]
                        },
                        {
                            label: "Sub Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Sub Category"]?.["View"]
                        },
                        {
                            label: "Product Group",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Group"]?.["View"]
                        },
                        {
                            label: "Product Creation",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Creation"]?.["View"]
                        }
                    ],
                    StoreActive: true,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward"]?.["View"]
                        },
                        {
                            label: "Stock Level",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Level"]?.["View"]
                        },
                        {
                            label: "Stock Details",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details"]?.["View"]
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward Log"]?.["View"]
                        },
                        {
                            label: "Stock Details Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details Report"]?.["View"]
                        }
                    ],
                    WarehouseActive: true,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse"]?.["View"]
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse Quick Login"]?.["View"]
                        },
                        {
                            label: "Dispatch List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Dispatch List"]?.["View"]
                        },
                    ],
                    AdminToolsActive: true,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: false,
                            View: true,
                            API: apiMapping["Users"]?.["View"]
                        },
                        {
                            label: "Create Franchise",
                            Edit: false,
                            View: true,
                            API: apiMapping["Create Franchise"]?.["View"]
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Franchise Type Report"]?.["View"]
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Rm Quick Login"]?.["View"]
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: false,
                            View: true,
                            API: apiMapping["States"]?.["View"]
                        },
                        {
                            label: "Cities",
                            Edit: false,
                            View: true,
                            API: apiMapping["Cities"]?.["View"]
                        },
                        {
                            label: "Pincodes",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pincodes"]?.["View"]
                        },
                        {
                            label: "Box",
                            Edit: false,
                            View: true,
                            API: apiMapping["Box"]?.["View"]
                        },
                        {
                            label: "Couriers",
                            Edit: false,
                            View: true,
                            API: apiMapping["Couriers"]?.["View"]
                        },
                    ],
                    CRMToolsActive: true,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: false,
                            View: true,
                            API: apiMapping["Region Master"]?.["View"]
                        },
                        {
                            label: "Purchase List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Purchase List"]?.["View"]
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["All Orders Report"]?.["Edit"]
                        },
                        {
                            label: "SO Creation",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Creation"]?.["Edit"]
                        },
                        {
                            label: "SO Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Approvals"]?.["Edit"]
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse Approvals"]?.["Edit"]
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Accounts"]?.["Edit"]
                        },
                        {
                            label: "Pending For Stores",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Stores"]?.["Edit"]
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Dispatch"]?.["Edit"]
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Delivery"]?.["Edit"]
                        },
                        {
                            label: "Order Delivered",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Delivered"]?.["Edit"]
                        },
                        {
                            label: "Order Rejected",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Rejected"]?.["Edit"]
                        },
                        {
                            label: "Order Cancelled",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Cancelled"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Details"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Cancel"]?.["Edit"]
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Way Bill Report"]?.["Edit"]
                        },
                        {
                            label: "Return And Replace List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Return And Replace List"]?.["Edit"]
                        }
                    ],
                    PaymentManagerActive: true,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Account Ledger Report"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes Report"]?.["Edit"]
                        },
                        {
                            label: "Payment Approval",
                            Edit: true,
                            View: true,
                            API: apiMapping["Payment Approval"]?.["Edit"]
                        },
                        {
                            label: "Gst Data Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Gst Data Report"]?.["Edit"]
                        },
                    ],
                });
            } else if(ApplicationState?.UserType === "logistics_ops") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: true,
                    ProductMastersActive: true,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Applications"]?.["View"]
                        },
                        {
                            label: "Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Category"]?.["View"]
                        },
                        {
                            label: "Sub Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Sub Category"]?.["View"]
                        },
                        {
                            label: "Product Group",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Group"]?.["View"]
                        },
                        {
                            label: "Product Creation",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Creation"]?.["View"]
                        }
                    ],
                    StoreActive: true,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward"]?.["View"]
                        },
                        {
                            label: "Stock Level",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Level"]?.["View"]
                        },
                        {
                            label: "Stock Details",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details"]?.["View"]
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward Log"]?.["View"]
                        },
                        {
                            label: "Stock Details Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details Report"]?.["View"]
                        }
                    ],
                    WarehouseActive: true,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse"]?.["View"]
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse Quick Login"]?.["View"]
                        },
                        {
                            label: "Dispatch List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Dispatch List"]?.["View"]
                        },
                    ],
                    AdminToolsActive: true,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: false,
                            View: true,
                            API: apiMapping["Users"]?.["View"]
                        },
                        {
                            label: "Create Franchise",
                            Edit: false,
                            View: true,
                            API: apiMapping["Create Franchise"]?.["View"]
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Franchise Type Report"]?.["View"]
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Rm Quick Login"]?.["View"]
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: false,
                            View: true,
                            API: apiMapping["States"]?.["View"]
                        },
                        {
                            label: "Cities",
                            Edit: false,
                            View: true,
                            API: apiMapping["Cities"]?.["View"]
                        },
                        {
                            label: "Pincodes",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pincodes"]?.["View"]
                        },
                        {
                            label: "Box",
                            Edit: false,
                            View: true,
                            API: apiMapping["Box"]?.["View"]
                        },
                        {
                            label: "Couriers",
                            Edit: false,
                            View: true,
                            API: apiMapping["Couriers"]?.["View"]
                        },
                    ],
                    CRMToolsActive: true,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: false,
                            View: true,
                            API: apiMapping["Region Master"]?.["View"]
                        },
                        {
                            label: "Purchase List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Purchase List"]?.["View"]
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["All Orders Report"]?.["Edit"]
                        },
                        {
                            label: "SO Creation",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Creation"]?.["Edit"]
                        },
                        {
                            label: "SO Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Approvals"]?.["Edit"]
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse Approvals"]?.["Edit"]
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Accounts"]?.["Edit"]
                        },
                        {
                            label: "Pending For Stores",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Stores"]?.["Edit"]
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Dispatch"]?.["Edit"]
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Delivery"]?.["Edit"]
                        },
                        {
                            label: "Order Delivered",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Delivered"]?.["Edit"]
                        },
                        {
                            label: "Order Rejected",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Rejected"]?.["Edit"]
                        },
                        {
                            label: "Order Cancelled",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Cancelled"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Details"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Cancel"]?.["Edit"]
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Way Bill Report"]?.["Edit"]
                        },
                        {
                            label: "Return And Replace List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Return And Replace List"]?.["Edit"]
                        }
                    ],
                    PaymentManagerActive: true,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Account Ledger Report"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes Report"]?.["Edit"]
                        },
                        {
                            label: "Payment Approval",
                            Edit: true,
                            View: true,
                            API: apiMapping["Payment Approval"]?.["Edit"]
                        },
                        {
                            label: "Gst Data Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Gst Data Report"]?.["Edit"]
                        },
                    ],
                });
            } else if(ApplicationState?.UserType === "accounts") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: true,
                    ProductMastersActive: true,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Applications"]?.["View"]
                        },
                        {
                            label: "Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Category"]?.["View"]
                        },
                        {
                            label: "Sub Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Sub Category"]?.["View"]
                        },
                        {
                            label: "Product Group",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Group"]?.["View"]
                        },
                        {
                            label: "Product Creation",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Creation"]?.["View"]
                        }
                    ],
                    StoreActive: true,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward"]?.["View"]
                        },
                        {
                            label: "Stock Level",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Level"]?.["View"]
                        },
                        {
                            label: "Stock Details",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details"]?.["View"]
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward Log"]?.["View"]
                        },
                        {
                            label: "Stock Details Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details Report"]?.["View"]
                        }
                    ],
                    WarehouseActive: true,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse"]?.["View"]
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse Quick Login"]?.["View"]
                        },
                        {
                            label: "Dispatch List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Dispatch List"]?.["View"]
                        },
                    ],
                    AdminToolsActive: true,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: false,
                            View: true,
                            API: apiMapping["Users"]?.["View"]
                        },
                        {
                            label: "Create Franchise",
                            Edit: false,
                            View: true,
                            API: apiMapping["Create Franchise"]?.["View"]
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Franchise Type Report"]?.["View"]
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Rm Quick Login"]?.["View"]
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: false,
                            View: true,
                            API: apiMapping["States"]?.["View"]
                        },
                        {
                            label: "Cities",
                            Edit: false,
                            View: true,
                            API: apiMapping["Cities"]?.["View"]
                        },
                        {
                            label: "Pincodes",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pincodes"]?.["View"]
                        },
                        {
                            label: "Box",
                            Edit: false,
                            View: true,
                            API: apiMapping["Box"]?.["View"]
                        },
                        {
                            label: "Couriers",
                            Edit: false,
                            View: true,
                            API: apiMapping["Couriers"]?.["View"]
                        },
                    ],
                    CRMToolsActive: true,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Purchase List",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["All Orders Report"]?.["Edit"]
                        },
                        {
                            label: "SO Creation",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Creation"]?.["Edit"]
                        },
                        {
                            label: "SO Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["SO Approvals"]?.["Edit"]
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: true,
                            View: true,
                            API: apiMapping["Warehouse Approvals"]?.["Edit"]
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Accounts"]?.["Edit"]
                        },
                        {
                            label: "Pending For Stores",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Stores"]?.["Edit"]
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Dispatch"]?.["Edit"]
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: true,
                            View: true,
                            API: apiMapping["Pending For Delivery"]?.["Edit"]
                        },
                        {
                            label: "Order Delivered",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Delivered"]?.["Edit"]
                        },
                        {
                            label: "Order Rejected",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Rejected"]?.["Edit"]
                        },
                        {
                            label: "Order Cancelled",
                            Edit: true,
                            View: true,
                            API: apiMapping["Order Cancelled"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Details"]?.["Edit"]
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Invoice Cancel"]?.["Edit"]
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["E-Way Bill Report"]?.["Edit"]
                        },
                        {
                            label: "Return And Replace List",
                            Edit: true,
                            View: true,
                            API: apiMapping["Return And Replace List"]?.["Edit"]
                        }
                    ],
                    PaymentManagerActive: true,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Account Ledger Report"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes"]?.["Edit"]
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Debit Credit Notes Report"]?.["Edit"]
                        },
                        {
                            label: "Payment Approval",
                            Edit: true,
                            View: true,
                            API: apiMapping["Payment Approval"]?.["Edit"]
                        },
                        {
                            label: "Gst Data Report",
                            Edit: true,
                            View: true,
                            API: apiMapping["Gst Data Report"]?.["Edit"]
                        },
                    ],
                });
            } else if(ApplicationState?.UserType === "inside_sales") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: true,
                    ProductMastersActive: true,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Applications"]?.["View"]
                        },
                        {
                            label: "Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Category"]?.["View"]
                        },
                        {
                            label: "Sub Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Sub Category"]?.["View"]
                        },
                        {
                            label: "Product Group",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Group"]?.["View"]
                        },
                        {
                            label: "Product Creation",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Creation"]?.["View"]
                        }
                    ],
                    StoreActive: true,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward"]?.["View"]
                        },
                        {
                            label: "Stock Level",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Level"]?.["View"]
                        },
                        {
                            label: "Stock Details",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details"]?.["View"]
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward Log"]?.["View"]
                        },
                        {
                            label: "Stock Details Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details Report"]?.["View"]
                        }
                    ],
                    WarehouseActive: true,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse"]?.["View"]
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse Quick Login"]?.["View"]
                        },
                        {
                            label: "Dispatch List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Dispatch List"]?.["View"]
                        },
                    ],
                    AdminToolsActive: true,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: false,
                            View: true,
                            API: apiMapping["Users"]?.["View"]
                        },
                        {
                            label: "Create Franchise",
                            Edit: false,
                            View: true,
                            API: apiMapping["Create Franchise"]?.["View"]
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Franchise Type Report"]?.["View"]
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Rm Quick Login"]?.["View"]
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: false,
                            View: true,
                            API: apiMapping["States"]?.["View"]
                        },
                        {
                            label: "Cities",
                            Edit: false,
                            View: true,
                            API: apiMapping["Cities"]?.["View"]
                        },
                        {
                            label: "Pincodes",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pincodes"]?.["View"]
                        },
                        {
                            label: "Box",
                            Edit: false,
                            View: true,
                            API: apiMapping["Box"]?.["View"]
                        },
                        {
                            label: "Couriers",
                            Edit: false,
                            View: true,
                            API: apiMapping["Couriers"]?.["View"]
                        },
                    ],
                    CRMToolsActive: true,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Purchase List",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["All Orders Report"]?.["View"]
                        },
                        {
                            label: "SO Creation",
                            Edit: false,
                            View: true,
                            API: apiMapping["SO Creation"]?.["View"]
                        },
                        {
                            label: "SO Approvals",
                            Edit: false,
                            View: true,
                            API: apiMapping["SO Approvals"]?.["View"]
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse Approvals"]?.["View"]
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pending For Accounts"]?.["View"]
                        },
                        {
                            label: "Pending For Stores",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pending For Stores"]?.["View"]
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pending For Dispatch"]?.["View"]
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pending For Delivery"]?.["View"]
                        },
                        {
                            label: "Order Delivered",
                            Edit: false,
                            View: true,
                            API: apiMapping["Order Delivered"]?.["View"]
                        },
                        {
                            label: "Order Rejected",
                            Edit: false,
                            View: true,
                            API: apiMapping["Order Rejected"]?.["View"]
                        },
                        {
                            label: "Order Cancelled",
                            Edit: false,
                            View: true,
                            API: apiMapping["Order Cancelled"]?.["View"]
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: false,
                            View: true,
                            API: apiMapping["E-Invoice Details"]?.["View"]
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: false,
                            View: true,
                            API: apiMapping["E-Invoice Cancel"]?.["View"]
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["E-Way Bill Report"]?.["View"]
                        },
                        {
                            label: "Return And Replace List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Return And Replace List"]?.["View"]
                        }
                    ],
                    PaymentManagerActive: true,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Account Ledger Report"]?.["View"]
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: false,
                            View: true,
                            API: apiMapping["Debit Credit Notes"]?.["View"]
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Debit Credit Notes Report"]?.["View"]
                        },
                        {
                            label: "Payment Approval",
                            Edit: false,
                            View: true,
                            API: apiMapping["Payment Approval"]?.["View"]
                        },
                        {
                            label: "Gst Data Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Gst Data Report"]?.["View"]
                        },
                    ],
                });
            } else if(ApplicationState?.UserType === "ecommerce") {
                setModules({
                    ...modules,
                    Dashboard: {
                        SalesRevenueReport: true,
                        DealerPerformance: true,
                        OrderFulfillment: true,
                    },
                    InventoryActive: true,
                    ProductMastersActive: true,
                    ProductMasters: [
                        {
                            label: "Product Applications",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Applications"]?.["View"]
                        },
                        {
                            label: "Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Category"]?.["View"]
                        },
                        {
                            label: "Sub Category",
                            Edit: false,
                            View: true,
                            API: apiMapping["Sub Category"]?.["View"]
                        },
                        {
                            label: "Product Group",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Group"]?.["View"]
                        },
                        {
                            label: "Product Creation",
                            Edit: false,
                            View: true,
                            API: apiMapping["Product Creation"]?.["View"]
                        }
                    ],
                    StoreActive: true,
                    Store: [
                        {
                            label: "Stock Inward",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward"]?.["View"]
                        },
                        {
                            label: "Stock Level",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Level"]?.["View"]
                        },
                        {
                            label: "Stock Details",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details"]?.["View"]
                        },
                        {
                            label: "Stock Inward Log",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Inward Log"]?.["View"]
                        },
                        {
                            label: "Stock Details Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Stock Details Report"]?.["View"]
                        }
                    ],
                    WarehouseActive: true,
                    Warehouse: [
                        {
                            label: "Warehouse",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse"]?.["View"]
                        },
                        {
                            label: "Warehouse Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse Quick Login"]?.["View"]
                        },
                        {
                            label: "Dispatch List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Dispatch List"]?.["View"]
                        },
                    ],
                    AdminToolsActive: true,
                    AdminTools: [
                        {
                            label: "Users",
                            Edit: false,
                            View: true,
                            API: apiMapping["Users"]?.["View"]
                        },
                        {
                            label: "Create Franchise",
                            Edit: false,
                            View: true,
                            API: apiMapping["Create Franchise"]?.["View"]
                        },
                        {
                            label: "Franchise Type Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Franchise Type Report"]?.["View"]
                        },
                        {
                            label: "Rm Quick Login",
                            Edit: false,
                            View: true,
                            API: apiMapping["Rm Quick Login"]?.["View"]
                        }
                    ],
                    Masters: [
                        {
                            label: "States",
                            Edit: false,
                            View: true,
                            API: apiMapping["States"]?.["View"]
                        },
                        {
                            label: "Cities",
                            Edit: false,
                            View: true,
                            API: apiMapping["Cities"]?.["View"]
                        },
                        {
                            label: "Pincodes",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pincodes"]?.["View"]
                        },
                        {
                            label: "Box",
                            Edit: false,
                            View: true,
                            API: apiMapping["Box"]?.["View"]
                        },
                        {
                            label: "Couriers",
                            Edit: false,
                            View: true,
                            API: apiMapping["Couriers"]?.["View"]
                        },
                    ],
                    CRMToolsActive: true,
                    CRMTools: [
                        {
                            label: "Region Master",
                            Edit: false,
                            View: false,
                            API: ""
                        },
                        {
                            label: "Purchase List",
                            Edit: false,
                            View: false,
                            API: ""
                        }
                    ],
                    OrderManagementActive: true,
                    OrderManagement: [
                        {
                            label: "All Orders Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["All Orders Report"]?.["View"]
                        },
                        {
                            label: "SO Creation",
                            Edit: false,
                            View: true,
                            API: apiMapping["SO Creation"]?.["View"]
                        },
                        {
                            label: "SO Approvals",
                            Edit: false,
                            View: true,
                            API: apiMapping["SO Approvals"]?.["View"]
                        },
                        {
                            label: "Warehouse Approvals",
                            Edit: false,
                            View: true,
                            API: apiMapping["Warehouse Approvals"]?.["View"]
                        },
                        {
                            label: "Pending For Accounts",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pending For Accounts"]?.["View"]
                        },
                        {
                            label: "Pending For Stores",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pending For Stores"]?.["View"]
                        },
                        {
                            label: "Pending For Dispatch",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pending For Dispatch"]?.["View"]
                        },
                        {
                            label: "Pending For Delivery",
                            Edit: false,
                            View: true,
                            API: apiMapping["Pending For Delivery"]?.["View"]
                        },
                        {
                            label: "Order Delivered",
                            Edit: false,
                            View: true,
                            API: apiMapping["Order Delivered"]?.["View"]
                        },
                        {
                            label: "Order Rejected",
                            Edit: false,
                            View: true,
                            API: apiMapping["Order Rejected"]?.["View"]
                        },
                        {
                            label: "Order Cancelled",
                            Edit: false,
                            View: true,
                            API: apiMapping["Order Cancelled"]?.["View"]
                        },
                        {
                            label: "E-Invoice Details",
                            Edit: false,
                            View: true,
                            API: apiMapping["E-Invoice Details"]?.["View"]
                        },
                        {
                            label: "E-Invoice Cancel",
                            Edit: false,
                            View: true,
                            API: apiMapping["E-Invoice Cancel"]?.["View"]
                        },
                        {
                            label: "E-Way Bill Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["E-Way Bill Report"]?.["View"]
                        },
                        {
                            label: "Return And Replace List",
                            Edit: false,
                            View: true,
                            API: apiMapping["Return And Replace List"]?.["View"]
                        }
                    ],
                    PaymentManagerActive: true,
                    PaymentManager: [
                        {
                            label: "Account Ledger Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Account Ledger Report"]?.["View"]
                        },
                        {
                            label: "Debit Credit Notes",
                            Edit: false,
                            View: true,
                            API: apiMapping["Debit Credit Notes"]?.["View"]
                        },
                        {
                            label: "Debit Credit Notes Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Debit Credit Notes Report"]?.["View"]
                        },
                        {
                            label: "Payment Approval",
                            Edit: false,
                            View: true,
                            API: apiMapping["Payment Approval"]?.["View"]
                        },
                        {
                            label: "Gst Data Report",
                            Edit: false,
                            View: true,
                            API: apiMapping["Gst Data Report"]?.["View"]
                        },
                    ],
                });
            };
        };
    }, [ ApplicationState ]);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "user_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        if (LocationRoute === "edit-user") {
            if(CurrentData_?.user_type === "regional_sales_team_asm") {
                setApplicationState({
                    ...ApplicationState,
                    FullName: CurrentData_?.full_name,
                    Email: CurrentData_?.email,
                    Mobile: CurrentData_?.mobile,
                    Address: CurrentData_?.address === null ? "" : CurrentData_?.address,
                    address2: CurrentData_?.address2 === null ? "" : CurrentData_?.address2,
                    UserType: (CurrentData_?.user_type === "regional_sales_team_asm" || CurrentData_?.user_type === "regional_sales_team_rm") ? "regional_sales_team" : CurrentData_?.user_type,
                    RelatedID: optionsRegion?.filter((elm) => elm?.value === CurrentData_?.region_id)[0],
                    Regional_manager: userlistAll.filter((ele) => ele.id === CurrentData_?.related_id).map((item) => ({ id: item.id, value: item.id, label:item.full_name }))[0],
                    RegionType: CurrentData_?.user_type === "regional_sales_team_asm" ? "assistant_sales_manager" : "regional_manager",
                    Status: CurrentData_?.is_active
                });

                if (CurrentData_?.related_id !== null) {
                    seterrorRelatedID("");
                };
            } else if(CurrentData_?.user_type === "regional_sales_team_rm" || CurrentData_?.user_type === "regional_manager") {
                setApplicationState({
                    ...ApplicationState,
                    FullName: CurrentData_?.full_name,
                    Email: CurrentData_?.email,
                    Mobile: CurrentData_?.mobile,
                    Address: CurrentData_?.address === null ? "" : CurrentData_?.address,
                    address2: CurrentData_?.address2 === null ? "" : CurrentData_?.address2,
                    UserType: (CurrentData_?.user_type === "regional_sales_team_asm" || CurrentData_?.user_type === "regional_sales_team_rm") ? "regional_sales_team" : CurrentData_?.user_type,
                    RelatedID: optionsRegion?.filter((elm) => elm?.value === CurrentData_?.related_id)[0],
                    // Regional_manager: userlistAll.filter((ele) => ele.id === CurrentData_?.related_id).map((item) => ({ id: item.id, value: item.id, label:item.full_name }))[0],
                    RegionType: CurrentData_?.user_type === "regional_sales_team_asm" ? "assistant_sales_manager" : "regional_manager",
                    Status: CurrentData_?.is_active
                });

                if (CurrentData_?.related_id !== null) {
                    seterrorRelatedID("");
                };
            } else {
                setApplicationState({
                    ...ApplicationState,
                    FullName: CurrentData_?.full_name,
                    Email: CurrentData_?.email,
                    Mobile: CurrentData_?.mobile,
                    Address: CurrentData_?.address === null ? "" : CurrentData_?.address,
                    address2: CurrentData_?.address2 === null ? "" : CurrentData_?.address2,
                    UserType: CurrentData_?.user_type,
                    RelatedID: CurrentData_?.related_id === null ? "" : CurrentData_?.user_type === "warehouse_manager" ? optionsWareHouse?.filter((elm) => elm?.value === CurrentData_?.related_id)[0] : optionsRegion?.filter((elm) => elm?.value === CurrentData_?.related_id)[0],
                    Status: CurrentData_?.is_active
                });
                if (CurrentData_?.related_id !== null) {
                    seterrorRelatedID("");
                };
            };
            setTimeout(() => {
                setModules(CurrentData_?.permissions_access === null ? modules : CurrentData_?.permissions_access);
            }, 500);
        };
    }, [CurrentData_]);

    // select user type
    const SelectUserType = (value) => {
        SelectRelatedType("", value);
        if (value !== "") {
            setApplicationState((ApplicationState) => ({ ...ApplicationState, UserType: value, RegionType: "" }));
            seterrorUserType("");
            if (value === "regional_manager") {
                if (defaultRegionOption?.value !== undefined) {
                    seterrorRelatedID("");
                };
            } else if (value === "warehouse_manager") {
                if (defaultWareHouseOption?.value !== undefined) {
                    seterrorRelatedID("");
                };
            };
        } else {
            setApplicationState((ApplicationState) => ({ ...ApplicationState, UserType: value }));
            seterrorUserType("Please select user team");
        };
    };

    // select region 
    const SelectRelatedType = (value, url) => {
        if (value !== "" && value !== null) {
            setApplicationState({ ...ApplicationState, RelatedID: value, Regional_manager: "" });
            seterrorRelatedID("");
        } else {
            setApplicationState((data) => ({ ...data, RelatedID: "", Regional_manager: "" }));
            if(url === "regional manager" || url === "regional_sales_team") {
                seterrorRelatedID(`Please select region`);
            } else {
                seterrorRelatedID(`Please select ${url?.replace("_", " ")}`);
            }
        };
    };

    // select region type
    const SelectRegionalType = (value) => {
        if (value !== "" && value !== null) {
            setApplicationState({ ...ApplicationState, RegionType: value, RelatedID: "" });
            seterrorerrorRegionalType("");
        } else {
            setApplicationState({ ...ApplicationState, RegionType: "", RelatedID: "" });
            seterrorerrorRegionalType("Please select type");
        }
    };

    useEffect(() => {
        if(ApplicationState?.RegionType !== "regional_manager") {
            if(ApplicationState?.RelatedID !== "" && ApplicationState?.RelatedID !== null && ApplicationState?.RelatedID !== undefined) {
                SetoptionsRegionalManager(userlistAll.filter((ele) => ele.related_id === ApplicationState.RelatedID.value && ele?.is_active ).map((item) => ({ id: item.id, value: item.id, label:item.full_name })))
            } else {
                SetoptionsRegionalManager([]);
            };
        };
    }, [ApplicationState?.RelatedID, userlistAll]);

    const SelectRegionalManager = (value) => {
        if (value != "" && value !== null) {
            setApplicationState({ ...ApplicationState, Regional_manager: value });
            setErrorRegionalManager("")
        } else {
            setApplicationState({ ...ApplicationState, Regional_manager: "" });
            setErrorRegionalManager("Please select regional manager")
        }
    };

    useEffect(() => {
        if(uniqueAPIs?.length > 0) {
            setpermissionsError("")
        } else {
            // setpermissionsError("Please select permissions");
        };
    }, [ uniqueAPIs ])

    const PhoneCode = 91;
    const CountryStates = getRegionCodeForCountryCode(PhoneCode);
    const CheckMobileNumber = parsePhoneNumber(ApplicationState?.Mobile?.toString(), { regionCode: CountryStates });

    // create user and edit user
    const CreateUser = () => {
        if (LocationRoute === "edit-user") {
            if (ApplicationState.UserType !== "admin") {
                if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "assistant_sales_manager") {
                    if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Regional_manager !== "" &&
                        ApplicationState?.UserType !== "" && ApplicationState?.RelatedID !== "" && ApplicationState?.Email.trim() !== "" &&
                        ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100 && uniqueAPIs?.length > 0
                    ) {
                        if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                            let param = {
                                "transmit": "broadcast",
                                "url": "user_edit",
                                "request": {
                                    "userid": CurrentData_?.id,
                                    "full_name": ApplicationState?.FullName,
                                    "email": ApplicationState?.Email,
                                    "mobile": ApplicationState?.Mobile,
                                    "address": ApplicationState?.Address,
                                    "address2": ApplicationState?.address2,
                                    "user_type": ApplicationState?.RegionType === "regional_manager" ? ApplicationState?.UserType +"_rm" : ApplicationState?.UserType +"_asm",
                                    "permissions": uniqueAPIs,
                                    "permissions_access": modules,
                                    "related_id": ApplicationState?.RegionType === "regional_manager" ? ApplicationState?.RelatedID?.value : ApplicationState?.Regional_manager?.value,
                                    "is_active": ApplicationState?.Status,
                                },
                                "DeviceId": device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        };
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        if (ApplicationState?.Address.length > 100) {
                            CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                        }
                        if (ApplicationState?.address2?.length > 100) {
                            CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                        }
                        if (ApplicationState?.UserType === "") {
                            seterrorUserType("Please select user team");
                        } else {
                            seterrorUserType("");
                        };
                        if (ApplicationState?.RelatedID === "") {
                            if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "assistant_sales_manager") {
                                seterrorRelatedID(`Please select region`);
                            } else {
                                seterrorRelatedID(`Please select ${ApplicationState?.UserType?.replace("_", " ")}`);
                            };
                        } else {
                            seterrorRelatedID("");
                        };
                        if(ApplicationState?.Regional_manager === ""){
                            setErrorRegionalManager("Please select regional manager")
                        }else{
                            setErrorRegionalManager("")
                        }
                        if(uniqueAPIs?.length > 0) {
                            setpermissionsError("");
                        } else {
                            setpermissionsError("Please select permissions");
                        };
                    };
                } else if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "regional_manager") {
                    if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                        ApplicationState?.UserType !== "" && ApplicationState?.RelatedID !== "" && ApplicationState?.Email.trim() !== "" &&
                        ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100 && uniqueAPIs?.length > 0
                    ) {
                        if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                            let param = {
                                "transmit": "broadcast",
                                "url": "user_edit",
                                "request": {
                                    "userid": CurrentData_?.id,
                                    "full_name": ApplicationState?.FullName,
                                    "email": ApplicationState?.Email,
                                    "mobile": ApplicationState?.Mobile,
                                    "address": ApplicationState?.Address,
                                    "address2": ApplicationState?.address2,
                                    "user_type": ApplicationState?.RegionType === "regional_manager" ? ApplicationState?.UserType +"_rm" : ApplicationState?.UserType +"_asm",
                                    "permissions": uniqueAPIs,
                                    "permissions_access": modules,
                                    "related_id": ApplicationState?.RegionType === "regional_manager" ? ApplicationState?.RelatedID?.value : ApplicationState?.Regional_manager?.value,
                                    "is_active": ApplicationState?.Status,
                                },
                                "DeviceId": device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        };
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        if (ApplicationState?.Address.length > 100) {
                            CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                        }
                        if (ApplicationState?.address2?.length > 100) {
                            CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                        }
                        if (ApplicationState?.UserType === "") {
                            seterrorUserType("Please select user team");
                        } else {
                            seterrorUserType("");
                        };
                        if (ApplicationState?.RelatedID === "") {
                            if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "regional_manager") {
                                seterrorRelatedID(`Please select region`);
                            } else {
                                seterrorRelatedID(`Please select ${ApplicationState?.UserType?.replace("_", " ")}`);
                            };
                        } else {
                            seterrorRelatedID("");
                        };
                        if(uniqueAPIs?.length > 0) {
                            setpermissionsError("");
                        } else {
                            setpermissionsError("Please select permissions");
                        };
                    };
                } else if(ApplicationState.UserType === "sales_operations" || ApplicationState?.UserType === "inventory_operations" || ApplicationState?.UserType === "logistics_ops" || ApplicationState?.UserType === "accounts" || ApplicationState?.UserType === "inside_sales" || ApplicationState?.UserType === "ecommerce") {
                    if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                        ApplicationState?.UserType !== "" && ApplicationState?.Email.trim() !== "" &&
                        ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100 && uniqueAPIs?.length > 0
                    ) {
                        if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                            let param = {
                                "transmit": "broadcast",
                                "url": "user_edit",
                                "request": {
                                    "userid": CurrentData_?.id,
                                    "full_name": ApplicationState?.FullName,
                                    "email": ApplicationState?.Email,
                                    "mobile": ApplicationState?.Mobile,
                                    "address": ApplicationState?.Address,
                                    "address2": ApplicationState?.address2,
                                    "user_type": ApplicationState?.UserType,
                                    "permissions": uniqueAPIs,
                                    "permissions_access": modules,
                                    "is_active": ApplicationState?.Status,
                                },
                                "DeviceId": device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        };
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        if (ApplicationState?.Address.length > 100) {
                            CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                        }
                        if (ApplicationState?.address2?.length > 100) {
                            CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                        }
                        if (ApplicationState?.UserType === "") {
                            seterrorUserType("Please select user team");
                        } else {
                            seterrorUserType("");
                        };
                        if (ApplicationState?.RelatedID === "") {
                            if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "regional_manager") {
                                seterrorRelatedID(`Please select region`);
                            } else {
                                seterrorRelatedID(`Please select ${ApplicationState?.UserType?.replace("_", " ")}`);
                            };
                        } else {
                            seterrorRelatedID("");
                        };
                        if(uniqueAPIs?.length > 0) {
                            setpermissionsError("");
                        } else {
                            setpermissionsError("Please select permissions");
                        };
                    };
                } else {
                    if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                        ApplicationState?.UserType !== "" && ApplicationState?.RelatedID !== "" && ApplicationState?.Email.trim() !== "" &&
                        ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100  && uniqueAPIs?.length > 0
                    ) {
                        if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                            let param = {
                                "transmit": "broadcast",
                                "url": "user_edit",
                                "request": {
                                    "userid": CurrentData_?.id,
                                    "full_name": ApplicationState?.FullName,
                                    "email": ApplicationState?.Email,
                                    "mobile": ApplicationState?.Mobile,
                                    "address": ApplicationState?.Address,
                                    "address2": ApplicationState?.address2,
                                    "user_type": ApplicationState?.UserType,
                                    "permissions": uniqueAPIs,
                                    "permissions_access": modules,
                                    "related_id": ApplicationState?.RelatedID?.value,
                                    "is_active": ApplicationState?.Status,
                                },
                                "DeviceId": device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        };
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        if (ApplicationState?.Address.length > 100) {
                            CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                        }
                        if (ApplicationState?.address2?.length > 100) {
                            CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                        }
                        if (ApplicationState?.UserType === "") {
                            seterrorUserType("Please select user team");
                        } else {
                            seterrorUserType("");
                        };
                        if (ApplicationState?.RelatedID === "") {
                            seterrorRelatedID(`Please select ${ApplicationState?.UserType?.replace("_", " ")}`);
                        } else {
                            seterrorRelatedID("");
                        };
                        if(uniqueAPIs?.length > 0) {
                            setpermissionsError("");
                        } else {
                            setpermissionsError("Please select permissions");
                        };
                    };
                };
            } else {
                if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                    ApplicationState?.UserType !== "" && ApplicationState?.Email.trim() !== "" &&
                    ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100
                ) {
                    if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                        let param = {
                            "transmit": "broadcast",
                            "url": "user_edit",
                            "request": {
                                "userid": CurrentData_?.id,
                                "full_name": ApplicationState?.FullName,
                                "email": ApplicationState?.Email,
                                "mobile": ApplicationState?.Mobile,
                                "address": ApplicationState?.Address,
                                "address2": ApplicationState?.address2,
                                "user_type": ApplicationState?.UserType,
                                "permissions": uniqueAPIs,
                                "permissions_access": modules,
                                "is_active": ApplicationState?.Status,
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    };
                } else {
                    CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                    CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                    CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    if (ApplicationState?.Address.length > 100) {
                        CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                    }
                    if (ApplicationState?.address2?.length > 100) {
                        CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                    }
                    if (ApplicationState?.UserType === "") {
                        seterrorUserType("Please select user team");
                    } else {
                        seterrorUserType("");
                    };
                };
            };
        } else {
            if (ApplicationState.UserType !== "admin") {
                if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "assistant_sales_manager") {
                    if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                        ApplicationState?.UserType !== "" && ApplicationState?.RelatedID !== "" && ApplicationState?.Email.trim() !== "" &&
                        ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100 && ApplicationState?.RegionType !== "" && ApplicationState?.Regional_manager !== ""  && uniqueAPIs?.length > 0
                    ) {
                        if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                            Dispatch(StartMainCommonLoader({
                                url: "user_add",
                                loader: true
                            }));
                            let param = {
                                "transmit": "broadcast",
                                "url": "user_add",
                                "request": {
                                    "full_name": ApplicationState?.FullName,
                                    "email": ApplicationState?.Email,
                                    "mobile": ApplicationState?.Mobile,
                                    "address": ApplicationState?.Address,
                                    "address2": ApplicationState?.address2,
                                    "user_type": ApplicationState?.RegionType === "regional_manager" ? ApplicationState?.UserType +"_rm" : ApplicationState?.UserType +"_asm",
                                    "permissions": uniqueAPIs,
                                    "permissions_access": modules,
                                    "related_id": ApplicationState?.RegionType === "regional_manager" ? ApplicationState?.RelatedID?.value : ApplicationState?.Regional_manager?.value,
                                    "is_active": ApplicationState?.Status,
                                },
                                "DeviceId": device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        };
                    } else {
                        if (ApplicationState?.Address.length > 100) {
                            CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                        }
                        if (ApplicationState?.address2?.length > 100) {
                            CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                        }
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        if(ApplicationState?.RegionType === ""){
                            seterrorerrorRegionalType("Please select type")
                        }else{
                            seterrorerrorRegionalType("")
                        }
                        if(ApplicationState?.Regional_manager === ""){
                            setErrorRegionalManager("Please select regional manager")
                        }else{
                            setErrorRegionalManager("")
                        }
                        if (ApplicationState?.UserType === "") {
                            seterrorUserType("Please select user team");
                        } else {
                            seterrorUserType("");
                        };
                        if (ApplicationState?.RelatedID === "") {
                            if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "assistant_sales_manager") {
                                seterrorRelatedID(`Please select region`);
                            } else {
                                seterrorRelatedID(`Please select ${ApplicationState?.UserType?.replace("_", " ")}`);
                            };
                        } else {
                            seterrorRelatedID("");
                        };
                        if(ApplicationState?.Regional_manager === ""){
                            setErrorRegionalManager("Please select regional manager")
                        }else{
                            setErrorRegionalManager("")
                        };
                        if(uniqueAPIs?.length > 0) {
                            setpermissionsError("");
                        } else {
                            setpermissionsError("Please select permissions");
                        };
                    };
                } else if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "regional_manager") {
                    if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                        ApplicationState?.UserType !== "" && ApplicationState?.RelatedID !== "" && ApplicationState?.Email.trim() !== "" &&
                        ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100 && ApplicationState?.RegionType !== ""  && uniqueAPIs?.length > 0
                    ) {
                        if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                            Dispatch(StartMainCommonLoader({
                                url: "user_add",
                                loader: true
                            }));
                            let param = {
                                "transmit": "broadcast",
                                "url": "user_add",
                                "request": {
                                    "full_name": ApplicationState?.FullName,
                                    "email": ApplicationState?.Email,
                                    "mobile": ApplicationState?.Mobile,
                                    "address": ApplicationState?.Address,
                                    "address2": ApplicationState?.address2,
                                    "user_type": ApplicationState?.RegionType === "regional_manager" ? ApplicationState?.UserType +"_rm" : ApplicationState?.UserType +"_asm",
                                    "permissions": uniqueAPIs,
                                    "permissions_access": modules,
                                    "related_id": ApplicationState?.RegionType === "regional_manager" ? ApplicationState?.RelatedID?.value : ApplicationState?.Regional_manager?.value,
                                    "is_active": ApplicationState?.Status,
                                },
                                "DeviceId": device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        };
                    } else {
                        if (ApplicationState?.Address.length > 100) {
                            CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                        }
                        if (ApplicationState?.address2?.length > 100) {
                            CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                        }
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        if(ApplicationState?.RegionType === ""){
                            seterrorerrorRegionalType("Please select type")
                        }else{
                            seterrorerrorRegionalType("")
                        }
                        if(ApplicationState?.Regional_manager === ""){
                            setErrorRegionalManager("Please select regional manager")
                        }else{
                            setErrorRegionalManager("")
                        }
                        if (ApplicationState?.UserType === "") {
                            seterrorUserType("Please select user team");
                        } else {
                            seterrorUserType("");
                        };
                        if (ApplicationState?.RelatedID === "") {
                            if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "regional_manager") {
                                seterrorRelatedID(`Please select region`);
                            } else {
                                seterrorRelatedID(`Please select ${ApplicationState?.UserType?.replace("_", " ")}`);
                            };
                        } else {
                            seterrorRelatedID("");
                        };
                        if(uniqueAPIs?.length > 0) {
                            setpermissionsError("");
                        } else {
                            setpermissionsError("Please select permissions");
                        };
                    };
                } else if(ApplicationState.UserType === "sales_operations" || ApplicationState?.UserType === "inventory_operations" || ApplicationState?.UserType === "logistics_ops" || ApplicationState?.UserType === "accounts" || ApplicationState?.UserType === "inside_sales" || ApplicationState?.UserType === "ecommerce") {
                    if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                        ApplicationState?.UserType !== "" && ApplicationState?.Email.trim() !== "" &&
                        ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100 && 
                        uniqueAPIs?.length > 0
                    ) {
                        if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                            Dispatch(StartMainCommonLoader({
                                url: "user_add",
                                loader: true
                            }));
                            let param = {
                                "transmit": "broadcast",
                                "url": "user_add",
                                "request": {
                                    "full_name": ApplicationState?.FullName,
                                    "email": ApplicationState?.Email,
                                    "mobile": ApplicationState?.Mobile,
                                    "address": ApplicationState?.Address,
                                    "address2": ApplicationState?.address2,
                                    "user_type": ApplicationState?.UserType,
                                    "permissions": uniqueAPIs,
                                    "permissions_access": modules,
                                    "is_active": ApplicationState?.Status,
                                },
                                "DeviceId": device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        };
                    } else {
                        if (ApplicationState?.Address.length > 100) {
                            CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                        }
                        if (ApplicationState?.address2?.length > 100) {
                            CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                        }
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        if (ApplicationState?.UserType === "") {
                            seterrorUserType("Please select user team");
                        } else {
                            seterrorUserType("");
                        };
                        if (ApplicationState?.RelatedID === "") {
                            if(ApplicationState.UserType === "regional_sales_team" && ApplicationState.RegionType === "regional_manager") {
                                seterrorRelatedID(`Please select region`);
                            } else {
                                seterrorRelatedID(`Please select ${ApplicationState?.UserType?.replace("_", " ")}`);
                            };
                        } else {
                            seterrorRelatedID("");
                        };
                        if(uniqueAPIs?.length > 0) {
                            setpermissionsError("");
                        } else {
                            setpermissionsError("Please select permissions");
                        };
                    };
                } else {
                    if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                        ApplicationState?.UserType !== "" && ApplicationState?.RelatedID !== "" && ApplicationState?.Email.trim() !== "" &&
                        ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100  && uniqueAPIs?.length > 0  
                    ) {
                        if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                            Dispatch(StartMainCommonLoader({
                                url: "user_add",
                                loader: true
                            }));
                            let param = {
                                "transmit": "broadcast",
                                "url": "user_add",
                                "request": {
                                    "full_name": ApplicationState?.FullName,
                                    "email": ApplicationState?.Email,
                                    "mobile": ApplicationState?.Mobile,
                                    "address": ApplicationState?.Address,
                                    "address2": ApplicationState?.address2,
                                    "user_type": ApplicationState?.UserType,
                                    "permissions": uniqueAPIs,
                                    "permissions_access": modules,
                                    "related_id": ApplicationState?.RelatedID?.value,
                                    "is_active": ApplicationState?.Status,
                                },
                                "DeviceId": device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        };
                    } else {
                        if (ApplicationState?.Address.length > 100) {
                            CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                        }
                        if (ApplicationState?.address2?.length > 100) {
                            CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                        }
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                        if(ApplicationState?.RegionType === ""){
                            seterrorerrorRegionalType("Please select type")
                        }else{
                            seterrorerrorRegionalType("")
                        }
                        if(ApplicationState?.Regional_manager === ""){
                            setErrorRegionalManager("Please select regional manager")
                        }else{
                            setErrorRegionalManager("")
                        }
                        if (ApplicationState?.UserType === "") {
                            seterrorUserType("Please select user team");
                        } else {
                            seterrorUserType("");
                        };
                        if (ApplicationState?.RelatedID === "") {
                            seterrorRelatedID(`Please select ${ApplicationState?.UserType?.replace("_", " ")}`);
                        } else {
                            seterrorRelatedID("");
                        };
                        if(uniqueAPIs?.length > 0) {
                            setpermissionsError("");
                        } else {
                            setpermissionsError("Please select permissions");
                        };
                    };
                };
            } else {
                if (ApplicationState?.FullName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" &&
                    ApplicationState?.UserType !== "" && ApplicationState?.Email.trim() !== "" &&
                    ApplicationState?.Address.length <= 100 && ApplicationState?.address2?.length <= 100
                ) {
                    if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true) {
                        Dispatch(StartMainCommonLoader({
                            url: "user_add",
                            loader: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "user_add",
                            "request": {
                                "full_name": ApplicationState?.FullName,
                                "email": ApplicationState?.Email,
                                "mobile": ApplicationState?.Mobile,
                                "address": ApplicationState?.Address,
                                "address2": ApplicationState?.address2,
                                "user_type": ApplicationState?.UserType,
                                "permissions": uniqueAPIs,
                                "permissions_access": modules,
                                "is_active": ApplicationState?.Status,
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    } else {
                        CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                        CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    };
                } else {
                    if (ApplicationState?.Address.length > 100) {
                        CheckValid(ApplicationState?.Address.trim(), { type: 'Address', error: seterrorAddress });
                    }
                    if (ApplicationState?.address2?.length > 100) {
                        CheckValid(ApplicationState?.address2?.trim(), { type: 'address2', error: seterrorAddress2 });
                    }
                    CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                    CheckValid(ApplicationState?.FullName.trim(), { type: 'FullName', error: seterrorFirstName });
                    CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                    seterrorerrorRegionalType("Please select type")
                    if (ApplicationState?.UserType === "") {
                        seterrorUserType("Please select user team");
                    } else {
                        seterrorUserType("");
                    };
                };
            };
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            Navigate(USER_MANAGEMENT_URL);
        };
    }, [ClearFormSet]);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return (<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={USER_MANAGEMENT_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappersmAN">
            <Scrollbars
                style={{ height: "calc(100vh - 147px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebarHide"
            >
                <div className="Bigform_custom_scroll" style={{ height: "calc(100vh - 147px)" }} >
                    <div className="formloginset">
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Full Name<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={ApplicationState?.FullName}
                                        onChange={(e) => setApplicationState({ ...ApplicationState, FullName: e.target.value })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'FullName', error: seterrorFirstName })}
                                        onKeyDown={EmptySpaceFieldValid}
                                        placeholder="Enter Full Name"
                                        disabled={UploadFileLoader}
                                    />
                                    {errorFirstName !== "" && <span className="error_medotory">{errorFirstName}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email Address<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={ApplicationState?.Email}
                                        onChange={(e) => setApplicationState({ ...ApplicationState, Email: e.target.value })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'email', error: seterrorEmail })}
                                        onKeyDown={EmptySpaceFieldValid}
                                        placeholder="Enter Email Address"
                                        disabled={UploadFileLoader}
                                    />
                                    {errorEmail !== "" && <span className="error_medotory">{errorEmail}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Mobile Number<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="inputBoxs"
                                        value={ApplicationState?.Mobile}
                                        onChange={(e) => setApplicationState({ ...ApplicationState, Mobile: e.target.value.replace(/^0/, "") })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'phone', error: seterrorMobile })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        placeholder="Enter Mobile Number"
                                        disabled={UploadFileLoader}
                                    />
                                    {errorMobile !== "" && <span className="error_medotory">{errorMobile}</span>}
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>User Type<span className="mendatory_star">*</span></Form.Label>
                                    <select className="form-control" value={ApplicationState.UserType} onChange={(e) => SelectUserType(e.target.value)}>
                                        <option value={""}>Select User Type</option>
                                        <option value={"super_admin"}>Admin</option>
                                        <option value={"regional_manager"}>Regional Manager</option>
                                        <option value={"warehouse_manager"}>Warehouse Manager</option>
                                    </select>
                                    {errorUserType !== "" &&<span className="error_medotory">{errorUserType}</span>}
                                </Form.Group>
                            </div> */}
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Team<span className="mendatory_star">*</span></Form.Label>
                                    <select className="form-control" value={ApplicationState.UserType} onChange={(e) => SelectUserType(e.target.value)}>
                                        <option value={""}>Select Team</option>
                                        <option value={"admin"}>Admin</option>
                                        <option value={"sales_operations"}>Sales Operations</option>
                                        <option value={"warehouse_manager"}>Warehouse Manager</option>
                                        <option value={"inventory_operations"}>Inventory Operations</option>
                                        <option value={"logistics_ops"}>Logistics Ops</option>
                                        <option value={"accounts"}>Accounts</option>
                                        <option value={"regional_sales_team"}>Regional Sales Team</option>
                                        <option value={"inside_sales"}>Inside Sales</option>
                                        <option value={"ecommerce"}>Ecommerce</option>
                                    </select>
                                    {errorUserType !== "" && <span className="error_medotory">{errorUserType}</span>}
                                </Form.Group>
                            </div>
                            {ApplicationState.UserType === "regional_sales_team" && <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Type<span className="mendatory_star">*</span></Form.Label>
                                    <select className="form-control" value={ApplicationState.RegionType} onChange={(e) => SelectRegionalType(e.target.value)}>
                                        <option value={""}>Select Regional Type</option>
                                        <option value={"regional_manager"}>RM</option>
                                        <option value={"assistant_sales_manager"}>ASM</option>
                                    </select>
                                    {errorRegionalType !== "" && <span className="error_medotory">{errorRegionalType}</span>}
                                </Form.Group>
                            </div>}
                            {(ApplicationState.UserType === "regional_sales_team" && (ApplicationState.RegionType === "regional_manager" || ApplicationState.RegionType === "assistant_sales_manager")) && (<div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Region Master<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select
                                            value={ApplicationState?.RelatedID}
                                            onChange={(e) => SelectRelatedType(e, "regional manager")}
                                            defaultValue={defaultRegionOption}
                                            isClearable
                                            options={optionsRegion}
                                            className="godown-select-container"
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                    </div>
                                    {errorRelatedID !== "" && <span className="error_medotory">{errorRelatedID}</span>}
                                </Form.Group>
                            </div>)}
                            {(ApplicationState.RegionType === "assistant_sales_manager" && ApplicationState?.RelatedID !== "") && (<div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Region Manager<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select
                                            value={ApplicationState?.Regional_manager}
                                            onChange={(e) => SelectRegionalManager(e)}
                                            isClearable
                                            options={optionsRegionalManager}
                                            className="godown-select-container"
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                    </div>
                                    {errorRegionalManager !== "" && <span className="error_medotory">{errorRegionalManager}</span>}
                                </Form.Group>
                            </div>)}
                            {ApplicationState.UserType === "warehouse_manager" && (<div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Warehouse Master<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select
                                            onChange={(e) => SelectRelatedType(e, "warehouse manager")}
                                            defaultValue={defaultWareHouseOption}
                                            isClearable
                                            options={optionsWareHouse}
                                            className="godown-select-container"
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                    </div>
                                    {errorRelatedID !== "" && <span className="error_medotory">{errorRelatedID}</span>}
                                </Form.Group>
                            </div>)}
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Address Line 1(Optional)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea" rows={3}
                                        value={ApplicationState?.Address}
                                        onChange={(e) => setApplicationState({ ...ApplicationState, Address: e.target.value })}
                                        onKeyUp={(e) => e.target.value != "" && CheckValid(e.target.value, { type: 'Address', error: seterrorAddress })}
                                        onKeyDown={EmptySpaceFieldValid}
                                        placeholder="Enter Address"
                                        disabled={UploadFileLoader}
                                    />
                                    {errorAddress !== "" && <span className="error_medotory">{errorAddress}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Address Line 2(Optional)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea" rows={3}
                                        value={ApplicationState?.address2}
                                        onChange={(e) => setApplicationState({ ...ApplicationState, address2: e.target.value })}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'address2', error: seterrorAddress2 })}
                                        onKeyDown={EmptySpaceFieldValid}
                                        placeholder="Enter Address"
                                        disabled={UploadFileLoader}
                                    />
                                    {errorAddress2 !== "" && <span className="error_medotory">{errorAddress2}</span>}
                                </Form.Group>
                            </div>
                            {CurrentData_?.user_type !== "super_admin" && (<div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Permissions<span className="mendatory_star">*</span></Form.Label>
                                    <div className="ToggleMainWrap">
                                        <Button className="permission-btn" style={{ width: "auto" }} onClick={() => showPermission(ApplicationState?.UserType)} variant="primary" disabled={ApplicationState?.UserType === ""}>
                                            <i className="bi bi-person-check"></i> Add Permission
                                        </Button>
                                    </div>
                                    {permissionsError !== "" && <span className="error_medotory">{permissionsError}</span>}
                                </Form.Group>
                            </div>)}
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Status</Form.Label>
                                    <div className="ToggleMainWrap">
                                        <CommonToggle
                                            valueToggle={ApplicationState?.Status}
                                            setValueToggle={() => setApplicationState({ ...ApplicationState, Status: !ApplicationState?.Status })}
                                            name={""}
                                        />
                                        {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: "right", }}>
                                <Button className="formbtn widthfullbtn" style={{ width: "auto" }} onClick={() => CreateUser()} variant="primary" >
                                    {LocationRoute === "edit-user" ? "Update" : "Add"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
        {showModalNew?.open && (
            <CommonPermissionModel 
                setShowModalNew={setShowModalNew} 
                showModalNew={showModalNew} 
                modules={modules} 
                setModules={setModules}
                ApplicationState={ApplicationState}
            />)}
        {(commonMainLoader?.url === "user_add" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default AddUser;