/* eslint-disable */
import React from "react";
import { Modal, Button, Table, Image } from 'react-bootstrap';
import Logo from "../../assets/img/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { GetOrderReportDetails } from "../../redux/actions/adminActions";
import CommonDateTime from "./CommonDateTime";
import AmountNumberFormat from "./AmountNumberFormat";

const OrderDetailsViewer = (props) => {
    const { OrderViewerModal, setOrderViewerModal } = props;
    const { OrderReportDetails } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();

    // close modal
    const handleClose = () => {
        setOrderViewerModal({...OrderViewerModal, open: false});
        Dispatch(GetOrderReportDetails({}));
    };

    var originalContents = document.body.innerHTML;
    // common function
    const CommonFunction = async (id) => {
        // const printContents = document.getElementById(id).innerHTML;
        // const originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;
        const printContent = document.getElementById(id);
        const newWindow = window.open('', '_blank', "width=1200,height=600");
        newWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>New Window</title>
            <!-- Add Bootstrap CSS link -->
            <style>
                table.printTable {
                    border-collapse: collapse;
                }
                .printTable th, .printTable td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: left;
                }
                .printTable th {
                    background-color: #f2f2f2;
                }
            </style>
        </head>
        <body>
            ${printContent.innerHTML}
        </body>
        </html>`);
        newWindow.document.close();
        newWindow.print();

        // Listen for the print dialog close event
        const printDialogClosed = () => {
            newWindow.close();
            window.removeEventListener('focus', printDialogClosed);
        };
    
        // Event listener to handle print dialog close
        window.addEventListener('focus', printDialogClosed);
    };

    // get total balance and Credit Limit sub_total
    const GetCurrentOrderRate = (current) => {
        let total_gst = 0;
        let price = 0;
        let sub_total = 0;

        // if(current?.is_demo) {
        //     price = parseInt(current?.product?.demo_rate);
        // } else {
        //     price = parseInt(current?.product?.franchise_rate);
        // };
        // let gst_rate = current?.product.igst_rate
        // sub_total = parseInt(parseInt(price) * parseInt(current?.qty) * 100);
        // total_gst = parseInt(total_gst + (parseInt(parseInt(gst_rate) * sub_total)));
        // return {
        //     price: price,
        //     tax: sub_total - total_gst,
        //     total_gst: total_gst,
        //     sub_total: sub_total,
        // };
    };

    // total table count
    const TotalTableCount = (list, url) => {
        if (list?.length > 0) {
            if (url === "qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.qty);
                }
                return sum;
            } else if (url === "TaxableAmount") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseFloat(list[i]?.sub_total - list[i]?.sub_gst);
                }
                return sum;
            } else if (url === "sub_gst") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseFloat(list[i]?.sub_gst);
                }
                return sum;
            } else if (url === "sub_total"){
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.sub_total);
                }
                return sum;
            };
        } else {
            return 0;
        };
    };

    return(<Modal className="OrderDetailsModal" show={OrderViewerModal?.open} onHide={handleClose}>
    <Modal.Header closeButton>
        <Modal.Title style={{ width: "100%"}}>{OrderViewerModal?.Title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="orderDetailsViewr" id="contentToPrint">
        {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
            <Table responsive>
                <tbody>
                    <tr>
                        <td style={{ padding: "0px" }}>
                            <Image src={Logo} style={{ width: "120px" }} alt="logo" />
                        </td>
                        <td style={{ padding: "0px", textAlign: "center" }}>
                            <Table responsive>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px", fontWeight: "600" }} colSpan={4}>Franchise Partner - {OrderReportDetails?.order?.billing?.customer_name}.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px" }} colSpan={4}>Address: {OrderReportDetails?.order?.address},</td>
                                    </tr>
                                    <tr>
                                        <th style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px" }} colSpan={4}>INKODOP TECHNOLOGIES PRIVATE LIMITED.</th>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px" }} colSpan={4}>Address:At post Jambe, Taluka Mulshi, 169/2 Sangawade Road, Pune - 411033,Maharashtra, India.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px", borderBottom: "0px", fontSize: "14px" }}>Phone : +91 8686050590</td>
                                        <td style={{ padding: "0px", borderBottom: "0px", fontSize: "14px" }}>EMail ID : contactus@emotorad.com</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ padding: "17px 0px", fontSize: "14px", textAlign: "center" }} colSpan={2}>PURCHASE ORDER</th>
                    </tr>
                    <tr>
                        <td style={{ padding: "0px", textAlign: "left" }} colSpan={2}>
                            <Table responsive>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px" }}>Franchise ID : {OrderReportDetails?.order?.billing?.franchise_code?.replace("FC","")}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px" }}>Order No : {OrderReportDetails?.order?.order_code}</td>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px" }}>Order Date : {CommonDateTime(OrderReportDetails?.order, "LL", "show")}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Table className="printTable" responsive bordered hover>
                <thead>
                    <tr>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Sl. No.</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Product Description	</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>HSN Code</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>UOM</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Rate</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Qty</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Taxable Amount</th>
                        <th style={{ padding: "0px", borderBottom: "0px", fontSize: "14px", textAlign: "center" }} colSpan={2}>
                            IGST
                            <Table bordered hover style={{ margin: "0px", textAlign: "center" }}>
                                <tr>
                                    <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Rate</th>
                                    <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Amount</th>
                                </tr>
                            </Table>
                        </th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {OrderReportDetails?.order_items_list?.map((item, index) => {
                        return(<tr key={index}>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{index + 1}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.product_name}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.hsn_code}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.is_bike?"Bike":"Accessories"}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{AmountNumberFormat(item?.price)}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.qty}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{parseFloat(item?.sub_total - item?.sub_gst).toFixed(2)}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{(item?.gst_rate)}%</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.sub_gst}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.sub_total}</td>
                        </tr>)
                    })}
                    <tr className="totaltd border">
                        <th colSpan={4} className="text-center">Grand total</th>
                        <td>-</td>
                        <td>{TotalTableCount(OrderReportDetails?.order_items_list, "qty")}</td>
                        <td>{AmountNumberFormat(TotalTableCount(OrderReportDetails?.order_items_list, "TaxableAmount"))}</td>
                        <td>-</td>
                        <td>{AmountNumberFormat(TotalTableCount(OrderReportDetails?.order_items_list, "sub_gst"))}</td>
                        <td>{AmountNumberFormat(TotalTableCount(OrderReportDetails?.order_items_list, "sub_total"))}</td>
                    </tr>
                </tbody>
            </Table>
        </div>) : (<>
            Please Wait...
        </>)}
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
            Close
        </Button>
        <Button variant="primary" className="addbtncmn" onClick={() => CommonFunction("contentToPrint")}>
            Print
        </Button>
    </Modal.Footer>
</Modal>)
}

export default OrderDetailsViewer;