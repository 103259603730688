/* eslint-disable */
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";

// empty space validation fucntion
const CheckValid = (value, states) => {
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    // const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@?!])[A-Za-z\d@?!]{8,}$/; // eslint-disable-line
    const pocRegex = /^\d{10}$/;
    const pinRegex = /^\d{6}$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    function checksum(gstNumber){
        const gstRegex = /^(?:(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1})|(\d{2}[A-Z]{4}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}))$/;
        return gstRegex.test(gstNumber);
    }

    // function validateVehicleNumber(vehicleNumber) {
    //     const vehicleRegex = /^([A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4})$/;
    //     return vehicleRegex.test(vehicleNumber);
    // }

    // email validation
    if(states?.type === "email") {
        if(value !== "") {
            if(EmailRegex.test(value) === true) {
                return states?.error("");    
            } else {
                if(EmailRegex.test(value) === false) {
                    return states?.error("Please enter valid email address");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if(value === "") {
                return states?.error("Email address cannot be empty");
            } else if(EmailRegex.test(value) === false) {
                return states?.error("Please enter valid email address");
            } else {
                return states?.error("");
            }
        };
    };

    // password validation
    if(states?.type === "password"){
        // if(value !== "") {
        //     if(PasswordRegex.test(value) === true) {
        //         return states?.error("");
        //     } else {
        //         if(PasswordRegex.test(value) === false) {
        //             return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
        //         } else {
        //             return states?.error("");
        //         }
        //     }
        // } else {
        //     if (value === "") {
        //         return states?.error("Password cannot be empty");
        //     } else if(PasswordRegex.test(value) === false) {
        //         return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
        //     } else {
        //         return states?.error("");
        //     }
        // }
        if(value !== "") {
            // if(PasswordRegex.test(value) === true) {
            //     return states?.error("");
            // } else {
            //     if(PasswordRegex.test(value) === false) {
            //         return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            //     } else {
            //         return states?.error("");
            //     }
            // }
            return states?.error("");
        } else {
            // if (value === "") {
            //     return states?.error("Password cannot be empty");
            // } else if(PasswordRegex.test(value) === false) {
            //     return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            // } else {
            //     return states?.error("");
            // }
            if (value === "") {
                return states?.error("Password cannot be empty");
            }
        }
    }

    // confirmPassword validation
    if(states?.type === "confirmPassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Confirm passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Confirm password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Confirm passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            } else {
                return states?.error("");
            }
        }
    }

    // TransactionPassword validation
    if(states?.type === "TransactionPassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Transaction passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Transaction password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Transaction passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            } else {
                return states?.error("");
            }
        }
    }

    // ConfirmTransactionPassword validation
    if(states?.type === "ConfirmTransactionPassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Confirm transaction passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Confirm transaction password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Confirm transaction passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            } else {
                return states?.error("");
            }
        }
    }

    // old password validation
    if(states?.type === "oldpassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Old Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            } else {
                return states?.error("");
            }
        }
    }

    // new password validation
    if(states?.type === "newpassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("New Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Passwords must contain at least one number, one uppercase and one lowercase letter, and at least 8 or more characters.");
            } else {
                return states?.error("");
            }
        }
    }

    // first name validation
    if(states?.type === "FullName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Full name cannot be empty");
        }
    }

    // state name validation
    if(states?.type === "StateName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("State name cannot be empty");
        }
    }

    // RMuser validation
    if(states?.type === "RMuser"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("RMuser cannot be empty");
        }
    }

    // State Code validation
    if(states?.type === "StateCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("State code cannot be empty");
        }
    }

    // State Code validation
    if(states?.type === "CityName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("City name cannot be empty");
        }
    }
    // GSTCode validation
    if(states?.type === "GSTCode"){
        if(value !== "") {
            if (value?.length === 2) {
                return states?.error("");
            } else {
                return states?.error("Please enter two digit code.");
            }
        } else {
            return states?.error("GST code cannot be empty");
        }
    }

    // first name validation
    if(states?.type === "courier_name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Courier name cannot be empty.");
        }
    }
    // track_link  validation
    if(states?.type === "tracking_link"){
        if(value !== "") {
                return states?.error("");
        } else {
            return states?.error("Tracking Link Is Required");
        }
    }
    // first name validation
    // if(states?.type === "vehicle_no"){
    //     if(value !== "") {
    //         return states?.error("");
    //     } else {
    //         return states?.error("Vehicle no Is Required");
    //     }
    // }
    // first name validation
    if(states?.type === "Remark"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Remarks Is Required");
        }
    }

    //slug validation

    if(states?.type === "slug"){
        if(value !== "" && value !==null) {
            return states?.error("");
        } else {
            return states?.error("Slug Is Required");
        }
    }
    //vendor id validation
    if(states?.type === "vendor_id"){
        if(value !== "" && value!==null) {
            return states?.error("");
        } else {
            return states?.error("Vendor Id Is Required");
        }
    }
    //service type
    if(states?.type === "service_type"){
        if(value !== "" && value!==null) {
            return states?.error("");
        } else {
            return states?.error("Service Type Is Required");
        }
    }
    //service_type_air
    // if(states?.type === "service_type_air"){
    //     if(value !== "") {
    //         return states?.error("");
    //     } else {
    //         return states?.error("Service Type Air Is Required");
    //     }
    // }

    // Dispatch Name validation
    if(states?.type === "DispatchName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Dispatch name cannot be empty");
        }
    }

    // first name validation
    if(states?.type === "FranchiseTypeName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Franchise type name cannot be empty");
        }
    }

    // ParentTypeId validation
    if(states?.type === "ParentTypeId"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select parent dealer type");
        }
    }

    // box selction
    if(states?.type === "BoxSelects"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select box");
        }
    }

    // CheckParentSubFrnchState validation
    if(states?.type === "CheckParentSubFrnchState"){
        if(value === true) {
            return states?.error("");
        } else {
            return states?.error("The sub-dealer's state and the parent state should be the same. Please enter the pincode according to the parent state.");
        };
    }

    // first name validation
    if(states?.type === "RegisteredBusinessName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Registered business name cannot be empty");
        }
    }

    // PrimaryContact validation
    if(states?.type === "PrimaryContact"){
        const PhoneCode = 91;
        const CountryStates = getRegionCodeForCountryCode(PhoneCode);
        const CheckMobileNumber = parsePhoneNumber(value?.toString(), { regionCode: CountryStates });
        if(value !== "") {
            if(CheckMobileNumber?.valid === true) {
                return states?.error("");
            } else {
                return states?.error(`Please enter valid primary contact`);
            }
        } else {
            if (value === "") {
                return states?.error("Primary contact cannot be empty");
            } else if(CheckMobileNumber?.valid === false) {
                return states?.error("Please enter valid primary contact");
            } else {
                return states?.error("");
            }
        }
    };


    // first name validation
    if(states?.type === "FranchiseTypeid"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select franchise type");
        }
    }

    
    // GSTNumber validation
    if(states?.type === "GSTNumber"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("GST number cannot be empty");
        }
    }
    // GSTNumber validation
    if(states?.type === "gst_no"){
        if(value !== "" ) {
            if(value.length >= 15){
                return states?.error("Please enter a valid GST number");
            }
            if(checksum(value)){
                return states?.error("");
            }else{
                return states?.error("Please enter a valid GST number");
            }
        } else {
            return states?.error("GST number cannot be empty");
        }
    }

    // CreditLimit validation
    if(states?.type === "CreditLimit"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Credit limit cannot be empty");
        }
    }

    // Franchise type credit
    if(states?.type === "FranchiseTypeCredit"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Franchise type credit cannot be empty");
        }
    }

    // first name validation
    if(states?.type === "Fname"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("First name cannot be empty");
        }
    }

    // customer name validation
    if(states?.type === "Customername"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Customer name cannot be empty");
        }
    }

    // WarehouseCode validation
    if(states?.type === "WarehouseCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Warehouse code cannot be empty");
        }
    }

    // PANCard validation
    if(states?.type === "PANCard"){
        if(value !== "") {
            if(panRegex.test(value) === true) {
                return states?.error("");    
            } else {
                if(panRegex.test(value) === false) {
                    return states?.error("Please enter valid PAN card");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if(value === "") {
                return states?.error("PAN card cannot be empty");
            } else if(panRegex.test(value) === false) {
                return states?.error("Please enter valid PAN card");
            } else {
                return states?.error("");
            }
        };
    }

    // POCName validation
    if(states?.type === "POCName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("POC name cannot be empty");
        }
    }

    // POCNumber validation
    if(states?.type === "POCNumber"){
        if(value !== "") {
            if(pocRegex.test(value) === true) {
                return states?.error("");    
            } else {
                if(pocRegex.test(value) === false) {
                    return states?.error("Please enter valid POC number");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if(value === "") {
                return states?.error("POC number cannot be empty");
            } else if(pocRegex.test(value) === false) {
                return states?.error("Please enter valid POC number");
            } else {
                return states?.error("");
            }
        };
    };

    // GSTIN validation
    if(states?.type === "GSTIN"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("GSTIN cannot be empty");
        }
    }

    // CIN validation
    if(states?.type === "CIN"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("CIN cannot be empty");
        }
    }

    // last name validation
    if(states?.type === "Lname"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Last name cannot be empty");
        }
    }

    // first name validation
    if(states?.type === "SupplierName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Supplier name cannot be empty");
        }
    }

    // WarehouseName validation
    if(states?.type === "WarehouseName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Warehouse name cannot be empty");
        }
    }

    // ContactPersonName validation
    if(states?.type === "ContactPersonName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Contact person name cannot be empty");
        }
    }

    // Currency validation
    if(states?.type === "Currency"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Currency cannot be empty");
        }
    }

    // RegionName validation
    if(states?.type === "RegionName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Region name cannot be empty");
        }
    }

    // RegionSelect validation
    if(states?.type === "RegionSelect"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select region");
        }
    }

    // Returnremark validation
    if(states?.type === "Returnremark"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Return  mark cannot be empty");
        }
    }

    // title validation
    if(states?.type === "Title"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Title cannot be empty");
        }
    }

    // WebSiteUrl validation
    if(states?.type === "WebSiteUrl"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("WebSite cannot be empty");
        }
    }

    // Business name validation
    if(states?.type === "Business_name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Business name cannot be empty");
        }
    }

    // Address validation
    if (states?.type === "Address") {
        if (value.trim() !== "") {
            if (value.length <= 100) {
                return states?.error("");
            } else {
                return states?.error("Address must be 100 characters or less");
            }
        } else {
            return states?.error("Address cannot be empty");
        }
    }
    // Address validation
    if (states?.type === "address2") {
        if (value.trim() !== "") {
            if (value.length <= 100) {
                return states?.error("");
            } else {
                return states?.error("Address must be 100 characters or less");
            }
        } else {
            return states?.error("");
        }
    }


    // Remarks validation
    if(states?.type === "Remarks"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Remarks cannot be empty");
        }
    }

    // Username validation
    if(states?.type === "UserName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("User name cannot be empty");
        }
    }

    // phone validation
    if(states?.type === "phone"){
        const PhoneCode = 91;
        const CountryStates = getRegionCodeForCountryCode(PhoneCode);
        const CheckMobileNumber = parsePhoneNumber(value?.toString(), { regionCode: CountryStates });
        if(value !== "") {
            if(CheckMobileNumber?.valid === true) {
                return states?.error("");
            } else {
                return states?.error(`Please enter valid region mobile number`);
            }
        } else {
            if (value === "") {
                return states?.error("Mobile number cannot be empty");
            } else if(CheckMobileNumber?.valid === false) {
                return states?.error("please enter valid region mobile number");
            } else {
                return states?.error("");
            }
        }
    };

    // Product Name validation
    if(states?.type === "ProductName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Product name cannot be empty");
        }
    }

    // Product Name validation
    if(states?.type === "Model"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Model cannot be empty");
        }
    }

    // Brand validation
    if(states?.type === "BrandName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Brand name cannot be empty");
        }
    }

    // ProductGroupName validation
    if(states?.type === "ProductGroupName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Product group name cannot be empty");
        }
    }

    // Brand validation
    if(states?.type === "SelectBrand"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select a brand");
        }
    }

    // category validation
    if(states?.type === "SelectCategory"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select a category");
        }
    }

    // Select SubCategory validation
    if(states?.type === "SelectSubCategory"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select a sub category");
        }
    }

    // BrandImage validation
    if(states?.type === "BrandImage"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Image cannot be empty");
        }
    };

    
    // MasterSKU validation
    if(states?.type === "MasterSKU"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Master SKU cannot be empty");
        }
    };

    // billing_number validation
    if(states?.type === "billing_number") {
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Billing number cannot be empty");
        }
    };
    
    // ProductName validation
    if(states?.type === "ProductName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Product name cannot be empty");
        }
    };

    // DisplayName validation
    if(states?.type === "DisplayName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Display name cannot be empty");
        }
    };

    // ProductGroup validation
    if(states?.type === "ProductGroup"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Product group cannot be empty");
        }
    };

    // ProductDescription validation
    if(states?.type === "ProductDescription"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Product description cannot be empty");
        }
    };

    // HSNCode validation
    if(states?.type === "HSNCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("HSN code cannot be empty");
        }
    };

    // MRP validation
    if(states?.type === "MRP"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("MRP cannot be empty");
        }
    }; 

    // FranchiseRate validation
    if(states?.type === "FranchiseRate"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Franchise rate cannot be empty");
        }
    }; 

    // DemoRate validation
    if(states?.type === "DemoRate"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Demo rate cannot be empty");
        }
    }; 

    // RetailPrice validation
    if(states?.type === "RetailPrice"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Retail price cannot be empty");
        }
    }; 

    // FSCode validation
    if(states?.type === "FSCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("FS code cannot be empty");
        }
    }; 

    // ProductColor validation
    if(states?.type === "ProductColor"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Product color cannot be empty");
        }
    }; 

    // IGSTRate validation
    if(states?.type === "IGSTRate"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("IGST rate cannot be empty");
        }
    }; 

    // DiscountAmount validation
    if(states?.type === "DiscountAmount"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Discount amount cannot be empty");
        }
    }; 
    
    // ECommPrice validation
    if(states?.type === "ECommPrice"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("EComm Price cannot be empty");
        }
    }; 

    // Select brand validation
    if(states?.type === "Select Brand"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select brand");
        }
    }

    // Select Warehouse validation
    if(states?.type === "Select Warehouse"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select Warehouse");
        }
    }
   
    // Select Dispatch validation
    if(states?.type === 'Select Dispatch'){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select Dispatch");
        }
    }

    // Unit const price validation
    if(states?.type === "Unitconstprice"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Unit cost price cannot be empty");
        }
    }

    // Unit const price validation
    if(states?.type === "Description"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Description cannot be empty");
        }
    }

    // Supplier name validation
    if(states?.type === "SupplierName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Supplier name cannot be empty");
        }
    }

    // Supplier name validation
    if(states?.type === "SupplierSelect"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select supplier");
        }
    }

    // permission validation
    if(states?.type === "permission"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select permisssion");
        }
    }

    // brand name validation
    if(states?.type === "Brand Name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Brand name cannot be empty");
        }
    }

    // SubCategoryName validation
    if(states?.type === "SubCategoryName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Sub category name cannot be empty");
        }
    }

    

    // model name validation
    if(states?.type === "Model Name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Model name cannot be empty");
        }
    }

    // Company name validation
    if(states?.type === "CompanyName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Company name cannot be empty");
        }
    }

    // VATRegistrationNo name validation
    if(states?.type === "VATRegistrationNo"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("VAT registration no cannot be empty");
        }
    }
    
    
    // RegistrationNo name validation
    if(states?.type === "RegistrationNo"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Registration no cannot be empty");
        }
    }

    // Amount validation
    if(states?.type === "Amount"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Amount cannot be empty");
        }
    }

    // InCoterm validation
    if(states?.type === "InCoterm"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("In-Coterm cannot be empty");
        }
    }

    // Quantity validation
    if(states?.type === "Quantity"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Quantity cannot be empty");
        }
    }

    // ItemNumber validation
    if(states?.type === "ItemNumber"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Item number cannot be empty");
        }
    }

    // PI number validation
    if(states?.type === "PInumber"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("PI-number cannot be empty");
        }
    }

    // PIN code validation
    if(states?.type === "PINCode"){
        if(value !== "") {
            if(pinRegex.test(value) === true) {
                return states?.error("");    
            } else {
                if(pinRegex.test(value) === false) {
                    return states?.error("Please enter valid PIN code");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if(value === "") {
                return states?.error("PIN code cannot be empty");
            } else if(pinRegex.test(value) === false) {
                return states?.error("Please enter valid PIN code");
            } else {
                return states?.error("");
            }
        };
    }

    // HSCode validation
    if(states?.type === "HSCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("HS-code cannot be empty");
        }
    }

    // PcCode validation
    if(states?.type === "PcCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("PcCode cannot be empty");
        }
    }

    // Category Name validation
    if(states?.type === 'CategoryName'){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Category Name cannot be empty");
        }
    }

    // setting name validation
    if(states?.type === "Setting Name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Field name cannot be empty");
        }
    }

    // box_name validation
    if(states?.type === "box_name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Box name cannot be empty");
        }
    }

    // box_height validation
    if(states?.type === "box_height"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Box height cannot be empty");
        }
    }

    // box_width validation
    if(states?.type === "box_width"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Box width cannot be empty");
        }
    }

    // box_length validation
    if(states?.type === "box_length"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Box length cannot be empty");
        }
    }

    // box_weight validation
    if(states?.type === "box_weight"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Box weight cannot be empty");
        }
    }
    // Adjust Type
    if(states?.type === "adjust_type"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Adjust type  cannot be empty");
        }
    }
    // adjust_amount
    if(states?.type === "adjust_amount"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Adjust amount  cannot be empty");
        }
    }
    // reason
    if(states?.type === "reason"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Reason cannot be empty");
        }
    }
    // reason_type
    if(states?.type === "reason_type"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Reason type cannot be empty");
        }
    }
    
    // reason_type
    if(states?.type === "FrameColor"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Frame color cannot be empty");
        }
    }
    
    if(states?.type === "FrameNumber"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Frame number cannot be empty");
        }
    }

    if(states?.type === "DealerInfo"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Dealer info cannot be empty");
        }
    }

    if(states?.type === "franchise_name"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Franchise_name  cannot be empty");
        }
    }
    if(states?.type === "amount"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Amount  cannot be empty");
        }
    }
    if(states?.type === "deposit_bank"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Deposit bank cannot be empty");
        }
    }
    if(states?.type === "transaction_number"){
        if(value !== "" && value !== null) {
            return states?.error("");
        } else {
            return states?.error("Transaction number cannot be empty");
        }
    }

};

export default CheckValid;