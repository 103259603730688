import React, { useState } from 'react';
import BarcodeReader from 'react-barcode-reader';

const FrameScanner = (props) => {
    const { FrameScanningModal, setFrameScanningModal } = props;
    const [ scanResult, setScanResult ] = useState({
        success: "",
        error: "",
    });

    // handle scanner function
    const handleScan = (data) => {
        if (data) {
            setScanResult({
                success: data,
                error: "",
            });
        };
    };

    // handle error get function
    const handleError = (error) => {
        console.error(error);
        setScanResult({
            success: "",
            error: error,
        });
    };

    // close frame scanner
    const CloseScanner = () => {
        setFrameScanningModal({
            ...FrameScanningModal,
            open: false,
            id: "",
        });
    };

    console.log("scanResult321=======>", scanResult);
    return (
        <div className="framescanner_modal">
            <i className="bi bi-x-lg close_scanner_btn" onClick={CloseScanner}></i>
            <div className="frma_scanner_box">
                <BarcodeReader
                    onScan={handleScan}
                    onError={handleError}
                />
            </div>
            <h4>Scan The Barcode</h4>
            <div>{scanResult?.success} & {scanResult?.error}</div>
        </div>
    );
};

export default FrameScanner;