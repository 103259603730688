import React from "react";
import { Modal } from 'react-bootstrap';

const ResponsiveFilter = (props) => {
    const { children, ResponsiveFilterModal, setResponsiveFilterModal } = props;

    // filter modal close
    const handleClose = () => {
        setResponsiveFilterModal({
            ...ResponsiveFilterModal,
            open: false,
        })
    };

    return(<Modal className="commonModal" show={ResponsiveFilterModal?.open} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title style={{ width: "100%" }} dangerouslySetInnerHTML={{__html: ResponsiveFilterModal?.title}}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="mobile-filters">
                {children}
            </div>    
        </Modal.Body>
    </Modal>)
};

export default ResponsiveFilter;