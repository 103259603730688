/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrderDataTableFilter from "../../Common/OrderDataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { Link, useNavigate } from "react-router-dom";
import { FRANCHISE_PENDING_ORDERS_URL } from "../../Shared/constant";
import { TimeSense, getColor } from "../../Common/TimeSense";
import MoreIcon from '../../../assets/img/more_options.png';
import { ClearFormSetFutios, updateOrderManagementFilter, getTableSorting } from "../../../redux/actions/adminActions";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";

const FranchisePendingOrders = (props) => {
    const { RouteName } = props;
    const navigate = useNavigate()
    const Dispatch = useDispatch();
    const [userType, setUserType] = useState("all");
    const { websocket } = useContext(WebSocketContext);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const today = new Date();
    const { device_id, PendingForAccountsList, ClearFormSet, sortingfor, Regionlistall, selfInfo, OrderManagmentFilter } = useSelector((state) => state.adminReducers);
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    //order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            Data: value
        });
    };
    
    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            PendingForAccounts: {
                CurrentPage: page,
            }
        }));
    };

    useEffect(() => {
        CommonSortingFct("")
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        Dispatch(updateOrderManagementFilter({
            PendingForAccounts: {
                CurrentPage: 1,
            }
        }));
    };

    const selectRegionFunction = (e) => {
        Dispatch(updateOrderManagementFilter({
            PendingForAccounts: {
                SelectRegion: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            Dispatch(updateOrderManagementFilter({
                PendingForAccounts: {
                    FromDate: "",
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                PendingForAccounts: {
                    FromDate: date,
                }
            }));
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };


    // select end date
    const DateEndGet = (date) => {
        Dispatch(updateOrderManagementFilter({
            PendingForAccounts: {
                CurrentPage: 1,
            }
        }));
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(OrderManagmentFilter?.PendingForAccounts?.FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            Dispatch(updateOrderManagementFilter({
                PendingForAccounts: {
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                PendingForAccounts: {
                    ToDate: date,
                }
            }));
        }
    };

    
    // get order_list all
    useEffect(() => {
        if (websocket) {
            const { UserLimit, UserSearch, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage } = OrderManagmentFilter?.PendingForAccounts;
            let param = {
                "transmit": "broadcast",
                "url": "order_list",
                "request": {
                    "status": "WH_APPROVED",
                    "limit": UserLimit,
                    "page_no": CurrentPage,
                    "search": UserSearch.trim(),
                    "from_date": null,
                    "to_date": null,
                    "from_inv": null,
                    "to_inv": null,
                    "order_type": OrderSource,
                    "region_id": SelectRegion,
                    "order_by": sortingfor,
                },
                "DeviceId": device_id
            };
            if (FromDate !== "" && ToDate !== "") {
                param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
            }
            wsSend_request(websocket, param);
        }
    }, [websocket, sortingfor, OrderManagmentFilter?.PendingForAccounts]);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };
    const OrderDetailsModal = (order_code) => {
        navigate(`${FRANCHISE_PENDING_ORDERS_URL}/${order_code}`);
        // let param = { 
        //     "transmit": "broadcast", 
        //     "url": "order_detail",
        //     "request" : { 
        //         "order_id" : order_code,
        //     },
        //     "DeviceId" : device_id
        // };
        // wsSend_request(websocket, param);
        // setOrderViewerModal({...OrderViewerModal, open: !OrderViewerModal?.open});
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    const GetRegionName = (id) => {
        if(id === null) {
            return "-";
        } else {
            const currentRegionData = PendingForAccountsList?.regionlist?.filter((elm) => elm?.id === id)[0];
            return currentRegionData?.region_name
        }
    };

    // get franchise name
    const GetFranchiseName = (billing_id) => {
        const current = PendingForAccountsList?.franchise?.filter((elm) => elm?.id === billing_id)[0];
        return {
            name: current?.customer_name
        };
    };

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={OrderManagmentFilter?.PendingForAccounts?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForAccounts?.FromDate)}
                                    startDate={OrderManagmentFilter?.PendingForAccounts?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForAccounts?.FromDate)}
                                    endDate={OrderManagmentFilter?.PendingForAccounts?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForAccounts?.ToDate)}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={OrderManagmentFilter?.PendingForAccounts?.FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={OrderManagmentFilter?.PendingForAccounts?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForAccounts?.ToDate)}
                                    startDate={OrderManagmentFilter?.PendingForAccounts?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForAccounts?.FromDate)}
                                    endDate={OrderManagmentFilter?.PendingForAccounts?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.PendingForAccounts?.ToDate)}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={OrderManagmentFilter?.PendingForAccounts?.ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "15%" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter?.PendingForAccounts?.SelectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn exportbtn" disabled={OrderManagmentFilter?.PendingForAccounts?.ToDate === "" || OrderManagmentFilter?.PendingForAccounts?.FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": OrderManagmentFilter?.PendingForAccounts?.FromDate, "ToDate": OrderManagmentFilter?.PendingForAccounts?.ToDate, "FromInv": null, "ToInv": null, "status": "WH_APPROVED", "search": OrderManagmentFilter?.PendingForAccounts?.UserSearch.trim(), "region_id": OrderManagmentFilter?.PendingForAccounts?.SelectRegion, "order_type": OrderManagmentFilter?.PendingForAccounts?.OrderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <OrderDataTableFilter
            filterType={""}
            searchType={"Order Id, Name, Customer Code"}
            userType={userType}
            setUserType={setUserType}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />

        <div className="tableView gstreporttableadded">
            <Scrollbars
                style={{ height: PendingForAccountsList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 209px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "order_source" ? "active" : sortingfor === "-order_source" ? "inactive" : ""} onClick={() => CommonSortingFct("order_source")}>Order Source</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                            <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                            <th className={sortingfor === "qty" ? "active" : sortingfor === "-qty" ? "inactive" : ""} onClick={() => CommonSortingFct("qty")}>Quantity</th>
                            <th className={sortingfor === "order_priority" ? "active" : sortingfor === "-order_priority" ? "inactive" : ""} onClick={() => CommonSortingFct("order_priority")}>Order Priority</th>
                            <th className={sortingfor === "total" ? "active" : sortingfor === "-total" ? "inactive" : ""} onClick={() => CommonSortingFct("total")}>Order Value</th>
                            <th className={sortingfor === "priority" ? "active" : sortingfor === "-priority" ? "inactive" : ""} onClick={() => CommonSortingFct("priority")}>Criticality</th>
                            <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Pending Since </th>
                        </tr>
                    </thead>
                    <tbody>
                        {PendingForAccountsList?.list?.map((elm, index) => {
                            const get_color = getColor(elm);
                            return (
                                <React.Fragment key={index}>
                                    <tr >
                                        <td style={{ width: '10px' }}>
                                            <button
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                                {GetCurrentTableSRNo(index, PendingForAccountsList?.pagination?.current_page, PendingForAccountsList?.pagination?.record_limit)}
                                            </Link>
                                        </td>
                                        <td>
                                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[4]?.Edit) ? (
                                                <b onClick={() => OrderDetailsModal(elm?.id)} className="tableBolanchor">{elm?.order_code}</b>
                                            ) : (
                                                <b className="tableBolanchor">{elm?.order_code}</b>
                                            )}
                                        </td>
                                        <td>
                                            {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                        </td>
                                        <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                        <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                                        <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                        <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                        <td>{elm?.qty}</td>
                                        <td>{elm?.order_priority ? elm?.order_priority : "-"}</td>
                                        <td>{AmountNumberFormat(elm?.total)}</td>
                                        <td>
                                            <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                        </td>
                                        {/* <td>{elm?.updated_at?.split(" ")[0] === elm?.created_at?.split(" ")[0] ? TimeSense(elm?.updated_at) : TimeSense(elm?.created_at)}</td> */}
                                        <td>{TimeSense(elm?.updated_at)}</td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                            <td colSpan="12" >
                                                <Table bordered hover className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Status</th>
                                                            <td>
                                                                {elm?.order_status === "WH_APPROVED" && (<Badge bg="danger">Accounts Pending</Badge>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Attachments</th>
                                                            <td>
                                                                {elm?.attachments === null ? "-" : (<ul className="fileuploadListytable">
                                                                    {elm?.attachments?.map((elm, index) => {
                                                                        return (<li key={index}>
                                                                            <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                                        </li>)
                                                                    })}
                                                                </ul>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Region</th>
                                                            <td>{GetRegionName(elm?.region_id)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>
                            )
                        })}
                        {PendingForAccountsList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="11">Pending for accounts list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {PendingForAccountsList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={OrderManagmentFilter?.PendingForAccounts?.CurrentPage}
                paginationLength={PendingForAccountsList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderStatusModel setOrderNewModel={setOrderNewModel} OrderNewModel={OrderNewModel} />
    </section>)
}

export default FranchisePendingOrders;