/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Collapse, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, Link } from "react-router-dom";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import { WebSocketContext } from "../../../App";
import Select from 'react-select'
import moment from "moment";
import 'moment-timezone';
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import DataTableFilter from "../../Common/DataTableFilter";
import CommonewModel from "../../Common/CommonewModel";
import CommonExport from "../../Common/CommonExport";

const StockInwardLog = (props) => {
    const { RouteName } = props;
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo, device_id, InwardLogList, wareHouselistall, productlistall, subCategorylistall, categorylistall } = useSelector((state) => state.adminReducers);
    const [userSearch, setUserSearch] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectWarehouse, setselectWarehouse] = useState("");
    const [selectProduct, setselectProduct] = useState("");
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} - (${item?.warehouse_code})- (${item?.display_name})` }));
    const optionsProducts = productlistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.product_name }));
    const today = new Date();
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        stock_log_id: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "product_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get category all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "subcategory_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all city list
    useEffect(() => {
        if (selfInfo?.user?.user_type === 'super_admin') {
            let param = {
                "transmit": "broadcast",
                "url": "warehouse_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        }
    }, []);

    // get warehouse code
    const GetWareHouseCode = (warehouse_id) => {
        const CurrentWarehouse = wareHouselistall?.filter((elm) => elm?.id === warehouse_id)[0];
        return CurrentWarehouse?.display_name === undefined ? "-" : CurrentWarehouse?.display_name;
    };

    // get product name
    const GetProductName = (product_id) => {
        const CurrentProduct = productlistall?.filter((elm) => elm?.id === product_id)[0];
        const CurrentSubCategory = subCategorylistall?.filter((elm) => elm?.id === CurrentProduct?.sub_category_id)[0];
        const CurrentCategory = categorylistall?.filter((elm) => elm?.id === CurrentSubCategory?.category_id)[0];
        return {
            product_name: CurrentProduct?.product_name === undefined ? "-" : CurrentProduct?.product_name,
            Current_name: CurrentCategory?.category_name === undefined ? "-" : CurrentCategory?.category_name
        };
    };

    // get user name
    const GetAutherName = (author) => {
        const CurrentAuther = InwardLogList?.user_list?.filter((elm) => elm?.id === author)[0];
        return CurrentAuther?.full_name;
    };

    

    // get product list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "stock_inward_logs",
                "request": {
                    "status": "",
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch.trim(),
                    "warehouse_id": selectWarehouse,
                    "product_id": selectProduct,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, [userSearch, currentPage, userLimit, selectWarehouse, selectProduct ]);


    window.addEventListener('load', function () {
        // Code to call API when the window loads
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "stock_inward_logs",
                "request": {
                    "status": "",
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch.trim(),
                    "warehouse_id": selectWarehouse,
                    "product_id": selectProduct,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    });

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // wareehouse select
    const WareHouseSelect = (value) => {
        setselectWarehouse(value?.value);
        setCurrentPage(1);
    };

    // Product select
    const ProductSelect = (value) => {
        setselectProduct(value?.value);
        setCurrentPage(1);
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    // Sample array of numbers
    const numbers = InwardLogList?.list;

    // common date fucntion
    const CommonDateTime = (date) => {
        if (date !== null) {
            const currentTimezone = moment.tz.guess();
            return moment(date).tz(currentTimezone).format('MMMM Do YYYY, h:mm:ss a');
        } else {
            return "-";
        };
    };

    // show frames details
    const showTable = (stock_log_id) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Stock Inward Log Details",
            subtitle: "Stock Inward Log Details",
            modalType: "form",
            stock_log_id: stock_log_id
        });
    };

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            {/* <Form.Label>Products</Form.Label> */}
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => ProductSelect(e)}
                                    isClearable
                                    options={optionsProducts}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Products"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    {selfInfo?.user?.user_type === 'super_admin' && (<div className="col-md-3">
                        <Form.Group className="mb-3">
                            {/* <Form.Label>Warehouse</Form.Label> */}
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => WareHouseSelect(e)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>)}
                    <div className="col-md-3">
                        <button className="btn exportbtn" style={{ width: "auto" }} onClick={() => CommonExport("stock_inward_log", { "product_id": selectProduct, "warehouse_id": selectWarehouse, "limit": userLimit, "search": userSearch.trim(), "status":"", "page_no": currentPage })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter
            filterType={""}
            searchType={"Inward ID"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView">
            <Scrollbars
                style={{
                    height: InwardLogList?.pagination?.total_records > 10 ? "calc(100vh - 255px)" : "calc(100vh - 220px)"
                }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Inward ID</th>
                            <th>Date & Time</th>
                            <th>Product Name</th>
                            <th>Qty</th>
                            <th>Auther Name</th>
                            <th>Billing No</th>
                            <th>Warehouse Name</th>
                            <th>Category</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {InwardLogList?.order_logs?.map((elm, index) => {
                            return (<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, InwardLogList?.pagination?.current_page, InwardLogList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.inward_id === null ? "-": elm?.inward_id}</td>
                                <td>{CommonDateTime(elm?.created_at)}</td>
                                <td>{GetProductName(elm?.product_id)?.product_name}</td>
                                <td>{elm?.qty}</td>
                                <td>{GetAutherName(elm?.author)}</td>
                                <td>{(elm?.bill_no === null || elm?.bill_no === "None") ? "-" : elm?.bill_no}</td>
                                <td>{GetWareHouseCode(elm?.warehouse_id)}</td>
                                <td>{GetProductName(elm?.product_id)?.Current_name}</td>
                                <td>
                                    <div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                                        {elm?.is_bike && (<span className="btn edittable" onClick={() => showTable(elm?.id)}>
                                            <i className="bi bi-eye"></i>
                                        </span>)}
                                        {elm?.view_file_url !== null && (<a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url}  className="downloadaction" target="__blank">
                                            <i className="bi bi-file-earmark-arrow-down"></i>
                                        </a>)}
                                    </div>
                                </td>
                            </tr>)
                        })}
                        {InwardLogList?.order_logs?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Stock Inward Log not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {InwardLogList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={InwardLogList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>

        {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
    </section>)
}

export default StockInwardLog;